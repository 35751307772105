import amplitudeDestinationInterface from '@lib/avo/destinations/amplitude'

import { environment, PRODUCTION } from '@/bootstrap/environment'

import Avo, { AvoEnv } from './Avo'

function getAvoEnvironment(): AvoEnv {
    if (environment === PRODUCTION) {
        return AvoEnv.Prod
    }

    return AvoEnv.Dev
}

function init() {
    Avo.initAvo(
        {
            env: getAvoEnvironment(),
            webDebugger: false
        },
        {
            eventSource: 'web',
            dpSource: 'saas'
        },
        {},
        amplitudeDestinationInterface
    )
}

export default {
    init,
    ...Avo
}
