import { NavigationGuardNext } from 'vue-router'
import { Store as VuexStore } from 'vuex'

import { ensureToggleIsEnabled } from '@/core/ui/plugins/featureToggle/featureToggle.util'
import { AuthGetterTypes } from '@/features/auth/ui/store/types'
import { ROUTE_CALENDAR_BASE_NAME } from '@/features/calendar/ui/constants/routes.constants'
import Store from '@/store'

type MiddlewareParams = {
    next: NavigationGuardNext
    store: VuexStore<typeof Store>
}

export default function hasAccessToBookingQuestions({ store, next }: MiddlewareParams) {
    if (
        ensureToggleIsEnabled('BookingRulesQuestions', true) ||
        store.getters[AuthGetterTypes.HasUserAccessToGpsFeatures]
    ) {
        return next()
    }

    return next({ name: ROUTE_CALENDAR_BASE_NAME })
}
