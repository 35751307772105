import {
    DateFormat,
    dateToUtc,
    DateUnit,
    endOf,
    formatDate,
    getTimeFromMinutes,
    isBetweenDate,
    isSameDay,
    nearestPastMinutes,
    startOf,
    startOfDate,
    subtractToDate,
    toDate
} from '@/core/ui/utils'
import agendaRepository from '@/features/calendar/api/agenda.api'
import calendarEventsRepository from '@/features/calendar/api/calendar.events.api'
import colorSchemasRepository from '@/features/calendar/api/colorSchemas.api'
import scheduleRepository from '@/features/calendar/api/schedule.api'
import CalendarEventService from '@/features/calendar/domain/calendar.event.service'
import {
    CALENDAR_DEFAULT_END_TIME,
    CALENDAR_DEFAULT_START_TIME,
    CALENDAR_DEFAULT_TIME_BOUNDARY_OFFSET,
    CALENDAR_FETCH_EVENT_WEEKS_OFFSET,
    CALENDAR_ROUNDING_INTERVAL
} from '@/features/calendar/domain/constants/calendar.events.constants'
import { breakDownHolidayId } from '@/features/calendar/domain/utils/calendar.holiday.util'
import { ColorSchemasEntity } from '@/features/rooms/domain/interfaces/rooms.interface'
import scheduleApi from '@/features/schedule/api/schedule.api'
import { Schedule } from '@/features/schedule/domain/interfaces/schedule.interface'

import { CALENDAR_TYPE } from '../ui/constants/calendar.constants'
import CalendarBookingRequestsService from './calendar.bookingRequests.service'
import { CalendarPeriod, CalendarRoutePathname, CalendarTimeBoundaries } from './enums/calendar.enums'
import {
    AgendaDateRange,
    AgendaDay,
    AgendaSchedule,
    AgendaSlot,
    AgendaSlots,
    DateRange
} from './interfaces/calendar.interfaces'
import { HolidayEvent } from './interfaces/holiday.interfaces'
import { CreateSchedule, ScheduleOverlapDTO, ScheduleOverlapQueryParams } from './interfaces/schedule.interfaces'
import { getDateRangeByUnitType, getWeeksRange } from './utils/calendar-dates.utils'

class CalendarService {
    async getCalendarEvents(
        start = new Date(),
        schedulesIds: number[] = [],
        areBookingRequestsAllowed = false,
        docplannerDoctorId = ''
    ) {
        const { from, to } = getWeeksRange(start, CALENDAR_FETCH_EVENT_WEEKS_OFFSET)
        const dateRange = {
            from,
            to: formatDate(subtractToDate(to, 1, DateUnit.Second), DateFormat.DateStringNoUtcOffset)
        }
        const calendarEvents = await this.getCalendarEventsByDateRange(dateRange, schedulesIds)

        let calendarEventsWithBookingRequests

        if (areBookingRequestsAllowed) {
            try {
                const bookingRequests = await CalendarBookingRequestsService.getBookingRequests({
                    start: dateToUtc(dateRange.from),
                    end: dateToUtc(dateRange.to),
                    docplannerDoctorId
                })
                calendarEventsWithBookingRequests = calendarEvents as any
                calendarEventsWithBookingRequests.bookingRequests = bookingRequests
            } catch {}
        }

        return {
            dateRange,
            calendarEvents: calendarEventsWithBookingRequests || calendarEvents
        }
    }

    getCalendarEventsByDateRange(dateRange: DateRange, schedulesIds: number[] = []) {
        return calendarEventsRepository.getCalendarEvents(dateRange, schedulesIds)
    }

    getCalendarPeriodByRoutePath(calendarRoutePath: CalendarRoutePathname): CalendarPeriod {
        return calendarRoutePath === CalendarRoutePathname.Day ? CalendarPeriod.Day : CalendarPeriod.Week
    }

    getSelectedDateRangeByPeriod(date: Date, unit: DateUnit): DateRange {
        return getDateRangeByUnitType(date, unit)
    }

    isDateInCalendarEventsWeekRange(date: Date, weeks: string[]): boolean {
        const weekOfTheYear = formatDate(date, DateFormat.YearAndWeekOfTheYearFormat)
        return weeks.includes(weekOfTheYear)
    }

    createCalendar(params: CreateSchedule, hasAccessToNewServicesConfiguration: boolean) {
        if (params.type === CALENDAR_TYPE.STAFF) {
            return hasAccessToNewServicesConfiguration
                ? scheduleRepository.createStaffCalendarV2(params)
                : scheduleRepository.createStaffCalendar(params)
        }

        if (params.type === CALENDAR_TYPE.DEVICE) {
            return hasAccessToNewServicesConfiguration
                ? scheduleRepository.createDeviceCalendarV2(params)
                : scheduleRepository.createDeviceCalendar(params)
        }
    }

    removeHoliday(holiday: HolidayEvent): Promise<void> {
        const { externalId, start, scheduleId, id } = holiday
        const year = new Date(start).getFullYear()
        const holidayId = breakDownHolidayId(id)

        if (externalId) {
            return scheduleRepository.removeExternalHoliday(scheduleId, externalId)
        }

        return scheduleRepository.removeHoliday(scheduleId, holidayId, year)
    }

    async getAgendaSlots(
        dateRange: AgendaDateRange,
        colorSchema: ColorSchemasEntity[],
        schedules: Schedule[]
    ): Promise<AgendaSlots> {
        const agendaSlots = await agendaRepository.getAgendaSlots(dateRange)

        return this.processAgendaEvents(agendaSlots, colorSchema, schedules)
    }

    isAgendaEvent = (slot: AgendaSlot) => {
        return slot.block || slot.event || slot.holiday
    }

    agendaCollapseId = (slot: AgendaSlot, index: number) => {
        const customDateformat = `DDMMYYYYHHmm${index}` as DateFormat
        return formatDate(slot.start, customDateformat)
    }

    processAgendaEvents = (agendaEvents: AgendaSlots, colorSchema: ColorSchemasEntity[], schedules: Schedule[]) => {
        agendaEvents.days.forEach((day: AgendaDay) => {
            day.schedules.forEach((schedule: AgendaSchedule) => {
                let rememberedSlotIndex = -1

                schedule.slots.forEach((slot: AgendaSlot, index: number) => {
                    if (this.isAgendaEvent(slot)) {
                        if (slot.event) {
                            const appointmentSchedule = schedules.find(({ id }) => id === schedule.id)!
                            slot.event = {
                                ...slot.event,
                                colorSchemas: appointmentSchedule.colorSchemas
                            }
                        }
                        rememberedSlotIndex = -1
                        return
                    }
                    if (rememberedSlotIndex !== -1) {
                        const slotsNumber = schedule.slots[rememberedSlotIndex].collapse || 1
                        schedule.slots[rememberedSlotIndex].collapse = slotsNumber + 1
                        schedule.slots[index].isCollapsed = true
                    } else {
                        rememberedSlotIndex = index
                        schedule.slots[index].collapse = 1
                    }
                    schedule.slots[index].collapseId = this.agendaCollapseId(
                        schedule.slots[rememberedSlotIndex],
                        rememberedSlotIndex
                    )
                })
            })
        })
        return agendaEvents
    }

    getAgendaRange(currentDate: Date): AgendaDateRange {
        const { start: startDate, end: endDate } = CalendarEventService.processStartEndToISODate({
            start: toDate(startOf(currentDate, DateUnit.Week)),
            end: toDate(endOf(currentDate, DateUnit.Week))
        })

        return {
            startDate,
            endDate
        }
    }

    // eslint-disable-next-line sonarjs/cognitive-complexity
    getTimeframesFromEvents(
        events: { end: Date; start: Date }[],
        frame: CalendarTimeBoundaries,
        calendarDate: Date | DateRange
    ): string {
        const timeOffset =
            frame === CalendarTimeBoundaries.Start
                ? -CALENDAR_DEFAULT_TIME_BOUNDARY_OFFSET
                : CALENDAR_DEFAULT_TIME_BOUNDARY_OFFSET
        const timeFormat =
            frame === CalendarTimeBoundaries.Start ? DateFormat.HourMinuteFormat : DateFormat.HourMinuteFormatWith24

        const defaultTime =
            frame === CalendarTimeBoundaries.Start ? CALENDAR_DEFAULT_START_TIME : CALENDAR_DEFAULT_END_TIME

        const millisecondsInMinute = 60000
        const initialAccumulatorValue = -1

        const reduceBody = (eventDateTime: number, acumTime: number, calendarFrame: CalendarTimeBoundaries) => {
            if (acumTime === initialAccumulatorValue) return eventDateTime

            if (calendarFrame === CalendarTimeBoundaries.Start) {
                return acumTime > eventDateTime ? eventDateTime : acumTime
            }

            return acumTime < eventDateTime ? eventDateTime : acumTime
        }

        let hour
        if (calendarDate instanceof Date) {
            const todayDate = startOfDate(calendarDate).toDate()

            const time = events.reduce((acumTime, { [frame]: date }) => {
                if (date && isSameDay(toDate(date), todayDate)) {
                    const dateTime = toDate(date).getTime()
                    return reduceBody(dateTime, acumTime, frame)
                }
                return acumTime
            }, initialAccumulatorValue)

            hour = (time - todayDate.getTime()) / millisecondsInMinute
        } else {
            const { from, to } = calendarDate

            const time = events.reduce((acumTime, { [frame]: date }) => {
                if (date && isBetweenDate(toDate(date), from, to)) {
                    const eventDateTime = toDate(date).getTime() - startOfDate(date).toDate().getTime()
                    return reduceBody(eventDateTime, acumTime, frame)
                }
                return acumTime
            }, initialAccumulatorValue)

            hour = time / millisecondsInMinute
        }

        if (hour < 0) {
            return defaultTime
        }

        return formatDate(
            nearestPastMinutes(
                CALENDAR_ROUNDING_INTERVAL,
                getTimeFromMinutes(hour, timeOffset),
                DateFormat.HourMinuteFormat
            ),
            timeFormat
        )
    }

    getSchedules(): Promise<Schedule[]> {
        return scheduleApi.getSchedules()
    }

    getColorsSchemas(): Promise<ColorSchemasEntity[]> {
        return colorSchemasRepository.getCalendarColorSchemas()
    }

    async getSchedulesOverlap(queryParams: ScheduleOverlapQueryParams): Promise<ScheduleOverlapDTO> {
        return scheduleRepository.getSchedulesOverlap(queryParams)
    }
}

export default new CalendarService()
