import { AuthGetterTypes } from '@/features/auth/ui/store/types'
import CalendarOrderColumnsService from '@/features/calendar/domain/calendar.orderColumns.service'
import DeviceService from '@/features/devices/domain/devices.service'
import DoctorService from '@/features/doctor/domain/doctor.service'
import RoomService from '@/features/rooms/domain/rooms.service'
import ScheduleService from '@/features/schedule/domain/schedule.service'
import ServicesService from '@/features/servicesLegacy/domain/services.service'
import StaffService from '@/features/staff/domain/staff.service'
import { orderByAttributes } from '@/utils/array'
import { upperCaseFirstCharacter } from '@/utils/string'

import CalendarEventService from '../../domain/calendar.event.service'
import CalendarService from '../../domain/calendar.service'
import CalendarSettingsService from '../../domain/calendar.settings.service'
import {
    CALENDAR_DEFAULT_END_TIME,
    CALENDAR_DEFAULT_START_TIME
} from '../../domain/constants/calendar.events.constants'
import { CalendarPeriod, CalendarTimeBoundaries } from '../../domain/enums/calendar.enums'
import { CALENDAR_KIND, COLOR_BY_TYPE_SERVICE, COLOR_THEME_LIGHT } from '../constants/calendar.constants'
import { WEEKDAYS_DISPLAY_KEY } from '../constants/calendar.settings.constants'
import { CALENDAR_ZOOM_LEVEL_USER_SETTING_KEY } from '../constants/calendar.zoom.constants'
import calendarZoomGetters from './calendarZoom/getters'
import {
    GETTER_AGENDA_RANGE,
    GETTER_CALENDAR_24_MODE,
    GETTER_CALENDAR_CELL_SIZE,
    GETTER_CALENDAR_COLOR_BY_TYPE,
    GETTER_CALENDAR_COLORS_THEME_TYPE,
    GETTER_CALENDAR_CREATION_ADDRESS,
    GETTER_CALENDAR_CREATION_COLOR,
    GETTER_CALENDAR_CREATION_NAME,
    GETTER_CALENDAR_CREATION_SERVICES,
    GETTER_CALENDAR_CREATION_TYPE,
    GETTER_CALENDAR_CURRENT_RESOURCES,
    GETTER_CALENDAR_DENSITY,
    GETTER_CALENDAR_DEVICES,
    GETTER_CALENDAR_EVENTS,
    GETTER_CALENDAR_EVENTS_DATE_RANGE,
    GETTER_CALENDAR_FILTER_SELECTED_SERVICES,
    GETTER_CALENDAR_FILTER_SERVICES,
    GETTER_CALENDAR_FREE_SLOTS_SERVICE,
    GETTER_CALENDAR_HAS_SERVICE_BY_COLORS,
    GETTER_CALENDAR_LOADING,
    GETTER_CALENDAR_MAX_TIME,
    GETTER_CALENDAR_MIN_TIME,
    GETTER_CALENDAR_ORDER,
    GETTER_CALENDAR_ORDERED_RESOURCES,
    GETTER_CALENDAR_ORDERED_RESOURCES_BY_AVAILABILITY,
    GETTER_CALENDAR_PERIOD,
    GETTER_CALENDAR_ROOMS,
    GETTER_CALENDAR_SCHEDULES,
    GETTER_CALENDAR_SCHEDULES_ORDER,
    GETTER_CALENDAR_SELECTED_DATE,
    GETTER_CALENDAR_SHOW_QUICK_MODAL,
    GETTER_CALENDAR_STAFF,
    GETTER_CALENDAR_THEME_LIGHT,
    GETTER_CALENDAR_ZOOM_LEVEL,
    GETTER_COLOR_SCHEMAS,
    GETTER_DOCTORS,
    GETTER_DOCTORS_FILTERED_AND_ORDERED,
    GETTER_DOCTORS_GROUPED_BY_SPECIALITY,
    GETTER_DOCTORS_LIST,
    GETTER_DOCTORS_WORKING_CURRENT_PERIOD,
    GETTER_DOCTORS_WORKING_CURRENT_PERIOD_TOGGLE,
    GETTER_FACILITIES,
    GETTER_FREE_SLOTS_SELECTED_SERVICE,
    GETTER_GET_DOCTORS_STORED,
    GETTER_HIDDEN_DAYS,
    GETTER_HOLIDAYS,
    GETTER_NON_DOCTOR_SCHEDULES,
    GETTER_PICKER_COLOR_SCHEMAS,
    GETTER_RESOURCES_CUSTOM_ORDER_SET,
    GETTER_RESOURCES_ID_SELECTED,
    GETTER_SCHEDULES_FILTERED_AND_ORDERED,
    GETTER_SCHEDULES_WORKING_CURRENT_PERIOD,
    GETTER_SELECTED_DATE,
    GETTER_SELECTED_DATE_RANGE_BY_PERIOD,
    GETTER_SELECTED_DEVICES,
    GETTER_SELECTED_DOCTOR,
    GETTER_SELECTED_DOCTOR_SCHEDULES,
    GETTER_SELECTED_DOCTORS,
    GETTER_SELECTED_FACILITY,
    GETTER_SELECTED_FREESLOTS_SCHEDULE_IDS,
    GETTER_SELECTED_ROOMS,
    GETTER_SELECTED_SCHEDULES,
    GETTER_SELECTED_STAFF,
    GETTER_SHOW_RESOURCE_WEEK,
    GETTER_USER_CALENDARS_ORDENED
} from './types/getters'

export const getters = {
    [GETTER_SELECTED_DATE]: ({ selectedDate }) => selectedDate,
    [GETTER_SHOW_RESOURCE_WEEK]: ({ showResourceWeek }) => showResourceWeek,
    [GETTER_SELECTED_DATE_RANGE_BY_PERIOD]: ({ selectedDate }, otherGetters) => {
        return CalendarService.getSelectedDateRangeByPeriod(selectedDate, otherGetters[GETTER_CALENDAR_PERIOD])
    },
    [GETTER_CALENDAR_LOADING]: ({ loading }) => loading,
    [GETTER_CALENDAR_ROOMS]: ({ calendarRooms, selectedFacility, sidebarCalendarsQuery }) => {
        const rooms = [...calendarRooms]

        return RoomService.filterRoomsByProperties(rooms, {
            facilityId: selectedFacility?.id
        })
    },
    [GETTER_CALENDAR_FREE_SLOTS_SERVICE]: (
        { calendarServices, sidebarServicesQuery },
        otherGetters,
        rootState,
        rootGetters
    ) => {
        const services = [...calendarServices]

        const doctorsSelected = otherGetters[GETTER_SELECTED_DOCTORS]
        const doctorSchedules = doctorsSelected?.length
            ? doctorsSelected.map(doctor => doctor.userSchedules).flat(2)
            : []

        const staffsSelected = otherGetters[GETTER_SELECTED_STAFF]
        const devicesSelected = otherGetters[GETTER_SELECTED_DEVICES]
        const schedules = [...doctorSchedules, ...staffsSelected, ...devicesSelected]

        return ServicesService.filterServicesByProperties(services, {
            name: sidebarServicesQuery,
            schedules: schedules.length > 0 ? schedules : null
        })
    },
    [GETTER_CALENDAR_FILTER_SERVICES]: (
        { calendarServices, calendarEventsCurrentViewServicesIds, sidebarServicesQuery },
        otherGetters,
        rootState,
        rootGetters
    ) => {
        const managesMedicalCenter = rootGetters[AuthGetterTypes.CanManageMedicalCenter]
        const doctorsWorkingCurrentPeriod = otherGetters[GETTER_DOCTORS_WORKING_CURRENT_PERIOD_TOGGLE]
        const sidebarServiceList = calendarServices.filter(({ id }) =>
            calendarEventsCurrentViewServicesIds.includes(id)
        )

        const doctorsFilteredAndOrdered = otherGetters[GETTER_DOCTORS_FILTERED_AND_ORDERED]
        const doctorsWorking = otherGetters[GETTER_DOCTORS_WORKING_CURRENT_PERIOD]
        const doctorsSelected = otherGetters[GETTER_SELECTED_DOCTORS]

        let doctors = []

        if (managesMedicalCenter) {
            doctors = doctorsWorkingCurrentPeriod ? doctorsWorking : doctorsSelected
            if (!doctors.length) {
                return []
            }
        } else {
            doctors = doctorsFilteredAndOrdered
        }

        const schedules = doctors.length ? doctors.map(doctor => doctor.userSchedules).flat(2) : null

        return ServicesService.filterServicesByProperties(sidebarServiceList, {
            name: sidebarServicesQuery,
            schedules
        })
    },
    [GETTER_FREE_SLOTS_SELECTED_SERVICE]: ({ selectedFreeSlotsService }) => selectedFreeSlotsService,
    [GETTER_HOLIDAYS]: ({ calendarEvents: { holidays = [] } }, otherGetters, rootState, { managesMedicalCenter }) => {
        if (!managesMedicalCenter) {
            return holidays
        }

        const activeResourcesIds = otherGetters[GETTER_CALENDAR_CURRENT_RESOURCES].map(({ id }) => id)
        return holidays.filter(({ scheduleId }) => activeResourcesIds.includes(scheduleId))
    },
    [GETTER_USER_CALENDARS_ORDENED]: ({ schedules }) => {
        const schedulesMapped = schedules.map(schedule => ({
            ...schedule,
            name: upperCaseFirstCharacter((schedule.displayName ? schedule.displayName : schedule.name).toLowerCase())
        }))
        return orderByAttributes(schedulesMapped, ['name'])
    },
    [GETTER_CALENDAR_SELECTED_DATE]: ({ selectedDate }) => {
        return selectedDate
    },
    [GETTER_COLOR_SCHEMAS]: state => {
        return state.colorSchemas
    },
    [GETTER_PICKER_COLOR_SCHEMAS]: state => {
        return state.colorSchemasArray
    },
    [GETTER_SELECTED_DOCTORS]: ({ doctors = [] }) => {
        return doctors.filter(({ selected }) => selected)
    },
    [GETTER_SELECTED_ROOMS]: ({ calendarRooms = [] }) => {
        return calendarRooms.filter(({ selected }) => selected)
    },
    [GETTER_CALENDAR_FILTER_SELECTED_SERVICES]: ({ calendarServices = [] }) => {
        return calendarServices.filter(({ selected }) => selected) || []
    },
    [GETTER_CALENDAR_EVENTS]: (state, otherGetters, rootGetters, { managesMedicalCenter }) => {
        const events = [
            ...state.calendarEvents.appointments,
            ...state.calendarEvents.workperiods,
            ...state.calendarEvents.blocks,
            ...state.calendarEvents.reminders,
            ...state.calendarEvents.bookingRequests,
            ...otherGetters[GETTER_HOLIDAYS]
        ]
        return CalendarEventService.getEventsToRender(events, managesMedicalCenter)
    },
    [GETTER_CALENDAR_CREATION_TYPE]: state => {
        return state.calendarCreationType
    },
    [GETTER_CALENDAR_CREATION_ADDRESS]: state => {
        return state.calendarCreationAddress
    },
    [GETTER_CALENDAR_CREATION_SERVICES]: state => {
        return state.calendarCreationSelectedServices
    },
    [GETTER_CALENDAR_CREATION_COLOR]: state => {
        return state.calendarCreationColorId
    },
    [GETTER_CALENDAR_CREATION_NAME]: state => {
        return state.calendarCreationName
    },
    [GETTER_CALENDAR_PERIOD]: state => {
        return CalendarService.getCalendarPeriodByRoutePath(state.calendarRoutePath)
    },
    [GETTER_SELECTED_FACILITY]: ({ selectedFacility }) => selectedFacility,
    [GETTER_SELECTED_DOCTOR]: (_, otherGetters) => {
        const doctors = otherGetters[GETTER_DOCTORS_FILTERED_AND_ORDERED]

        return DoctorService.getSelectedDoctor(doctors)
    },
    [GETTER_SELECTED_FREESLOTS_SCHEDULE_IDS]: state => {
        return state.schedules.filter(({ selected }) => selected).map(({ id }) => id)
    },
    [GETTER_FACILITIES]: state => {
        return state.facilities
    },
    [GETTER_DOCTORS]: state => {
        return DoctorService.filterDoctorsByProperties(state.doctors, {
            facilityId: state.selectedFacility?.id,
            specialityId: state.selectedSpeciality?.id
        })
    },
    [GETTER_DOCTORS_LIST]: (state, otherGetters) => {
        return otherGetters[GETTER_DOCTORS_WORKING_CURRENT_PERIOD_TOGGLE]
            ? otherGetters[GETTER_DOCTORS_WORKING_CURRENT_PERIOD]
            : otherGetters[GETTER_GET_DOCTORS_STORED]
    },
    [GETTER_DOCTORS_WORKING_CURRENT_PERIOD]: (state, otherGetters) => {
        const {
            doctors = [],
            calendarEvents: { resources = [] }
        } = state
        const selectedDateRange = otherGetters[GETTER_SELECTED_DATE_RANGE_BY_PERIOD]
        const selectedFacility = state.selectedFacility?.id
        const doctorIds = DoctorService.getAvailableDoctorsWorkingCurrentPeriod(
            resources,
            selectedDateRange,
            selectedFacility
        )
        return doctors.map(doctor => {
            const selected = doctorIds.includes(doctor.id)
            return { ...doctor, selected }
        })
    },
    [GETTER_SCHEDULES_WORKING_CURRENT_PERIOD]: (state, otherGetters) => {
        const {
            schedules = [],
            showResourceWeek,
            calendarEvents: { resources = [], workperiods = [] }
        } = state

        if (!workperiods.length && showResourceWeek) {
            return schedules
        }

        const selectedDateRange = otherGetters[GETTER_SELECTED_DATE_RANGE_BY_PERIOD]
        const selectedFacility = state.selectedFacility?.id
        const schedulesIds = ScheduleService.getAvailableSchedulesWorkingCurrentPeriod(
            resources,
            selectedDateRange,
            selectedFacility
        )

        return schedules.map(schedule => {
            const selected = schedulesIds.includes(schedule.id)
            return { ...schedule, selected }
        })
    },
    [GETTER_DOCTORS_FILTERED_AND_ORDERED]: (state, otherGetters) => {
        const doctors = otherGetters[GETTER_DOCTORS_LIST]
        return DoctorService.filterDoctorsByProperties(doctors, {
            facilityId: state.selectedFacility?.id,
            specialityId: state.selectedSpeciality?.id
        })
    },
    [GETTER_DOCTORS_GROUPED_BY_SPECIALITY]: (state, otherGetters) => {
        return DoctorService.groupDoctorsBySpeciality(otherGetters[GETTER_DOCTORS_FILTERED_AND_ORDERED])
    },
    [GETTER_CALENDAR_CURRENT_RESOURCES]: (state, otherGetters) => {
        const schedules = otherGetters[GETTER_DOCTORS_WORKING_CURRENT_PERIOD_TOGGLE]
            ? otherGetters[GETTER_SCHEDULES_WORKING_CURRENT_PERIOD]
            : otherGetters[GETTER_SELECTED_SCHEDULES]

        return schedules
            .filter(
                resource =>
                    resource.selected &&
                    (state.selectedFacility?.id ? state.selectedFacility.id === resource.facilityId : true)
            )
            .concat(otherGetters[GETTER_SELECTED_ROOMS])
    },
    [GETTER_CALENDAR_ORDERED_RESOURCES]: (state, otherGetters) => {
        const currentResources = otherGetters[GETTER_CALENDAR_CURRENT_RESOURCES]
        const schedulesOrder = otherGetters[GETTER_CALENDAR_SCHEDULES_ORDER]

        const {
            calendarEvents: { resources = [] }
        } = state

        const schedulesWithAvailabilities = currentResources.map(schedule => ({
            ...schedule,
            ...resources.find(({ id }) => id === schedule.id)
        }))

        return CalendarOrderColumnsService.getOrderedSchedulesData(schedulesWithAvailabilities, schedulesOrder)
    },
    [GETTER_CALENDAR_ORDERED_RESOURCES_BY_AVAILABILITY]: (state, otherGetters) => {
        const currentSchedules = otherGetters[GETTER_CALENDAR_CURRENT_RESOURCES]
        const {
            calendarEvents: { resources = [] }
        } = state

        const schedulesWithAvailabilities = currentSchedules.map(schedule => ({
            ...schedule,
            ...resources.find(({ id }) => id === schedule.id)
        }))

        const selectedDateRange = otherGetters[GETTER_SELECTED_DATE_RANGE_BY_PERIOD]

        return CalendarOrderColumnsService.getOrderedSchedulesByAvailability(
            schedulesWithAvailabilities,
            selectedDateRange
        )
    },
    [GETTER_CALENDAR_DEVICES]: ({ schedules, selectedFacility }) => {
        const devices = schedules.filter(({ kind }) => kind === CALENDAR_KIND.device) || []
        return DeviceService.filterDevicesByProperties(devices, {
            facilityId: selectedFacility?.id
        })
    },
    [GETTER_SELECTED_SCHEDULES]: ({ schedules = [] }) => {
        return schedules.filter(({ selected }) => selected)
    },
    [GETTER_SELECTED_DEVICES]: (_, otherGetters) => {
        return otherGetters[GETTER_CALENDAR_DEVICES].filter(({ selected }) => selected)
    },
    [GETTER_AGENDA_RANGE]: ({ selectedDate }) => {
        return CalendarService.getAgendaRange(selectedDate)
    },
    [GETTER_CALENDAR_STAFF]: ({ schedules, selectedFacility }) => {
        const staffs = schedules.filter(({ kind }) => kind === CALENDAR_KIND.staff) || []
        return StaffService.filterStaffByProperties(staffs, {
            facilityId: selectedFacility?.id
        })
    },
    [GETTER_SELECTED_STAFF]: (_, otherGetters) => {
        return otherGetters[GETTER_CALENDAR_STAFF].filter(({ selected }) => selected)
    },
    [GETTER_HIDDEN_DAYS]: (state, otherGetters, rootGetters, rootState) => {
        if (
            !rootState[AuthGetterTypes.GetAuthUser] ||
            !rootState[AuthGetterTypes.GetAuthUser].userSettings[WEEKDAYS_DISPLAY_KEY]
        ) {
            return []
        }

        const activeDays = CalendarSettingsService.getWeekdaysVisibilityArray(
            rootState[AuthGetterTypes.GetAuthUser].userSettings[WEEKDAYS_DISPLAY_KEY]
        )

        if (activeDays.every(day => day === false)) {
            return activeDays.map(day => !day)
        }

        // needed for 0 = Sunday
        // https://fullcalendar.io/docs/hiddenDays
        activeDays.unshift(activeDays.pop())

        return activeDays.reduce((hiddenDays, activeDay, index) => {
            if (!activeDay) {
                hiddenDays.push(index)
            }
            return hiddenDays
        }, [])
    },

    [GETTER_CALENDAR_MIN_TIME]: (state, otherGetters, rootState, rootGetters) => {
        if (otherGetters[GETTER_CALENDAR_24_MODE]) return '00:00'
        const calendarEvents = otherGetters[GETTER_CALENDAR_EVENTS]
        let hour = CALENDAR_DEFAULT_START_TIME

        if (!otherGetters[GETTER_CALENDAR_LOADING] && calendarEvents.length) {
            const date =
                otherGetters[GETTER_CALENDAR_PERIOD] === CalendarPeriod.Day
                    ? otherGetters[GETTER_SELECTED_DATE]
                    : otherGetters[GETTER_SELECTED_DATE_RANGE_BY_PERIOD]
            hour = CalendarService.getTimeframesFromEvents(calendarEvents, CalendarTimeBoundaries.Start, date)
        }

        return hour
    },

    [GETTER_CALENDAR_MAX_TIME]: (state, otherGetters, rootState, rootGetters) => {
        if (otherGetters[GETTER_CALENDAR_24_MODE]) return '24:00'
        const calendarEvents = otherGetters[GETTER_CALENDAR_EVENTS]
        let hour = CALENDAR_DEFAULT_END_TIME

        if (!otherGetters[GETTER_CALENDAR_LOADING] && calendarEvents.length) {
            const date =
                otherGetters[GETTER_CALENDAR_PERIOD] === CalendarPeriod.Day
                    ? otherGetters[GETTER_SELECTED_DATE]
                    : otherGetters[GETTER_SELECTED_DATE_RANGE_BY_PERIOD]
            hour = CalendarService.getTimeframesFromEvents(calendarEvents, CalendarTimeBoundaries.End, date)
        }
        return hour
    },
    [GETTER_CALENDAR_SHOW_QUICK_MODAL]: (state, otherGetters, rootState, rootGetters) => {
        return rootGetters[AuthGetterTypes.GetAuthUser]?.userSettings?.quickModalBooking === 'true'
    },
    [GETTER_CALENDAR_COLORS_THEME_TYPE]: (state, otherGetters, rootState, rootGetters) => {
        return CalendarSettingsService.getCalendarColorsThemeType(
            rootGetters[AuthGetterTypes.GetAuthUser]?.userSettings
        )
    },
    [GETTER_CALENDAR_THEME_LIGHT]: (state, otherGetters) => {
        return otherGetters[GETTER_CALENDAR_COLORS_THEME_TYPE].name === COLOR_THEME_LIGHT
    },
    [GETTER_CALENDAR_HAS_SERVICE_BY_COLORS]: (state, otherGetters) => {
        return otherGetters[GETTER_CALENDAR_COLOR_BY_TYPE] === COLOR_BY_TYPE_SERVICE
    },
    [GETTER_CALENDAR_COLOR_BY_TYPE]: (state, otherGetters, rootState, rootGetters) => {
        return CalendarSettingsService.getCalendarColorsByType(rootGetters[AuthGetterTypes.GetAuthUser]?.userSettings)
    },
    [GETTER_CALENDAR_DENSITY]: (state, otherGetters, rootState, rootGetters) => {
        return CalendarSettingsService.getCalendarDensity(rootGetters[AuthGetterTypes.GetAuthUser]?.userSettings)
    },
    [GETTER_CALENDAR_ORDER]: (state, otherGetters, rootState, rootGetters) => {
        return CalendarSettingsService.getCalendarOrder(
            rootGetters[AuthGetterTypes.GetAuthUser]?.userSettings,
            otherGetters[GETTER_RESOURCES_CUSTOM_ORDER_SET]
        )
    },
    [GETTER_CALENDAR_CELL_SIZE]: (state, otherGetters, rootState, rootGetters) => {
        return CalendarSettingsService.getCalendarCellSize(rootGetters.authUser?.userSettings)
    },
    [GETTER_CALENDAR_24_MODE]: (state, otherGetters, rootState, rootGetters) => {
        return rootGetters[AuthGetterTypes.GetAuthUser]?.userSettings?.show24hours === 'true'
    },
    [GETTER_CALENDAR_ZOOM_LEVEL]: (state, otherGetters, rootState, rootGetters) => {
        const userSettings = rootGetters[AuthGetterTypes.GetAuthUser]?.userSettings ?? {}
        const calendarZoomLevel = userSettings[CALENDAR_ZOOM_LEVEL_USER_SETTING_KEY] ?? 0
        return Number(calendarZoomLevel)
    },
    [GETTER_NON_DOCTOR_SCHEDULES]: ({ schedules }) => {
        return schedules.filter(({ kind }) => kind !== CALENDAR_KIND.doctor)
    },
    [GETTER_SELECTED_DOCTOR_SCHEDULES]: (state, otherGetters, rootState, { managesMedicalCenter }) => {
        if (managesMedicalCenter) {
            return []
        }
        const filteredDoctorSchedules = otherGetters[GETTER_SCHEDULES_FILTERED_AND_ORDERED]
        return filteredDoctorSchedules.filter(({ selected }) => selected)
    },
    [GETTER_SCHEDULES_FILTERED_AND_ORDERED]: state => {
        const { doctorSchedules } = state

        return ScheduleService.filterSchedulesByProperties(doctorSchedules, {
            facilityId: state.selectedFacility?.id
        })
    },
    [GETTER_CALENDAR_SCHEDULES_ORDER]: ({ schedulesOrder }) => {
        return schedulesOrder
    },
    [GETTER_DOCTORS_WORKING_CURRENT_PERIOD_TOGGLE]: ({ doctorsWorkingCurrentPeriod }, otherGetters, _, rootGetters) => {
        return doctorsWorkingCurrentPeriod
    },
    [GETTER_RESOURCES_ID_SELECTED]: (_, otherGetters) => {
        return otherGetters[GETTER_CALENDAR_CURRENT_RESOURCES].filter(({ isRoom }) => !isRoom).map(({ id }) => id)
    },
    [GETTER_GET_DOCTORS_STORED](state) {
        return state.doctors
    },
    [GETTER_CALENDAR_EVENTS_DATE_RANGE]: state => {
        return state.calendarEventsDateRange
    },
    [GETTER_RESOURCES_CUSTOM_ORDER_SET]: (_, otherGetters) => {
        const schedulesOrder = otherGetters[GETTER_CALENDAR_SCHEDULES_ORDER]

        return schedulesOrder.length > 0
    },
    [GETTER_CALENDAR_SCHEDULES]: state => {
        return state.schedules
    },
    ...calendarZoomGetters
}
