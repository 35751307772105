import { captureException, captureMessage, getCurrentScope } from '@sentry/browser'
import { init } from '@sentry/vue'
import Vue from 'vue'

import { maskSensitiveInfo } from '@/core/ui/utils/dataMasking.utils'

import { ignoreErrors, integrations } from './sentry.config'

class Monitor {
    constructor() {
        this.enabled = false
        this.messageQueue = []
    }

    initialize({ release, dsn, environment, allowUrls, router, apiUrls, debug, ensureToggleIsEnabled }) {
        this.initializeSentry({
            release,
            dsn,
            environment,
            allowUrls,
            router,
            apiUrls,
            debug,
            ensureToggleIsEnabled
        })
        this.enable()
    }

    initializeSentry({ release, dsn, environment, allowUrls, router, apiUrls, debug, ensureToggleIsEnabled }) {
        init({
            Vue,
            tracingOptions: {
                trackComponents: true
            },
            dsn,
            environment,
            integrations: integrations(router, apiUrls),
            ignoreErrors,
            allowUrls,
            tracesSampler: this.sentryTracesSampler,
            beforeSend: this.sentryBeforeSend,
            debug,
            beforeSendTransaction(event) {
                if (
                    event.transaction === 'CalendarPrevNextWeek' &&
                    ensureToggleIsEnabled('SentryMonitorCalendarWeekChange', false)
                ) {
                    return null
                }

                return event
            },
            release
        })
    }

    isEnabled() {
        return this.enabled
    }

    setUserId(id) {
        getCurrentScope().setUser({
            id
        })
    }

    setTags(tags) {
        getCurrentScope().setTags(tags)
    }

    send(message, captureContext = undefined) {
        if (this.enabled) {
            captureMessage(message, captureContext)
        } else {
            this.messageQueue.push({
                message,
                captureContext
            })
        }
    }

    sendException(exception) {
        captureException(exception)
    }

    enable() {
        this.enabled = true
        this.messageQueue.forEach(({ message, captureContext }) => captureMessage(message, captureContext))
        this.messageQueue = []
    }

    sentryTracesSampler(transactionToSend) {
        return transactionToSend?.customSampleRate ?? 0
    }

    sentryBeforeSend(event) {
        if (event.message) {
            event.message = maskSensitiveInfo(event.message)
        }

        if (event.exception && event.exception.values) {
            event.exception.values = event.exception.values.map(exception => {
                if (exception.value) {
                    exception.value = maskSensitiveInfo(exception.value)
                }
                return exception
            })
        }

        if (event.request && event.request.url) {
            event.request.url = maskSensitiveInfo(event.request.url)
        }

        if (event.breadcrumbs) {
            event.breadcrumbs = event.breadcrumbs.map(breadcrumb => {
                if (breadcrumb.message) {
                    breadcrumb.message = maskSensitiveInfo(breadcrumb.message)
                }
                return breadcrumb
            })
        }

        return event
    }
}

export default new Monitor()
