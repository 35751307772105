import monitor from '@/bootstrap/monitor/monitor'
import { AuthTokenDTO } from '@/features/auth/api/interfaces/auth.DTO'
import { SSO_LOGIN_FAILED, SSO_RENEW_STATE } from '@/features/auth/domain/constants/auth.constants'
import { setMKPLToken, setSAASToken } from '@/features/auth/domain/utils/token.utils'
import { getRenewTokenPath } from '@/router/utils/helpers'

const IFRAME_NAME = 'SSOIframe'
const WAIT_FOR_CODE = 10000

interface PostMessagePayload {
    payload: {
        mkplToken: AuthTokenDTO
        saasToken: AuthTokenDTO
    }
}

const createIframe = () => {
    const iFrameEl = document.getElementById(IFRAME_NAME) as HTMLIFrameElement

    if (iFrameEl) {
        iFrameEl.src = getRenewTokenPath()
        return
    }

    const SSOIframe = document.createElement('iframe')
    SSOIframe.id = IFRAME_NAME
    SSOIframe.width = '0'
    SSOIframe.height = '0'
    SSOIframe.setAttribute('sandbox', 'allow-scripts allow-same-origin')
    SSOIframe.referrerPolicy = 'strict-origin-when-cross-origin'
    SSOIframe.style.position = 'absolute'
    SSOIframe.style.right = '0'
    SSOIframe.style.top = '0'
    SSOIframe.style.zIndex = '0'
    SSOIframe.src = getRenewTokenPath()
    document.body.appendChild(SSOIframe)
}

const destroyIframe = () => {
    const iFrameEl = document.getElementById(IFRAME_NAME) as HTMLIFrameElement
    iFrameEl.parentNode?.removeChild(iFrameEl)
}

const setNewTokens = ({ payload: { saasToken, mkplToken } }: PostMessagePayload) => {
    setSAASToken(saasToken.token, saasToken.expires_in)
    setMKPLToken(mkplToken.token, mkplToken.expires_in)
}

const endProcessTokenRefresh = (timeout: ReturnType<typeof setTimeout>) => {
    clearTimeout(timeout)
    destroyIframe()
}

export const refreshSSOToken = () => {
    return new Promise<void>((resolve, reject) => {
        const waitForTokenTimeout = setTimeout(() => {
            reject(new Error('Refresh Token timeout'))
        }, WAIT_FOR_CODE)

        const handlePostMessageRefreshToken = (message: MessageEvent) => {
            if (message.data.type !== SSO_RENEW_STATE) {
                return
            }

            const removeEventListenerAndEndProcess = () => {
                window.removeEventListener('message', handlePostMessageRefreshToken)
                endProcessTokenRefresh(waitForTokenTimeout)
            }

            if (message.data.error === SSO_LOGIN_FAILED) {
                removeEventListenerAndEndProcess()
                reject(new Error('RefreshToken: SSO_LOGIN_FAILED'))
                return
            }

            if (message.origin !== window.location.origin) {
                removeEventListenerAndEndProcess()
                reject(new Error('RefreshToken: iFrame origin is different than its parent window.location.origin'))
                return
            }

            const childWindow = (document.getElementById(IFRAME_NAME) as HTMLIFrameElement)?.contentWindow
            if (message.source !== childWindow) {
                removeEventListenerAndEndProcess()
                reject(new Error('Iframe origin is not authorised'))
                return
            }

            setNewTokens(message.data as PostMessagePayload)

            removeEventListenerAndEndProcess()
            resolve()
        }

        window.addEventListener('message', handlePostMessageRefreshToken)

        try {
            createIframe()
        } catch (e: any) {
            e.message = `Error creating renew token iFrame: ${e.message}`
            window.removeEventListener('message', handlePostMessageRefreshToken)
            monitor.sendException(e)
        }
    })
}
