const ExportData = () =>
    import(/* webpackChunkName: "export-data-settings" */ '@/features/settings/ui/views/export/ExportDataSettings.vue')

export default [
    {
        path: 'settings-exportdata',
        name: 'settings-exportdata',
        component: ExportData
    }
]
