import { AuthTokenDTO } from '@/features/auth/api/interfaces/auth.DTO'

export enum AuthActionTypes {
    AuthError = 'ACTION_AUTH_ERROR',
    AuthLogin = 'ACTION_AUTH_LOGIN',
    AuthLogout = 'ACTION_AUTH_LOGOUT',
    AuthToken = 'ACTION_AUTH_TOKEN',
    AuthWebToken = 'ACTION_AUTH_WEB_TOKEN',
    FetchProfile = 'ACTION_FETCH_PROFILE',
    FetchSaasNavigation = 'ACTION_FETCH_SAAS_NAVIGATION',
    FetchUserProfileCopyLinkUrl = 'ACTION_FETCH_USER_PROFILE_COPY_LINK_URL',
    FetchUserProfileEmailUrl = 'ACTION_FETCH_USER_PROFILE_EMAIL_URL',
    FetchUserProfileWhatsappUrl = 'ACTION_FETCH_USER_PROFILE_WHATSAPP_URL',
    FetchUsersAccountUsers = 'ACTION_FETCH_USERS_ACCOUNTUSERS',
    SetIsFakeClinic = 'ACTION_SET_IS_FAKE_CLINIC',
    SetIsPublicPractitioner = 'ACTION_SET_IS_PUBLIC_PRACTITIONER',
    SetUserSettingsByKey = 'ACTION_SET_USER_SETTINGS_BY_KEY',
    SwitchSaaSUser = 'SWITCH_SAAS_USER'
}

export type AuthActions = {
    [AuthActionTypes.AuthError](payload: { error: any }): Promise<any>
    [AuthActionTypes.AuthLogin](payload: { userId: any }): Promise<any>
    [AuthActionTypes.AuthLogout](): void
    [AuthActionTypes.AuthToken](payload: { mkpl: AuthTokenDTO; saas: AuthTokenDTO }): void
    [AuthActionTypes.AuthWebToken](payload: string): Promise<any>
    [AuthActionTypes.FetchProfile](): Promise<any>
    [AuthActionTypes.FetchSaasNavigation](): void
    [AuthActionTypes.FetchUserProfileCopyLinkUrl](payload: { utms: any }): any
    [AuthActionTypes.FetchUserProfileEmailUrl](payload: { utms: any }): any
    [AuthActionTypes.FetchUserProfileWhatsappUrl](payload: { utms: any }): any
    [AuthActionTypes.FetchUsersAccountUsers](): Promise<any>
    [AuthActionTypes.SetIsFakeClinic](payload: boolean): void
    [AuthActionTypes.SetIsPublicPractitioner](payload: boolean): void
    [AuthActionTypes.SetUserSettingsByKey](payload: { key: string; value: string }): void
    [AuthActionTypes.SwitchSaaSUser](payload: { userId: any }): Promise<any>
}
