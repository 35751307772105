import { PlanTypeEnum } from '@/core/ui/enums/planType.enums'
import { ensureToggleIsEnabled } from '@/core/ui/plugins/featureToggle/featureToggle.util'
import { date, formatDate } from '@/core/ui/utils'
import { AuthGetterTypes } from '@/features/auth/ui/store/types'
import { AvailableProjects } from '@/features/onboardings/utils/constants'
import { initProductOnboarding } from '@/features/onboardings/utils/productOnboarding'
import { usePackaging } from '@/features/packaging/composables/usePackaging'
import {
    REVERSE_TRIAL,
    REVERSE_TRIAL_START_DATE,
    REVERSE_TRIAL_V2,
    REVERSE_TRIAL_V2_START_DATE
} from '@/features/reverseTrial/constants/reverse-trial'
import { ConsentStatus } from '@/features/reverseTrial/enums/reverseTrial.enums'
import { Consent } from '@/features/reverseTrial/interfaces/interfaces'
import { isTodayOrEarlier } from '@/features/reverseTrial/utils/helper'
import store from '@/store'

export const hasUserAccessToReverseTrialOptIn = () => {
    const { canUsePackaging, hasPrivateAddressesWithPlan } = usePackaging()

    return (
        ensureToggleIsEnabled('CanRegisterToReverseTrialStarterToPlus', true) &&
        canUsePackaging() &&
        hasPrivateAddressesWithPlan(PlanTypeEnum.Starter)
    )
}

export const isReverseTrialPresent = (data: Array<Consent>) => {
    if (Array.isArray(data)) {
        return data.some(item => item.consentType === REVERSE_TRIAL)
    }
    return false
}

export const getReverseTrialConsentInfo = (
    data: Array<Consent>
): {
    canStartOnboarding: boolean
    experiment: string
    experimentStartDate: string
} => {
    const acceptedConsent = data.find(
        item => item.consentType === REVERSE_TRIAL && item.consentStatus === ConsentStatus.ACCEPTED
    )

    if (!acceptedConsent) {
        return { canStartOnboarding: false, experiment: '', experimentStartDate: '' }
    }

    // Not scalable, but we have only 2 batches for now and no plans for more
    const isFirstBatch = date(REVERSE_TRIAL_START_DATE).isAfter(acceptedConsent.createdAt)

    const experiment = isFirstBatch ? REVERSE_TRIAL : REVERSE_TRIAL_V2
    const experimentStartDate = isFirstBatch ? REVERSE_TRIAL_START_DATE : REVERSE_TRIAL_V2_START_DATE

    return {
        canStartOnboarding: isTodayOrEarlier(experimentStartDate),
        experiment,
        experimentStartDate
    }
}

export const initReverseTrialPFOnboarding = (experiment: string, experimentStartDate: string) => {
    try {
        if (ensureToggleIsEnabled('UseProductFruits', true)) {
            const { canUsePackaging, hasPrivateAddressesWithPlan } = usePackaging()

            const startDate = date(experimentStartDate)
            const trialDateExpiration = startDate.add(30, 'day')
            const trialDaysLeft = date(trialDateExpiration).diff(date(), 'day')
            const country = store.getters[AuthGetterTypes.GetCountryCode]
            initProductOnboarding(AvailableProjects.reverseTrial, {
                isUserTargeted: canUsePackaging() && hasPrivateAddressesWithPlan(PlanTypeEnum.Plus),
                platform: 'saas',
                experiment,
                trialExpiration: formatDate(trialDateExpiration),
                trialDaysLeft,
                country
            })
        }
    } catch {}
}
