import versions from '@public/config/versions.json'

import configManager from '@/bootstrap/configurationManager'
import { environment } from '@/bootstrap/environment'
import monitor from '@/bootstrap/monitor/monitor'
import { ensureToggleIsEnabled } from '@/core/ui/plugins/featureToggle/featureToggle.util'
import { useDebugMode } from '@/plugins/debug/composables/useDebugMode'
import router from '@/router'

const useMonitor = !!parseInt(configManager.getValue('SENTRY_ENABLED'), 10)

if (useMonitor) {
    const allowUrls = configManager.getSupportedDomains().map(domain => RegExp(`https?://${domain}`))
    const { isSentryLoggerEnabled: debug } = useDebugMode()

    monitor.initialize({
        dsn: configManager.getValue('SENTRY_DSN'),
        environment,
        allowUrls,
        release: versions && versions.release,
        router,
        apiUrls: [
            ...['API_URL', 'MKPL_API_URL'].map(key => configManager.getValue(key)),
            ...configManager.getSupportedDomains()
        ],
        debug,
        ensureToggleIsEnabled
    })
}
