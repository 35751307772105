import { RequestType } from '@/features/chat/domain/enums/requests.enums'

import { RequestCategorySetting } from '../../interfaces/requestCategories.interfaces'

export const REQUEST_CATEGORIES: RequestCategorySetting = {
    Prescriptions: {
        id: RequestType.Prescriptions,
        settingLabel: 'inbox-category-new-prescription',
        icon: 'plus-circle',
        iconBg: '#FECD1E',
        tracking: 'new_prescription'
    },
    RefillPrescription: {
        id: RequestType.RefillPrescription,
        settingLabel: 'inbox-category-recurring-prescription',
        icon: 'pill',
        iconBg: '#C675EC',
        tracking: 'recurrent_prescription'
    },
    Certificate: {
        id: RequestType.Certificate,
        settingLabel: 'inbox-category-certificate',
        icon: 'file',
        iconBg: '#FE9DAF',
        tracking: 'certificate'
    },
    /*
    Only use 'Booking' for booking request feature
    Booking: {
        settingLabel: '',
        type: 'booking',
        id: 5
    },
    * */
    TestResults: {
        id: RequestType.TestResults,
        settingLabel: 'inbox-category-test-results',
        icon: 'tube',
        iconBg: '#C8803E',
        tracking: 'test_results'
    },
    Referrals: {
        id: RequestType.Referrals,
        settingLabel: 'request-message-category-saas-request-referral',
        // icon: 'plus',
        // iconBg: 'warning-active',
        tracking: 'referrals'
    },
    Uncategorized: {
        id: RequestType.Uncategorized,
        settingLabel: 'inbox-category-other-request',
        icon: 'question',
        iconBg: '#12A985',
        tracking: 'other'
    }
} as const

export const DEFAULT_CATEGORIES_SET = [
    {
        category: 'Prescriptions',
        categoryId: RequestType.Prescriptions,
        isEnabled: false
    },
    {
        category: 'RefillPrescription',
        categoryId: RequestType.RefillPrescription,
        isEnabled: false
    },
    {
        category: 'Certificate',
        categoryId: RequestType.Certificate,
        isEnabled: false
    },
    {
        category: 'TestResults',
        categoryId: RequestType.TestResults,
        isEnabled: false
    },
    {
        category: 'Referrals',
        categoryId: RequestType.Referrals,
        isEnabled: false
    },
    {
        category: 'Uncategorized',
        categoryId: RequestType.Uncategorized,
        isEnabled: true
    }
] as const
