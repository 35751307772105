import { ensureToggleIsEnabled } from '@/core/ui/plugins/featureToggle/featureToggle.util'
import { date } from '@/core/ui/utils'
import { AuthGetterTypes } from '@/features/auth/ui/store/types'
import { AvailableProjects } from '@/features/onboardings/utils/constants'
import { initProductOnboarding } from '@/features/onboardings/utils/productOnboarding'
import { usePackaging } from '@/features/packaging/composables/usePackaging'
import { ConsentStatus } from '@/features/reverseTrial/enums/reverseTrial.enums'
import { Consent } from '@/features/reverseTrial/interfaces/interfaces'
import store from '@/store'
import { apiMkplUrl } from '@/utils/url'

import { FREEMIUM_TRIAL_V1, TRIAL_DURATION_DAYS } from '../constants/freemium'

export const hasUserAccessToFreemiumTrial = () => {
    const { getIsFreeDoctor } = usePackaging()
    const hasAccessToFreemiumTrial = ensureToggleIsEnabled('HasAccessToSaasForFreemiumTrial', true)

    return hasAccessToFreemiumTrial && getIsFreeDoctor()
}

export const isFreemiumTrialAccepted = (consents: Array<Consent>) => {
    if (Array.isArray(consents)) {
        return consents.some(
            item => item.consentType === FREEMIUM_TRIAL_V1 && item.consentStatus === ConsentStatus.ACCEPTED
        )
    }
    return false
}

export const getTrialConsentDate = (consents: Array<Consent>) => {
    const consent = consents.find(
        item => item.consentType === FREEMIUM_TRIAL_V1 && item.consentStatus === ConsentStatus.ACCEPTED
    )

    return consent?.createdAt
}

const getTrialDaysLeft = (consentDate: string) => {
    const today = date()
    const startDate = date(consentDate)
    const daysElapsed = today.diff(startDate, 'day')

    return TRIAL_DURATION_DAYS - daysElapsed
}

export const initializeFreemiumPfTours = (consentDate: string) => {
    if (ensureToggleIsEnabled('UseProductFruits', true)) {
        const country = store.getters[AuthGetterTypes.GetCountryCode]
        const trialDaysLeft = getTrialDaysLeft(consentDate)

        initProductOnboarding(AvailableProjects.freemium, {
            isUserTargeted: true,
            platform: 'saas',
            experiment: FREEMIUM_TRIAL_V1,
            trialDaysLeft,
            country
        })
    }
}

export const getDashboardRedirectUrl = () => `${apiMkplUrl}/doctor-dashboard`
export const redirectUserToDashboard = () => {
    window.location.href = getDashboardRedirectUrl()
}

const getIsTrialExpired = (consentDate: string) => getTrialDaysLeft(consentDate) < 0

export const handleTrialAccess = (consentDate: string) => {
    if (getIsTrialExpired(consentDate)) {
        redirectUserToDashboard()
        return
    }

    initializeFreemiumPfTours(consentDate)
}
