import { ensureToggleIsEnabled } from '@/core/ui/plugins/featureToggle/featureToggle.util'
import { DateFormat, formatDateWithLocale } from '@/core/ui/utils'
import { upperCaseFirstCharacter } from '@/utils/string'

import { PaymentsPatientVisit } from '../../api/interfaces'
import { SCHEDULE_PAYMENT_STATUS, TRACK_REASON } from '../../constants'
import { PaymentsPatientSuggestion, PaymentsSchedule, PaymentsScheduleBookingSettings } from '../../domain/interfaces'
import { PAYMENTS_REQUESTS_OPTION_VALUE_NONE } from '../constants'
import {
    FeatureDeactivationReason,
    PaymentsRequestsPreviewName,
    PaymentsUpfrontSettings,
    SchedulePaymentStatus,
    UpfrontPaymentsMandatorySetting
} from '../enums'
import { PaymentsRequestsDropdownOption, PaymentsRequestsDropdownPreview } from '../interfaces'
import { $t } from './i18n.utils'

export const isFeatureEnabledUpfrontPaymentsPatientBookings = () => {
    return ensureToggleIsEnabled('UpfrontPaymentsPatientBookings', true)
}

export const isFeatureEnabledInactivatePaymentsSetting = () => {
    return ensureToggleIsEnabled('InactivePaymentsSettingOption', true)
}

export const getOnlinePaymentsBadge = ({
    isOnlinePaymentEnabled,
    isBundled,
    canActivatePayments
}: PaymentsSchedule) => {
    if (isBundled && isOnlinePaymentEnabled) {
        return SCHEDULE_PAYMENT_STATUS[SchedulePaymentStatus.ActiveByDefault]
    }
    if (!canActivatePayments && !isOnlinePaymentEnabled) {
        return SCHEDULE_PAYMENT_STATUS[SchedulePaymentStatus.Required]
    }
    if (isOnlinePaymentEnabled) {
        return SCHEDULE_PAYMENT_STATUS[SchedulePaymentStatus.Active]
    }

    return SCHEDULE_PAYMENT_STATUS[SchedulePaymentStatus.Inactive]
}

export const getOnlinePaymentAction = ({ canActivatePayments }: PaymentsSchedule) => {
    if (!canActivatePayments) {
        return {
            label: $t('payments-section-settings-chart-cta-details'),
            icon: null
        }
    }
    return {
        label: $t('payments-section-settings-chart-cta'),
        icon: 'settings'
    }
}

export const getActivationTrackProperties = (
    scheduleId: number,
    { reason, comments }: { comments: string; reason: FeatureDeactivationReason }
) => ({
    properties: {
        object_id: scheduleId,
        object_sub_type: TRACK_REASON[FeatureDeactivationReason.DontLike],
        object_type: TRACK_REASON[reason as FeatureDeactivationReason],
        ...(reason === FeatureDeactivationReason.Other && {
            object_value: comments
        })
    }
})

export const getPaymentRequestsDropdownPatientOptions = (
    suggestions: PaymentsPatientSuggestion[]
): PaymentsRequestsDropdownOption[] =>
    suggestions.map(({ dateOfBirth, fullName, id, formattedPhone }) => {
        const birthdate = dateOfBirth ? formatDateWithLocale(dateOfBirth, DateFormat.LocalizedDefaultFormat) : ''
        const description = [formattedPhone, birthdate].filter(Boolean).join(' | ')

        return {
            title: fullName,
            value: id,
            ...(description
                ? {
                      description
                  }
                : {})
        }
    })

export const getPaymentRequestsDropdownPatientPreview = ({
    fullName,
    formattedPhone,
    email
}: Pick<PaymentsPatientSuggestion, 'fullName' | 'formattedPhone' | 'email'>): PaymentsRequestsDropdownPreview[] => {
    return [
        {
            name: PaymentsRequestsPreviewName.FullName,
            iconName: 'user',
            description: fullName
        },
        ...(formattedPhone
            ? [
                  {
                      name: PaymentsRequestsPreviewName.Phone,
                      iconName: 'phone',
                      description: formattedPhone
                  }
              ]
            : []),
        ...(email
            ? [
                  {
                      name: PaymentsRequestsPreviewName.Email,
                      iconName: 'mail',
                      description: email
                  }
              ]
            : [])
    ]
}

export const getPaymentRequestsDropdownPatientVisitOptions = (
    visits: PaymentsPatientVisit[]
): PaymentsRequestsDropdownOption[] => [
    {
        title: $t('payments-section-payments-requests-modal-create-title4-placeholder'),
        value: PAYMENTS_REQUESTS_OPTION_VALUE_NONE
    },
    ...visits.map(({ startDateTime, id, serviceName, doctorFullName }) => ({
        title: `${upperCaseFirstCharacter(formatDateWithLocale(startDateTime, DateFormat.PaymentsVisitFormat))} `,
        value: id,
        description: `${serviceName} / ${doctorFullName}`
    }))
]

export const getPaymentRequestsDropdownPatientVisitPreview = (
    visit: PaymentsPatientVisit
): PaymentsRequestsDropdownPreview[] => [
    {
        name: PaymentsRequestsPreviewName.DateTime,
        iconName: 'calendar',
        description: `${upperCaseFirstCharacter(
            formatDateWithLocale(visit.startDateTime, DateFormat.PaymentsVisitFormat)
        )}`
    },
    {
        name: PaymentsRequestsPreviewName.FullName,
        iconName: 'doctor',
        description: visit.doctorFullName
    },
    {
        name: PaymentsRequestsPreviewName.ServiceName,
        iconName: 'stethoscope',
        description: visit.serviceName
    }
]
