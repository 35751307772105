import PhoneNumberService from '@/core/domain/phoneNumber.service'

export async function isPhoneValid(value: string): Promise<boolean> {
    const { isValid } = await PhoneNumberService.validatePhone(value)
    return isValid
}

export async function isPhoneValidCancelable(value: string): Promise<boolean> {
    const { isValid } = await PhoneNumberService.validatePhoneCancelable(value)
    return isValid
}
