import { computed } from 'vue'

import { ensureToggleIsEnabled } from '@/core/ui/plugins/featureToggle/featureToggle.util'
import { AuthGetterTypes } from '@/features/auth/ui/store/types'
import {
    ActionTypes as RequestActionTypes,
    GetterTypes as RequestGetterTypes,
    MODULE_REQUEST
} from '@/features/chat/ui/store/request/types'
import {
    ActionTypes as ChatActionTypes,
    GetterTypes as ChatGetterTypes,
    MODULE_CHAT
} from '@/features/chat/ui/store/types'
import { DEFAULT_CATEGORIES_SET } from '@/features/settings/ui/constants/patientRequest'
import store from '@/store'

import { INTEGRATION_CHANNEL, REQUEST_INTEGRATION_CHANNEL } from '../ui/constants/requests.constants'

export function useRequests() {
    const hasDpPhoneIntegrated = computed(() => {
        if (
            store.getters[`${MODULE_REQUEST}/${RequestGetterTypes.GetRequestChannel}`] ===
            REQUEST_INTEGRATION_CHANNEL[INTEGRATION_CHANNEL.dpPhone]
        ) {
            return true
        }

        return ensureToggleIsEnabled('DoctorIntegratedWithDpPhone', true)
    })

    const isUserDoctorType = computed(() => {
        return store.getters[`${AuthGetterTypes.IsDoctor}`]
    })

    const isUserSecretaryOfSpecialist = computed(() => {
        return store.getters[`${AuthGetterTypes.IsSecretaryOfIndividualDoctor}`]
    })

    const isUserGruppoType = computed(() => {
        return store.getters[`${AuthGetterTypes.IsGruppo}`]
    })

    const isUserPublicPractitionerSecretary = computed(() => {
        return store.getters[`${AuthGetterTypes.IsSecretaryOfPublicPractitioner}`]
    })

    const isUserPublicPractitioner = computed(() => {
        return store.getters[`${AuthGetterTypes.IsPublicPractitioner}`]
    })

    const isUserGruppoSecretary = computed(() => {
        return store.getters[`${AuthGetterTypes.IsSecretaryOfGruppo}`]
    })

    const isPatientRequestEnabled = computed(() => {
        return store.getters[`${MODULE_REQUEST}/${RequestGetterTypes.GetIsRequestFeatureEnabled}`]
    })

    const isChatContactFromProfileEnabled = computed(() => {
        return store.getters[`${MODULE_CHAT}/${ChatGetterTypes.GetIsContactFromProfileAllowed}`]
    })

    const isGlobalBlockMessageActivated = computed(() => {
        return store.getters[`${MODULE_CHAT}/${ChatGetterTypes.GetIsGlobalMessageBlockActive}`]
    })

    const hasDoctorAccessToRequestFeature = computed(() => {
        return (
            isUserPublicPractitioner.value ||
            (ensureToggleIsEnabled('ShowRequestFeature', true) && isUserDoctorType.value)
        )
    })

    const isUserSecretaryWithGPfeatureAccess = computed(() => {
        return (
            (isUserSecretaryOfSpecialist.value && ensureToggleIsEnabled('ShowRequestFeature', true)) ||
            isUserPublicPractitionerSecretary.value
        )
    })

    const hasSecretaryAccessToRequest = computed(() => {
        return isUserSecretaryWithGPfeatureAccess.value && store.getters[`${AuthGetterTypes.HasAccessToMessages}`]
    })

    const canCheckRequestFeatureEnabled = computed(() => {
        return isUserSecretaryWithGPfeatureAccess.value || hasDoctorAccessToRequestFeature.value
    })

    const isGruppoIntegratedWithDpPhone = computed(() => {
        return isUserGruppoType.value && ensureToggleIsEnabled('DoctorIntegratedWithDpPhone', true)
    })

    const hasUserAccessToReadRequests = computed(() => {
        const hasDoctorAccess = hasDoctorAccessToRequestFeature.value
        const hasSecretaryAccess =
            (hasSecretaryAccessToRequest.value || isUserGruppoSecretary.value) && isPatientRequestEnabled.value
        return hasDoctorAccess || hasSecretaryAccess
    })

    const isUserMedicalCenter = computed(() => {
        return store.getters[`${[AuthGetterTypes.IsMedicalCenter]}`]
    })

    const hasSpecialistAccessToNewChat = computed(() => {
        if (hasDoctorAccessToRequestFeature.value) return false
        if (isUserDoctorType.value) return true
        return false
    })

    const hasUserAccessToBlockPatientFeature = computed(() => {
        return ensureToggleIsEnabled('ShowPatientCanReplyToChatToggle', true)
    })

    const showCanReplyChatFromSettings = computed(() => {
        return (
            (isUserMedicalCenter.value || hasSpecialistAccessToNewChat.value) &&
            hasUserAccessToBlockPatientFeature.value
        )
    })

    const shouldAccessNewChat = computed(() => {
        return ensureToggleIsEnabled('NewInboxView', true)
    })

    const shouldAccessNewConversationView = computed(() => {
        if (isUserGruppoSecretary.value) {
            return ensureToggleIsEnabled('NewInboxConversationViewGruppoSecretaries', true)
        }

        if (shouldAccessNewChat.value) {
            return true
        }

        return false
    })

    async function fetchIsContactEnabled() {
        const isContactEnabled = `${MODULE_CHAT}/${ChatActionTypes.FetchIsContactFromProfileAllowed}`
        await store.dispatch(isContactEnabled)
    }

    async function fetchRequestsIntegration(doctorId: string) {
        const requestIntegration = `${MODULE_REQUEST}/${RequestActionTypes.FetchRequestsIntegration}`
        await store.dispatch(requestIntegration, { docplannerDoctorId: doctorId })
    }

    async function updateContactFromProfileAllowed({ isAllowed }: { isAllowed: boolean }) {
        const setContactFromProfileAction = `${MODULE_CHAT}/${ChatActionTypes.UpdateIsContactFromProfileAllowed}`
        await store.dispatch(setContactFromProfileAction, { isAllowed })
    }

    async function setDefaultCategories(doctorId: string) {
        const setRequestCategories = `${MODULE_REQUEST}/${RequestActionTypes.UpdateRequestCategories}`

        await store.dispatch(setRequestCategories, {
            docplannerDoctorId: doctorId,
            categories: DEFAULT_CATEGORIES_SET
        })
    }

    async function updateRequestFeature(isEnabled: boolean, doctorId: string) {
        const setRequestEnabled = `${MODULE_REQUEST}/${RequestActionTypes.UpdateIsRequestFeatureEnabled}`

        await store.dispatch(setRequestEnabled, { isEnabled, doctorId })
    }

    async function updateStatusGlobalMessageBlock(shouldActivateBlock: boolean, doctorId: string) {
        const updateStatusGlobalBlock = `${MODULE_CHAT}/${ChatActionTypes.UpdateStatusGlobalMessageBlock}`
        await store.dispatch(updateStatusGlobalBlock, { shouldActivateBlock, doctorId })
    }

    async function switchRequests({
        doctorId,
        isEnabled,
        setBlock = true
    }: {
        doctorId: string
        isEnabled: boolean
        setBlock: boolean
    }) {
        await updateRequestFeature(isEnabled, doctorId)
        if (!isGlobalBlockMessageActivated.value && setBlock) {
            await updateStatusGlobalMessageBlock(true, doctorId)
        }
    }

    async function enableRequestDefaults({ doctorId, setBlock }: { doctorId: string; setBlock: boolean }) {
        await Promise.all([switchRequests({ doctorId, isEnabled: true, setBlock }), setDefaultCategories(doctorId)])
    }

    return {
        canCheckRequestFeatureEnabled,
        enableRequestDefaults,
        fetchIsContactEnabled,
        fetchRequestsIntegration,
        hasDoctorAccessToRequestFeature,
        hasDpPhoneIntegrated,
        hasUserAccessToBlockPatientFeature,
        hasUserAccessToReadRequests,
        isChatContactFromProfileEnabled,
        isGlobalBlockMessageActivated,
        isGruppoIntegratedWithDpPhone,
        isPatientRequestEnabled,
        isUserDoctorType,
        isUserGruppoSecretary,
        isUserMedicalCenter,
        isUserPublicPractitioner,
        isUserPublicPractitionerSecretary,
        shouldAccessNewChat,
        shouldAccessNewConversationView,
        showCanReplyChatFromSettings,
        switchRequests,
        updateContactFromProfileAllowed,
        updateRequestFeature,
        updateStatusGlobalMessageBlock
    }
}
