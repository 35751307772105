export enum DateFormat {
    BlockDate = 'dddd, DD MMM YYYY',
    CalendarDailyFormat = 'ddd, ll',
    CalendarSearchResultFormat = 'dd, D MMM YYYY',
    ChatVisitDateFormat = 'HH:mm • dddd, DD.MM.YYYY',
    DateDashedFormat = 'YYYY-MM-DD',
    DateDayMonthYear = 'DD-MM-YYYY',
    DateDayMonthYearWithSlash = 'DD/MM/YYYY',
    DateFormat = 'D',
    DatePickerFormat = 'dd.MM.yyyy',
    DateStringLogFormat = 'DD/MM/YYYY HH:mm',
    DateStringNoUtcOffset = 'YYYY-MM-DDTHH:mm:ss',
    DatepickerDefaultFormat = 'dd/MM/yyyy',
    DatepickerDefaultFormatDash = 'dd-MM-yyyy',
    DayFullMonthYearTime = 'LL, HH:mm',
    DayMonth = 'DD/MM',
    DayMonthAbbrFormat = 'DD MMM',
    DayMonthAbbrYearFormat = 'DD MMM YYYY',
    DayMonthFormat = 'DD MMMM',
    DayMonthHourFormat = 'DD MMMM, HH:mm',
    DayMonthYearFormat = 'DD MMMM, YYYY',
    DayMonthYearHourMinutesWithDots = 'DD.MM.YYYY HH:mm',
    DayMonthYearHourMonthSecondFormat = 'DD-MM-YYYY HH:mm:ss',
    DayMonthYearWithDots = 'DD.MM.YYYY',
    DayNameAndNumberWithFullMonthYear = 'dddd, D MMMM YYYY',
    DayNameFormat = 'dddd',
    DayNameOfTheWeek = 'dddd',
    DayNameShortFormat = 'ddd',
    DayNameWithMonthNameAndYear = 'Do MMMM YYYY',
    DayNumber = 'd',
    DayOfTheWeekDayMonthFormat = 'dddd, DD/MM',
    DayOfTheWeekHourMinuteFormat = 'dddd, HH:mm',
    DayOfWeekFullMonthYear = 'dddd, LL',
    DaySMonthFormat = 'DD/MM',
    DayWithFullMonth = 'D MMMM',
    DayWithFullMonthYear = 'D MMMM YYYY',
    DefaultFormat = 'DD/MM/YYYY',
    FullMonthFormat = 'MMMM',
    FullYearFormat = 'YYYY',
    HourFormat = 'HH',
    HourMinuteDayNameDayMonthName = 'HH:mm ddd DD MMM',
    HourMinuteFormat = 'HH:mm',
    HourMinuteFormatWith24 = 'kk:mm',
    HourMinuteSecondFormat = 'HH:mm:ss',
    LocalizedDateHourMinute = 'L LT',
    LocalizedDefaultFormat = 'L',
    LocalizedDefaultFormatWithDayNameShort = 'ddd L',
    LocalizedHourMinute = 'LT',
    MinutesFormat = 'mm',
    MonthAbbrDayYearTimeFormat = 'MMM D YYYY, HH:mm',
    MonthDayAbbrFormat = 'MMM YY',
    MonthFormat = 'MMM',
    PatientsListFormat = 'DD-MM-YYYY HH:mm',
    PaymentsVisitFormat = 'dddd DD.MM.YYYY • HH:mm',
    YearAndWeekOfTheYearFormat = 'YYYYww'
}

export enum DateUnit {
    Day = 'day',
    Hour = 'hour',
    Millisecond = 'millisecond',
    Minute = 'minute',
    Month = 'month',
    Quarter = 'quarter',
    Second = 'second',
    Week = 'week',
    Year = 'year'
}

export const AvailableUnit = [
    DateUnit.Day,
    DateUnit.Week,
    DateUnit.Month,
    DateUnit.Quarter,
    DateUnit.Year,
    DateUnit.Hour,
    DateUnit.Minute,
    DateUnit.Second,
    DateUnit.Millisecond
]

export enum Locales {
    AR = 'es',
    BR = 'pt-br',
    CL = 'es',
    CO = 'es',
    CZ = 'cs',
    DE = 'de',
    Default = 'en-gb',
    Es = 'es',
    IT = 'it',
    MX = 'es',
    PL = 'pl',
    PR = 'es',
    PT = 'pt',
    TR = 'it',
    US = 'en'
}
