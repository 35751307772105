import { date, format, toDate } from '@/core/ui/utils'
import CalendarAppointmentsService from '@/features/calendar/domain/calendar.appointments.service'
import BlocksService from '@/features/calendar/domain/calendar.blocks.service'
import HolidaysService from '@/features/calendar/domain/calendar.holidays.service'
import RemindersService from '@/features/calendar/domain/calendar.reminders.service'
import CalendarWorkPeriodsService from '@/features/calendar/domain/calendar.workperiod.service'
import { CalendarEventGroups, CalendarEventType } from '@/features/calendar/domain/enums/calendarEvent.enums'
import { AppointmentDTO } from '@/features/calendar/domain/interfaces/appointment.interfaces'
import { BlockDTO } from '@/features/calendar/domain/interfaces/block.interfaces'
import {
    CalendarEvent,
    CalendarEventDisplay,
    CalendarEventDisplayDTO,
    CalendarEvents,
    CalendarEventsDTO
} from '@/features/calendar/domain/interfaces/event.interfaces'
import { HolidayDTO } from '@/features/calendar/domain/interfaces/holiday.interfaces'
import { CalendarReminder, ReminderDTO } from '@/features/calendar/domain/interfaces/reminder.interfaces'
import { WorkPeriodDTO } from '@/features/calendar/domain/interfaces/workperiod.interfaces'

import { BookingRequestDTO } from '../api/interfaces/bookingRequests.interfaces'
import CalendarBookingRequestsService from './calendar.bookingRequests.service'
import CalendarResourceService from './calendar.resource.service'

class CalendarEventService {
    processEvents(calendarEvents: CalendarEventsDTO): CalendarEvents {
        const { schedules, colorSchemas, countryCode, ...restEvents } = calendarEvents

        return Object.entries(restEvents).reduce((allprocessedItems, [key, items]): CalendarEvents => {
            let processedItems
            switch (key) {
                case CalendarEventGroups.BookingRequests:
                    processedItems = CalendarBookingRequestsService.mapBookingRequestsCollectionToEvent(
                        items as BookingRequestDTO[]
                    )
                    break
                case CalendarEventGroups.Appointments:
                    processedItems = (items as AppointmentDTO[]).flatMap((appointment: AppointmentDTO) =>
                        CalendarAppointmentsService.processAppointment(
                            appointment,
                            countryCode,
                            colorSchemas,
                            schedules
                        )
                    )
                    break
                case CalendarEventGroups.Blocks:
                    processedItems = BlocksService.mapBlocksCollectionToEvent(items as BlockDTO[])
                    break
                case CalendarEventGroups.Holidays:
                    processedItems = HolidaysService.mapHolidaysToEvents(items as HolidayDTO[], schedules, colorSchemas)
                    break
                case CalendarEventGroups.Reminders:
                    processedItems = RemindersService.mapRemindersCollectionToEvent(items as ReminderDTO[])
                    break
                case CalendarEventGroups.Workperiods:
                    processedItems = CalendarWorkPeriodsService.mapWorkperiodsToEvents(
                        items as WorkPeriodDTO[],
                        schedules,
                        colorSchemas
                    )
                    break
                case CalendarEventGroups.Resources:
                    processedItems = CalendarResourceService.mapResourcesCollectionToCalendarResources(
                        items,
                        colorSchemas
                    )
                    break
                // no default
            }

            return {
                ...allprocessedItems,
                [key]: processedItems ?? items
            }
        }, {} as CalendarEvents)
    }

    processStartEndToDate(
        { start, end }: { end: string; start: string },
        duration?: number
    ): { end: Date; start: Date } {
        return {
            start: toDate(start),
            end: duration ? date(new Date(start)).add(duration, 'minute').toDate() : toDate(end)
        }
    }

    processStartEndToISODate({ start, end }: { end: Date; start: Date }): {
        end: string
        start: string
    } {
        return {
            start: format(start),
            end: format(end)
        }
    }

    getEventByType(
        type: CalendarEventType,
        eventId: number
    ): Promise<AppointmentDTO> | Promise<BlockDTO> | Promise<CalendarReminder> {
        switch (type) {
            case CalendarEventType.Appointment:
                return CalendarAppointmentsService.getAppointment(eventId)
            case CalendarEventType.Block:
                return BlocksService.getBlock(eventId)
            case CalendarEventType.Reminder:
                return RemindersService.getReminder(eventId)
            default:
                throw new Error('Incorrect type')
        }
    }

    getEventsToRender(events: CalendarEventDisplayDTO[], isMedicalCenter = false): Readonly<CalendarEventDisplay>[] {
        return events.map(en => {
            const mappedEvent = {
                id: `${en.id}`,
                type: en.type,
                start: new Date(en.start),
                end: new Date(en.end),
                isPreview: en.isPreview,
                isRoomEvent: en.isRoomEvent,
                title: en.title || undefined,
                attendance: en.attendance ?? undefined,
                color: en.color || undefined,
                className: en.className,
                display: en.display,
                displayDefault: en.displayDefault,
                done: en.done,
                insuranceName: en.insuranceName || undefined,
                serviceName: en.serviceName || undefined,
                roomName: en.roomName || undefined,
                noShowProtection: en.noShowProtection,
                service: en.service,
                eventServices: en.eventServices,
                eventServicesIds: en.eventServicesIds,
                scheduleId: en.scheduleId,
                serviceColorSchemaId: en.serviceColorSchemaId,
                colorSchemas: en.colorSchemas || undefined,
                lastModifiedAt: en.lastModifiedAt,
                status: en.status,
                comments: en.comments || undefined,
                doctorFullName: en.doctorFullName,
                doctorId: en.doctorId,
                scheduleName: en.scheduleName || undefined,
                editable: en.editable,
                backgroundColor: en.backgroundColor,
                resourceIds: en.resourceIds,
                resourceId: en.resourceId,
                relatedSchedules: en.relatedSchedules,
                isBlock: en.isBlock,
                isVirtual: en.isVirtual,
                communicationChannel: en.communicationChannel,
                hasVoucher: en.isEventWithVoucher,
                integrated: en.integrated,
                scheduledBy: en.scheduledBy,
                eventType: en.eventType,
                isPatientFirstTime: en.isPatientFirstTime,
                serviceId: en.serviceId,
                recurrentSettingsId: en.recurrentSettingsId,
                durationEditable: en.durationEditable,
                canNotifyPatient: en.canNotifyPatient,
                externalId: en.externalId,
                hasWaitingRoom: en.hasWaitingRoom,
                blockMedicalCenterId: en.blockMedicalCenterId,
                groupId: en.groupId,
                isPatientFirstAdminBooking: en.isPatientFirstAdminBooking,
                isBookedViaSecretaryAi: en.isBookedViaSecretaryAi,
                followUpDateWithSameDoctor: en.followUpDateWithSameDoctor,
                roomId: en.roomId,
                isPaidOnline: en.isPaidOnline
            }
            return Object.freeze(mappedEvent)
        })
    }

    eventsObjectToArray(events: CalendarEvents): CalendarEvent[] {
        return Object.values(events).reduce((calendarEvents, event) => [...calendarEvents, ...event], [])
    }

    getEventGroup(type: CalendarEventType): CalendarEventGroups {
        switch (type) {
            case CalendarEventType.Appointment:
                return CalendarEventGroups.Appointments
            case CalendarEventType.Block:
                return CalendarEventGroups.Blocks
            case CalendarEventType.Holiday:
                return CalendarEventGroups.Holidays
            case CalendarEventType.Reminder:
                return CalendarEventGroups.Reminders
            case CalendarEventType.Workperiod:
                return CalendarEventGroups.Workperiods
            case CalendarEventType.BookingRequest:
                return CalendarEventGroups.BookingRequests
            default:
                throw new Error('Incorrect type')
        }
    }
}

export default new CalendarEventService()
