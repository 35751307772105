import { isTstOrDevelopment } from '@/bootstrap/environment'
import { AuthGetterTypes } from '@/features/auth/ui/store/types'
import Storage from '@/store/storage'
import { OVERRIDE_SELECTED_COUNTRY_SETTINGS, SELECTED_TRANSLATION } from '@/store/storage-types'

const getters = {
    selectedDate: state => state.selectedDate,
    featuresReady: state => !!state.features,
    features: state => state.features,
    feature: (state, otherGetters) => name => {
        return otherGetters.features ? otherGetters.features[name] || false : false
    },
    culture: (state, otherGetters) => {
        return otherGetters[AuthGetterTypes.GetAuthUser]
            ? otherGetters[AuthGetterTypes.GetAuthUser].culture
            : state.culture
    },
    locale: (state, otherGetters) => {
        let locale

        if (isTstOrDevelopment) {
            locale = Storage.get(SELECTED_TRANSLATION)
        }

        return locale || otherGetters.culture
    },
    appState: state => state.appState,
    hasAccessToAdminPanel: (_, otherGetters) =>
        isTstOrDevelopment ? true : otherGetters[AuthGetterTypes.IsImpersonator],
    hasAccessToPms: (_, otherGetters) => {
        return (
            (otherGetters.countrySettings.hasAccessToPms || otherGetters[AuthGetterTypes.HasUserAccessToPms]) &&
            !otherGetters[AuthGetterTypes.IsSecretary]
        )
    },
    hasAccessToPatientEditPage: (state, otherGetters) => {
        return otherGetters[AuthGetterTypes.GetAuthUser].hasUserAccessToPatientEditPage
    },
    hasAccessToEditPrintLayout: (_, otherGetters) => {
        const secretaryAccess =
            otherGetters[AuthGetterTypes.GetAuthUser].userSettings.hasAccessToEditPrintLayout === 'true' &&
            otherGetters[AuthGetterTypes.IsSecretary]

        return otherGetters[AuthGetterTypes.IsDoctor] || secretaryAccess
    },
    isVoucherEnabled: (_, otherGetters) => {
        return otherGetters[AuthGetterTypes.GetAuthUser].userSettings.isVoucherEnabled === 'true'
    },
    isQuotesEnabled: (_, otherGetters) => {
        return otherGetters[AuthGetterTypes.GetAuthUser].userSettings.isQuoteEnabled === 'true'
    },
    isOnlinePaymentEnabled: (_, otherGetters) => {
        return otherGetters[AuthGetterTypes.GetAuthUser]?.userSettings?.isOnlinePaymentsEnabled === 'true'
    },
    isStoreCalendarFiltersEnabled: (_, otherGetters) => {
        return otherGetters[AuthGetterTypes.GetAuthUser].userSettings?.storeCalendarFilters === 'true'
    },
    countrySettings: state => {
        if (isTstOrDevelopment) {
            return {
                ...(state.countrySettings || {}),
                ...(Storage.get(OVERRIDE_SELECTED_COUNTRY_SETTINGS) || {})
            }
        }

        return state.countrySettings
    },
    countrySpecificShowNameBeforeSurname: (_, otherGetters) =>
        otherGetters.countrySettings?.patientShowNameBeforeSurname,
    showInsuranceFilterInCampaigns: (_, otherGetters) =>
        otherGetters.countrySettings && otherGetters.countrySettings.showInsuranceFilterInCampaigns,
    showSchedulesHelpCenterTouchpoint: (_, otherGetters) =>
        otherGetters.countrySettings && otherGetters.countrySettings.showSchedulesHelpCenterTouchpoint,
    showServiceScheduleSettingsByInsurance: (_, otherGetters) =>
        otherGetters.countrySettings && otherGetters.countrySettings.showServiceScheduleSettingsByInsurance,
    showServicesNewConfiguration: (_, otherGetters) =>
        otherGetters.countrySettings && otherGetters.countrySettings.showServicesNewConfiguration,
    minPriceForVirtualScheduleService: (_, otherGetters) =>
        otherGetters.countrySettings && otherGetters.countrySettings.minPriceForVirtualScheduleService,
    isShowServiceSelectNew: (_, otherGetters) =>
        otherGetters.countrySettings && otherGetters.countrySettings.showServiceSelect === 'new',
    showFreeSlotsInsuranceFilter: (_, otherGetters) =>
        otherGetters.countrySettings && otherGetters.countrySettings.showFreeSlotsInsuranceFilter,
    showGpFeatures: (_, otherGetters) => otherGetters.countrySettings && otherGetters.countrySettings.showGpFeatures,
    showBookingRequestsFeature: (_, otherGetters) =>
        otherGetters.countrySettings && otherGetters.countrySettings.showBookingRequestsFeature,
    currencyFormat: (_, otherGetters) => {
        if (otherGetters.countrySettings && otherGetters.countrySettings.currencyFormat) {
            return otherGetters.countrySettings.currencyFormat
        }
        return null
    },
    newServiceMethod: (_, otherGetters) =>
        otherGetters.countrySettings && otherGetters.countrySettings.newServiceMethod,
    showRequestServiceLink: (_, otherGetters) =>
        otherGetters.countrySettings && otherGetters.countrySettings.showRequestServiceLink,
    hideServiceDescription: (_, otherGetters) =>
        otherGetters.countrySettings && otherGetters.countrySettings.hideServiceDescription,
    isDecustomizeServicesOn: (_, otherGetters) => otherGetters.feature('decustomizeservices'),
    authProvider: state => state.authProvider,
    isCampaignNewPatientEnabled: (state, otherGetters) => otherGetters.feature('campaignnewpatient')
}

export default getters
