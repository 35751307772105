import { Schedule } from '@/features/schedule/domain/interfaces/schedule.interface'

import { get } from '../agent'

const URL_BASE = 'schedules'
const URL_OWN_SCHEDULE = `${URL_BASE}?owner=true'`

class PaymentSchedulesRepository {
    getSchedules() {
        return get<Schedule[]>(URL_BASE)
    }

    getOwnSchedule() {
        return get<Schedule[]>(URL_OWN_SCHEDULE)
    }
}

export default new PaymentSchedulesRepository()
