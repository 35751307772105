import {
    GETTER_EDITING_PATIENT_EPISODE,
    GETTER_EPISODE_TYPES,
    GETTER_IS_DRS_COPILOT_TRAINING_USAGE_ACCEPTED,
    GETTER_PATIENT,
    GETTER_SERVICES,
    GETTER_SNIPPETS,
    GETTER_TEMPLATE_SMART_PROPERTIES,
    GETTER_TEMPLATES
} from './types/getters'

const getters = {
    [GETTER_PATIENT](state) {
        return state.patient
    },
    [GETTER_SERVICES](state) {
        return state.services
    },
    [GETTER_SNIPPETS](state) {
        return state.snippets
    },
    [GETTER_TEMPLATES](state) {
        return state.templates
    },
    [GETTER_TEMPLATE_SMART_PROPERTIES](state) {
        return state.templateSmartProperties
    },
    [GETTER_EPISODE_TYPES](state) {
        return state.episodeTypes
    },
    [GETTER_EDITING_PATIENT_EPISODE](state) {
        return state.episode
    },
    [GETTER_IS_DRS_COPILOT_TRAINING_USAGE_ACCEPTED](state) {
        return state.isDoctorsCopilotTrainingUsageAccepted
    }
}

export default getters
