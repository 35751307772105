export const AvailableProjects = {
    reverseTrial: 'REVERSE_TRIAL',
    freemium: 'FREEMIUM',
    customerEngagement: 'CUSTOMER_ENGAGEMENT'
}

export const getProjectWSCode = (project: string) => {
    switch (project) {
        case AvailableProjects.reverseTrial:
            return 'SalyHBF39L4hVMdu'
        case AvailableProjects.freemium:
            return 'YiY7RkAcUTCggU6h'
        case AvailableProjects.customerEngagement:
            return 'AReXPGY3d9PzB9oV'
        default:
            return ''
    }
}
