import Config from '@/api/Config'
import Culture from '@/api/Culture'
import Features from '@/api/Features'
import { MODULE_ADMIN } from '@/features/admin/ui/store/types/module'
import { ADMIN_IMPORT_RESET } from '@/features/admin/ui/store/types/mutations'
import { AuthMutationTypes } from '@/features/auth/ui/store/types'
import { MODULE_HINTS, MutationTypes } from '@/features/hints/ui/store/types'
import { MODULE_INVOICE } from '@/features/invoice/ui/store/types/module'
import { MUTATION_INVOICES_RESET_STATE } from '@/features/invoice/ui/store/types/mutations'
import { MODULE_SETTINGS_USERS } from '@/features/settings/ui/store/users/types/module'
import { MUTATION_USERS_RESET_STATE } from '@/features/settings/ui/store/users/types/mutation'
import { ACTION_SET_APP_STATE } from '@/store/actions-types'
import {
    RESET_MODULE_STORES,
    SET_APP_STATE,
    SET_AUTH_PROVIDER,
    SET_COUNTRY_SETTINGS,
    SET_DATE,
    SET_FEATURES
} from '@/store/mutation-types'

const FeaturesApi = Features()
const CultureApi = Culture()
const ConfigApi = Config()

const actions = {
    'calendar.action.goTo': function ({ commit }, value) {
        commit(SET_DATE, value)
    },
    [ACTION_SET_APP_STATE]({ commit }, appState) {
        commit(SET_APP_STATE, appState)
    },
    FETCH_FEATURES({ commit }) {
        return FeaturesApi.fetch()
            .then(result => {
                commit(SET_FEATURES, result.data)
                return Promise.resolve(result)
            })
            .catch(error => {
                return Promise.reject(error)
            })
    },
    FETCH_TRANSLATIONS({ commit }, lang) {
        return CultureApi.fetchLanguage(lang)
            .then(result => {
                return Promise.resolve(result)
            })
            .catch(error => {
                return Promise.reject(error)
            })
    },
    FETCH_COUNTRY_SETTINGS({ commit }, culture) {
        return ConfigApi.fetchCountrySettings(culture)
            .then(result => {
                commit(SET_COUNTRY_SETTINGS, result.data)
                return Promise.resolve(result)
            })
            .catch(error => {
                return Promise.reject(error)
            })
    },
    [RESET_MODULE_STORES]({ commit }) {
        commit(AuthMutationTypes.ResetAuth)
        commit(`${MODULE_HINTS}/${MutationTypes.ResetHints}`)
        commit(`${MODULE_INVOICE}/${MUTATION_INVOICES_RESET_STATE}`)
        commit(`${MODULE_SETTINGS_USERS}/${MUTATION_USERS_RESET_STATE}`)
        commit(`${MODULE_ADMIN}/${ADMIN_IMPORT_RESET}`)
    }
}

export default actions
