import dayjs, { Dayjs } from 'dayjs'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import duration from 'dayjs/plugin/duration'
import isBetween from 'dayjs/plugin/isBetween'
import isLeapYear from 'dayjs/plugin/isLeapYear'
import isoWeek from 'dayjs/plugin/isoWeek'
import isoWeeksInYear from 'dayjs/plugin/isoWeeksInYear'
import isToday from 'dayjs/plugin/isToday'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import objectSupport from 'dayjs/plugin/objectSupport'
import relativeTime from 'dayjs/plugin/relativeTime'
import utc from 'dayjs/plugin/utc'
import weekOfYear from 'dayjs/plugin/weekOfYear'

import { numberToString } from '@/utils/string'

import { DateFormat, DateUnit, Locales } from './date.enums'

dayjs.extend(utc)
dayjs.extend(advancedFormat)
dayjs.extend(customParseFormat)
dayjs.extend(localizedFormat)
dayjs.extend(isBetween)
dayjs.extend(isoWeek)
dayjs.extend(objectSupport)
dayjs.extend(weekOfYear)
dayjs.extend(isToday)
dayjs.extend(relativeTime)
dayjs.extend(duration)
dayjs.extend(isoWeeksInYear)
dayjs.extend(isLeapYear)

const HOUR_STRING_LENGTH = 5
const MILLISECONDS_PER_DAY = 1000 * 60 * 60 * 24

export function format(date: string | Date | Dayjs): string {
    return dayjs(date).format(DateFormat.DateStringNoUtcOffset)
}

export function formatDate(date: string | Date | Dayjs, template = DateFormat.DefaultFormat): string {
    return dayjs(date).format(template)
}

export function formatDateWithLocale(
    date: string | Date | Dayjs,
    template: DateFormat = DateFormat.DefaultFormat,
    localization: string = Locales.Default
): string {
    return dayjs(date).locale(localization).format(template)
}

export function startOfDate(date: string | Date | Dayjs): dayjs.Dayjs {
    return dayjs(date).startOf(DateUnit.Day)
}

export function yearDifference(date: string | Date | Dayjs, compareDate: string | Date | Dayjs = dayjs()): number {
    return Math.abs(dayjs(compareDate).diff(date, DateUnit.Year))
}

export const monthDaysDifference = (
    birthDate: string | Date | Dayjs,
    endDate: string | Date | Dayjs = dayjs()
): { days: number; months: number } => {
    const start = dayjs(birthDate).toDate()
    const end = dayjs(endDate).toDate()

    let yearsDiff = end.getFullYear() - start.getFullYear()
    let monthsDiff = end.getMonth() - start.getMonth()
    let daysDiff = end.getDate() - start.getDate()

    if (daysDiff < 0) {
        monthsDiff -= 1
        const lastMonth = new Date(end.getFullYear(), end.getMonth() - 1, start.getDate())
        daysDiff = Math.floor((end.getTime() - lastMonth.getTime()) / MILLISECONDS_PER_DAY)
    }

    if (monthsDiff < 0) {
        yearsDiff -= 1
        monthsDiff += 12
    }

    const totalMonths = yearsDiff * 12 + monthsDiff

    return {
        months: totalMonths,
        days: daysDiff
    }
}

export function endDateIsGreaterThanStartDate({ endDate, startDate }: { endDate: Dayjs; startDate: Dayjs }): boolean {
    return endDate.diff(startDate, 'm') > 0
}

export function formatHourString(value: string): string {
    return value.length === HOUR_STRING_LENGTH ? value : `0${value}`
}

export function toDate(date: string | Date | Dayjs, template?: DateFormat): Date {
    if (!template) {
        return dayjs(date).toDate()
    }
    return dayjs(date, template).toDate()
}

export function isSameDay(date: string | Date | Dayjs, compareDate: string | Date | Dayjs = new Date()) {
    return dayjs(date).isSame(compareDate, DateUnit.Day)
}

export function isTodayDate(date: string | Date | Dayjs = new Date()) {
    return dayjs(date).isToday()
}

export function isTomorrowOrFuture(dateToCheck: string | Date | Dayjs) {
    return dayjs(dateToCheck).isAfter(new Date(), DateUnit.Day)
}

export function isFirstDateAfterSecondDate(firstDate: string | Date | Dayjs, secondDate: string | Date | Dayjs) {
    return dayjs(firstDate).isAfter(secondDate)
}

export function timeDifferent(
    date: string | Date | Dayjs,
    compareDate: string | Date | Dayjs = new Date(),
    unit: DateUnit = DateUnit.Minute
): number {
    return dayjs(compareDate).diff(date, unit)
}

export function isBeforeDate(date: string | Date | Dayjs, compareDate: string | Date | Dayjs = new Date()): boolean {
    return dayjs(date).isBefore(compareDate)
}

export function isBeforeDay(date: string | Date | Dayjs, compareDate: string | Date | Dayjs = new Date()): boolean {
    return dayjs(date).isBefore(compareDate, DateUnit.Day)
}

export function isBetweenDate(
    date: string | Date | Dayjs,
    from: string | Date | Dayjs,
    to: string | Date | Dayjs
): boolean {
    return dayjs(date).isBetween(from, to, DateUnit.Day, '[]')
}

export function nearestPastMinutes(interval: number, someDate: string | Date | Dayjs, template?: DateFormat): Dayjs {
    someDate = template ? dayjs(someDate, template) : dayjs(someDate)
    const roundedMinutes = Math.floor(someDate.minute() / interval) * interval
    return someDate.clone().minute(roundedMinutes).second(0)
}

export function nearestFutureMinutes(interval: number, someDate: string | Date | Dayjs, template?: DateFormat): Dayjs {
    someDate = template ? dayjs(someDate, template) : dayjs(someDate)
    const roundedMinutes = Math.ceil(someDate.minute() / interval) * interval
    return someDate.clone().minute(roundedMinutes).second(0)
}

export function hourMinute(date: string | Date | Dayjs): string {
    return formatDate(date, DateFormat.HourMinuteFormat)
}

export function parseDate(date: string | Date | Dayjs, template?: DateFormat): Dayjs | null {
    const dateInstance = template ? dayjs(date, template) : dayjs(date)
    return dateInstance.isValid() ? dateInstance : null
}

export function addToDate(date: string | Date | Dayjs, amount: number, unit: DateUnit): Dayjs {
    return dayjs(date).add(amount, unit as dayjs.OpUnitType)
}

export function subtractToDate(date: string | Date | Dayjs, amount: number, unit: DateUnit): Dayjs {
    return dayjs(date).subtract(amount, unit as dayjs.OpUnitType)
}

export function isDateValid(date: string | Date | Dayjs): boolean {
    return dayjs(date).isValid()
}

export function utcToLocal(date: string | Date | Dayjs): Dayjs {
    return dayjs.utc(date).local()
}

export function dateToUtc(date: string | Date | Dayjs): Dayjs {
    return dayjs.utc(date)
}

export function startOf(date: string | Date | Dayjs, unit: DateUnit): Dayjs {
    return dayjs(date).startOf(unit as dayjs.OpUnitType)
}

export function endOf(date: string | Date | Dayjs, unit: DateUnit): Dayjs {
    return dayjs(date).endOf(unit as dayjs.OpUnitType)
}

export function isPast(date: string | Date | Dayjs): boolean {
    return dayjs(date).isBefore(dayjs())
}

export function getShortDayName(date: string | Date | Dayjs) {
    return dayjs(date).format(DateFormat.DayNameShortFormat).replace(/\./g, '')
}

export function getWeekOfTheYear(date: string | Date | Dayjs): number {
    return dayjs(date).week()
}

export function getTimeFromNow(date: string | Date | Dayjs, withoutSuffix?: boolean): string {
    return dayjs(date).fromNow(withoutSuffix)
}

export function getEarliestDate(dates: string[] | Date[] | Dayjs[]): Dayjs {
    return dayjs(dates.sort((date1, date2) => dayjs(date1).unix() - dayjs(date2).unix())[0])
}

export function getLatestDate(dates: string[] | Date[] | Dayjs[]): Dayjs {
    return dayjs(dates.sort((date1, date2) => dayjs(date2).unix() - dayjs(date1).unix())[0])
}

// not used
export function getHourOfDayByInitialHour(initialHour: number) {
    if (initialHour <= 0) {
        return 0
    }

    if (initialHour >= 24) {
        return 24
    }

    return initialHour
}

// not used
export function getTimeFromMinutes(minutes: number, timeOffset = 0): string {
    const initialHour = Number(minutes / 60) + timeOffset

    const hourOfDay = getHourOfDayByInitialHour(initialHour)

    const initialMinutes = hourOfDay === 24 || initialHour < 0 ? 0 : Number(minutes % 60)

    return `${numberToString(hourOfDay)}:${numberToString(initialMinutes)}`
}

export const createDateFromTime = (time: string, someDate?: string | Date | Dayjs): dayjs.Dayjs => {
    const [hour, minute] = time.split(':')

    if (!someDate) {
        return dayjs().set({ h: hour, m: minute, s: 0, ms: 0 })
    }

    return dayjs(someDate).set({ h: hour, m: minute, s: 0, ms: 0 })
}

export const firstDayNextMonth = (date: string | Date | Dayjs = dayjs()): dayjs.Dayjs => {
    return addToDate(date, 1, DateUnit.Month).startOf(DateUnit.Month)
}

// Mising test
export function locale(culture: string) {
    import(
        /* webpackChunkName: "[request]-dayjs-locale" */
        `dayjs/locale/${culture}.js`
    )
        .then(result => {
            dayjs.locale({
                ...result,
                weekStart: 1
            })
        })
        .catch(e => {
            throw new Error(`Dayjs "${culture}": ${e.message}`)
        })
}

export const resetDateTime = (dateToReset: string): Date => {
    const date = new Date(dateToReset)
    date.setHours(0, 0, 0, 0)
    return date
}

export const convertToLocalDateString = (dateString: string): string => {
    const date = new Date(dateString)
    return new Date(date.getTime() - date.getTimezoneOffset() * 60000).toISOString().split('T')[0]
}

export const date = dayjs
