import {
    PatientActionAuditLog,
    PatientLog,
    PatientMerge
} from '@/features/patient/domain/interfaces/patient.interfaces'

import { PatientActionAuditLogDto, PatientLogDto, PatientMergeDto } from '../../api/interfaces/patient.log.interfaces'

const mapPatientActionAuditLog = (audit: PatientActionAuditLogDto | null): PatientActionAuditLog => {
    return {
        auditMessage: null,
        endTime: null,
        patientId: null,
        startTime: null,
        succeeded: null,
        ...audit
    }
}

const mapPatientMerge = (merge: PatientMergeDto | null): PatientMerge => {
    return {
        patientMergeId: null,
        patientSourceId: null,
        patientSourceName: null,
        patientTargetId: null,
        patientTargetName: null,
        ...merge
    }
}

export const mapPatientLogFromDTO = (log: PatientLogDto): PatientLog => {
    return {
        ...log,
        patientAudit: mapPatientActionAuditLog(log.patientAudit),
        patientMerge: mapPatientMerge(log.patientMerge)
    }
}
