import { get } from '@/api/utils/httpClientAPI'

import { OnboardingWizardProgressDTO } from '../interfaces'

const URL_BASE_ONBOARDING = 'onboarding-wizard'
const URL_PROGRESS = `${URL_BASE_ONBOARDING}/progress`
const URL_HAS_TO_DISPLAY = `${URL_BASE_ONBOARDING}/hastodisplay`

const getOnboardingProgress = async () => {
    return get<OnboardingWizardProgressDTO>(URL_PROGRESS)
}

const hasToDisplay = async () => {
    return get<boolean>(URL_HAS_TO_DISPLAY)
}

export default { getOnboardingProgress, hasToDisplay }
