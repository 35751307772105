import * as Sentry from '@sentry/browser'
import { xor } from 'lodash'

import { AuthGetterTypes } from '@/features/auth/ui/store/types'
import CalendarEventService from '@/features/calendar/domain/calendar.event.service'
import CalendarOrderColumnsService from '@/features/calendar/domain/calendar.orderColumns.service'
import CalendarService from '@/features/calendar/domain/calendar.service'
import CalendarSettingsDoctorService from '@/features/calendar/domain/calendar.settings.doctor.service'
import { CALENDAR_KIND, STORE_CALENDAR_FILTERS_STORAGE_KEY } from '@/features/calendar/ui/constants/calendar.constants'
import { HIDE_BOOKED_SLOTS_KEY } from '@/features/calendar/ui/constants/calendar.settings.constants'
import DoctorService from '@/features/doctor/domain/doctor.service'
import FacilityService from '@/features/patient/domain/facility.service'
import RoomService from '@/features/rooms/domain/rooms.service'
import ScheduleService from '@/features/schedule/domain/schedule.service'
import ServicesService from '@/features/servicesLegacy/domain/services.service'
import storage from '@/store/storage'

import { CalendarOrderType } from '../../domain/enums/calendar.enums'
import calendarZoomActions from './calendarZoom/actions'
import {
    ACTION_FETCH_AGENDA_EVENTS,
    ACTION_FETCH_CALENDAR_COLOR_SCHEMAS,
    ACTION_FETCH_CALENDAR_EVENTS,
    ACTION_FETCH_CALENDAR_EVENTS_BY_DATE_RANGE,
    ACTION_FETCH_CALENDAR_FILTER_SERVICES,
    ACTION_FETCH_CALENDAR_SCHEDULES,
    ACTION_FETCH_CALENDAR_SCHEDULES_ORDER,
    ACTION_FETCH_FACILITIES,
    ACTION_FETCH_FILTERS,
    ACTION_GET_CALENDAR_EVENTS,
    ACTION_MAP_CALENDAR_EVENTS,
    ACTION_SELECT_ALL_DEVICES,
    ACTION_SELECT_ALL_DOCTORS,
    ACTION_SELECT_ALL_ROOMS,
    ACTION_SELECT_ALL_SCHEDULES,
    ACTION_SELECT_ALL_STAFF,
    ACTION_SELECT_DOCTOR,
    ACTION_SELECT_DOCTOR_FOR_FREE_SLOTS_VIEW,
    ACTION_SELECT_FACILITY,
    ACTION_SELECT_SCHEDULE,
    ACTION_SELECT_SPECIALITY,
    ACTION_SET_ALL_DOCTORS_FOR_FREE_SLOTS_VIEW,
    ACTION_SET_CALENDAR_CREATION_ADDRESS,
    ACTION_SET_CALENDAR_CREATION_COLOR,
    ACTION_SET_CALENDAR_CREATION_NAME,
    ACTION_SET_CALENDAR_CREATION_RESET,
    ACTION_SET_CALENDAR_CREATION_SERVICES,
    ACTION_SET_CALENDAR_CREATION_TYPE,
    ACTION_SET_CALENDAR_HIDE_BOOKED_SLOTS,
    ACTION_SET_CALENDAR_SELECTED_SCHEDULES,
    ACTION_SET_CALENDAR_SELECTED_SCHEDULES_FOR_FREE_SLOTS_VIEW,
    ACTION_SET_CALENDAR_SELECTED_SCHEDULES_WORKING_CURRENT_PERIOD,
    ACTION_SET_DOCTOR_SCHEDULES,
    ACTION_SET_DOCTORS_WORKING_CURRENT_PERIOD,
    ACTION_SET_FREE_SLOTS_FILTER_DAYS,
    ACTION_SET_FREE_SLOTS_FILTER_DAYTIME,
    ACTION_SET_FREE_SLOTS_FILTER_INSURANCE,
    ACTION_SET_FREE_SLOTS_SELECTED_SERVICE,
    ACTION_SET_ONLY_DOCTORS_SELECTED,
    GETTER_AGENDA_RANGE,
    GETTER_CALENDAR_DEVICES,
    GETTER_CALENDAR_FILTER_SELECTED_SERVICES,
    GETTER_CALENDAR_ORDER,
    GETTER_CALENDAR_ROOMS,
    GETTER_CALENDAR_STAFF,
    GETTER_COLOR_SCHEMAS,
    GETTER_DOCTORS_WORKING_CURRENT_PERIOD,
    GETTER_DOCTORS_WORKING_CURRENT_PERIOD_TOGGLE,
    GETTER_RESOURCES_ID_SELECTED,
    GETTER_SCHEDULES_WORKING_CURRENT_PERIOD,
    GETTER_SELECTED_DATE_RANGE_BY_PERIOD,
    GETTER_SELECTED_DOCTORS,
    GETTER_SELECTED_ROOMS,
    GETTER_SELECTED_SCHEDULES,
    MODULE_CALENDAR,
    MUTATION_CLEAN_CALENDAR_EVENTS,
    MUTATION_SET_AGENDA_EVENTS,
    MUTATION_SET_CALENDAR_COLOR_SCHEMAS,
    MUTATION_SET_CALENDAR_COLOR_SCHEMAS_ARRAY,
    MUTATION_SET_CALENDAR_CREATION_ADDRESS,
    MUTATION_SET_CALENDAR_CREATION_COLOR,
    MUTATION_SET_CALENDAR_CREATION_NAME,
    MUTATION_SET_CALENDAR_CREATION_RESET,
    MUTATION_SET_CALENDAR_CREATION_SERVICES,
    MUTATION_SET_CALENDAR_CREATION_TYPE,
    MUTATION_SET_CALENDAR_EVENTS,
    MUTATION_SET_CALENDAR_EVENTS_DATE_RANGE,
    MUTATION_SET_CALENDAR_EVENTS_WEEK_NUMBER,
    MUTATION_SET_CALENDAR_FILTER_SERVICES,
    MUTATION_SET_CALENDAR_LOADING,
    MUTATION_SET_CALENDAR_ROOMS,
    MUTATION_SET_CALENDAR_SCHEDULES,
    MUTATION_SET_CALENDAR_SCHEDULES_ORDER,
    MUTATION_SET_CALENDAR_SELECTED_ROOMS,
    MUTATION_SET_CALENDAR_SELECTED_SCHEDULES,
    MUTATION_SET_DOCTOR_SCHEDULE_SELECTED,
    MUTATION_SET_DOCTOR_SCHEDULES,
    MUTATION_SET_DOCTORS,
    MUTATION_SET_DOCTORS_WORKING_CURRENT_PERIOD,
    MUTATION_SET_FACILITIES,
    MUTATION_SET_FREE_SLOTS_FILTER_DAYS,
    MUTATION_SET_FREE_SLOTS_FILTER_DAYTIME,
    MUTATION_SET_FREE_SLOTS_FILTER_INSURANCE,
    MUTATION_SET_FREE_SLOTS_SELECTED_SERVICE,
    MUTATION_SET_ONLY_DOCTORS_SELECTED,
    MUTATION_SET_SCHEDULES,
    MUTATION_SET_SELECTED_FACILITY,
    MUTATION_SET_SELECTED_SPECIALITY,
    MUTATION_SET_SELECTED_SPECIALITY_GROUP,
    MUTATION_TOGGLE_CALENDAR_HIDE_BOOKED_SLOTS,
    MUTATION_UPDATE_DOCTORS,
    MUTATION_UPDATE_DOCTORS_SELECTED
} from './types'

export const actions = {
    async [ACTION_GET_CALENDAR_EVENTS]({ state, dispatch }, date) {
        const isInRange = CalendarService.isDateInCalendarEventsWeekRange(date, state.calendarEventsWeekRangeNumbers)

        if (!isInRange) {
            dispatch(ACTION_FETCH_CALENDAR_EVENTS, date)
        }
    },
    async [ACTION_FETCH_CALENDAR_EVENTS]({ state, commit, dispatch, getters, rootGetters }, date) {
        if (state.isLoading) {
            return
        }

        let resourceIds = []
        if (state.fetchSchedulesSelected) {
            resourceIds = getters[GETTER_DOCTORS_WORKING_CURRENT_PERIOD_TOGGLE]
                ? []
                : getters[GETTER_RESOURCES_ID_SELECTED]
        }

        commit(MUTATION_SET_CALENDAR_LOADING, true)

        const currentSpan = Sentry.getCurrentScope().getSpan()
        const areBookingRequestsAllowed = rootGetters[AuthGetterTypes.AllowBookingRequests]
        const { docplannerDoctorId } = rootGetters[AuthGetterTypes.GetAuthUser]

        const spanGetCalendarEventsFromStore = currentSpan?.startChild({
            name: 'SAAS ::getCalendarEvents::',
            description: 'Get calendar events and emit some store actions',
            op: 'http.client'
        })

        const { dateRange, calendarEvents } = await CalendarService.getCalendarEvents(
            date,
            resourceIds,
            areBookingRequestsAllowed,
            docplannerDoctorId
        )

        spanGetCalendarEventsFromStore?.end()

        const spanMutationSetCalendarEventsDateRange = currentSpan?.startChild({
            name: 'SAAS ::MUTATION_SET_CALENDAR_EVENTS_DATE_RANGE::',
            description: 'Set calendar date range into the store',
            op: 'function'
        })
        commit(MUTATION_SET_CALENDAR_EVENTS_DATE_RANGE, dateRange)
        spanMutationSetCalendarEventsDateRange?.end()

        const spanMutationSetCalendarEventsWeekNumber = currentSpan?.startChild({
            name: 'SAAS ::MUTATION_SET_CALENDAR_EVENTS_WEEK_NUMBER::',
            description: 'Set calendar week number into the store',
            op: 'function'
        })
        commit(MUTATION_SET_CALENDAR_EVENTS_WEEK_NUMBER, dateRange)
        spanMutationSetCalendarEventsWeekNumber?.end()

        dispatch(ACTION_MAP_CALENDAR_EVENTS, calendarEvents)
        commit(MUTATION_SET_CALENDAR_LOADING, false)
    },
    async [ACTION_FETCH_CALENDAR_EVENTS_BY_DATE_RANGE]({ dispatch }, dateRange) {
        const calendarEvents = await CalendarService.getCalendarEventsByDateRange(dateRange)

        dispatch(ACTION_MAP_CALENDAR_EVENTS, calendarEvents)

        return calendarEvents
    },
    [ACTION_MAP_CALENDAR_EVENTS]({ commit, dispatch }, calendarEvents) {
        const currentSpan = Sentry.getCurrentScope().getSpan()

        const spanActionMapCalendarEvents = currentSpan?.startChild({
            name: 'SAAS ::ACTION_MAP_CALENDAR_EVENTS::',
            description: 'Process received events',
            op: 'function'
        })
        const mappedCalendarEvents = CalendarEventService.processEvents(calendarEvents)
        spanActionMapCalendarEvents?.end()

        commit(MUTATION_SET_CALENDAR_EVENTS, mappedCalendarEvents)
        dispatch(ACTION_SET_CALENDAR_SELECTED_SCHEDULES_WORKING_CURRENT_PERIOD)
    },
    async [ACTION_SET_CALENDAR_SELECTED_SCHEDULES_WORKING_CURRENT_PERIOD]({ state, commit, getters }) {
        if (getters[GETTER_DOCTORS_WORKING_CURRENT_PERIOD_TOGGLE]) {
            const schedulesIds = state.schedules.map(({ id }) => id)
            const schedulesWorkingCurrentPeriodIds = getters[GETTER_SCHEDULES_WORKING_CURRENT_PERIOD].filter(
                ({ selected }) => selected
            ).map(({ id }) => id)

            const doctorIds = getters[GETTER_DOCTORS_WORKING_CURRENT_PERIOD].filter(({ selected }) => selected).map(
                ({ id }) => id
            )

            commit(MUTATION_UPDATE_DOCTORS_SELECTED, {
                doctorIds,
                selected: true
            })

            commit(MUTATION_SET_CALENDAR_SELECTED_SCHEDULES, {
                scheduleIds: xor(schedulesIds, schedulesWorkingCurrentPeriodIds),
                selected: false
            })

            commit(MUTATION_SET_CALENDAR_SELECTED_SCHEDULES, {
                scheduleIds: schedulesWorkingCurrentPeriodIds,
                selected: true
            })
        }
    },
    async [ACTION_SET_CALENDAR_CREATION_TYPE]({ commit }, type) {
        commit(MUTATION_SET_CALENDAR_CREATION_TYPE, type)
    },
    async [ACTION_SET_CALENDAR_CREATION_ADDRESS]({ commit }, address) {
        commit(MUTATION_SET_CALENDAR_CREATION_ADDRESS, address)
    },
    async [ACTION_SET_CALENDAR_CREATION_SERVICES]({ commit }, services) {
        commit(MUTATION_SET_CALENDAR_CREATION_SERVICES, services)
    },
    async [ACTION_SET_CALENDAR_CREATION_NAME]({ commit }, name) {
        commit(MUTATION_SET_CALENDAR_CREATION_NAME, name)
    },
    async [ACTION_SET_CALENDAR_CREATION_COLOR]({ commit }, color) {
        commit(MUTATION_SET_CALENDAR_CREATION_COLOR, color)
    },
    async [ACTION_SET_CALENDAR_CREATION_RESET]({ commit }) {
        commit(MUTATION_SET_CALENDAR_CREATION_RESET)
    },
    async [ACTION_FETCH_CALENDAR_FILTER_SERVICES]({ commit, getters }) {
        const services = await ServicesService.getServices()
        commit(
            MUTATION_SET_CALENDAR_FILTER_SERVICES,
            ServicesService.matchSelectedPropertyBetweenLists(
                services,
                getters[GETTER_CALENDAR_FILTER_SELECTED_SERVICES]
            )
        )
    },
    async [ACTION_SELECT_DOCTOR]({ commit, getters }, { doctorId, selected }) {
        if (getters[GETTER_DOCTORS_WORKING_CURRENT_PERIOD_TOGGLE]) {
            commit(MUTATION_UPDATE_DOCTORS, getters[GETTER_DOCTORS_WORKING_CURRENT_PERIOD])
            commit(MUTATION_SET_SCHEDULES, getters[GETTER_SCHEDULES_WORKING_CURRENT_PERIOD])
        }

        commit(MUTATION_SET_DOCTORS_WORKING_CURRENT_PERIOD, false)
        commit(MUTATION_SET_SELECTED_SPECIALITY_GROUP, null)

        commit(MUTATION_UPDATE_DOCTORS_SELECTED, {
            doctorIds: [doctorId],
            selected
        })
    },
    async [ACTION_SELECT_DOCTOR_FOR_FREE_SLOTS_VIEW]({ commit }, { doctorId, selected }) {
        commit(MUTATION_SET_DOCTORS_WORKING_CURRENT_PERIOD, false)
        commit(MUTATION_UPDATE_DOCTORS_SELECTED, {
            doctorIds: [doctorId],
            selected
        })
    },
    [ACTION_SET_CALENDAR_SELECTED_SCHEDULES]({ state, commit, getters, dispatch }, { scheduleIds, selected }) {
        if (getters[GETTER_DOCTORS_WORKING_CURRENT_PERIOD_TOGGLE]) {
            commit(MUTATION_SET_DOCTORS_WORKING_CURRENT_PERIOD, false)
            commit(MUTATION_UPDATE_DOCTORS, getters[GETTER_DOCTORS_WORKING_CURRENT_PERIOD])
            commit(MUTATION_SET_SCHEDULES, getters[GETTER_SCHEDULES_WORKING_CURRENT_PERIOD])
        }
        commit(MUTATION_SET_SELECTED_SPECIALITY_GROUP, null)
        commit(MUTATION_SET_CALENDAR_SELECTED_SCHEDULES, {
            scheduleIds,
            selected
        })

        if (selected && state.fetchSchedulesSelected) {
            commit(MUTATION_CLEAN_CALENDAR_EVENTS)
            dispatch(ACTION_GET_CALENDAR_EVENTS, getters[GETTER_SELECTED_DATE_RANGE_BY_PERIOD].from)
        }
    },
    [ACTION_SET_CALENDAR_SELECTED_SCHEDULES_FOR_FREE_SLOTS_VIEW]({ commit }, { scheduleIds, selected }) {
        commit(MUTATION_SET_CALENDAR_SELECTED_SCHEDULES, {
            scheduleIds,
            selected
        })
    },
    [ACTION_SET_FREE_SLOTS_FILTER_DAYS]({ commit }, days) {
        commit(MUTATION_SET_FREE_SLOTS_FILTER_DAYS, days)
    },
    [ACTION_SET_FREE_SLOTS_FILTER_DAYTIME]({ commit }, daytime) {
        commit(MUTATION_SET_FREE_SLOTS_FILTER_DAYTIME, daytime)
    },
    [ACTION_SET_FREE_SLOTS_FILTER_INSURANCE]({ commit }, insuranceType) {
        commit(MUTATION_SET_FREE_SLOTS_FILTER_INSURANCE, insuranceType)
    },
    [ACTION_SET_FREE_SLOTS_SELECTED_SERVICE]({ commit }, serviceId) {
        commit(MUTATION_SET_FREE_SLOTS_SELECTED_SERVICE, serviceId)
    },
    async [ACTION_SELECT_ALL_DOCTORS]({ state, commit, dispatch }, selected) {
        commit(MUTATION_SET_DOCTORS_WORKING_CURRENT_PERIOD, false)
        commit(MUTATION_SET_SELECTED_SPECIALITY_GROUP, null)
        commit(MUTATION_UPDATE_DOCTORS_SELECTED, {
            doctorIds: state.doctors.map(({ id }) => id),
            selected
        })
        const doctorSchedulesIds = state.doctors.reduce((acc, { userSchedules }) => {
            return [...acc, ...userSchedules.map(({ id }) => id)]
        }, [])

        dispatch(ACTION_SET_CALENDAR_SELECTED_SCHEDULES, {
            scheduleIds: doctorSchedulesIds,
            selected
        })
    },

    async [ACTION_SET_ALL_DOCTORS_FOR_FREE_SLOTS_VIEW]({ state, commit }) {
        commit(MUTATION_UPDATE_DOCTORS_SELECTED, {
            doctorIds: state.doctors.map(({ id }) => id),
            selected: true
        })
        const doctorSchedulesIds = state.doctors.reduce((acc, { userSchedules }) => {
            return [...acc, ...userSchedules.map(({ id }) => id)]
        }, [])

        commit(MUTATION_SET_CALENDAR_SELECTED_SCHEDULES, {
            scheduleIds: doctorSchedulesIds,
            selected: true
        })
    },

    async [ACTION_FETCH_FACILITIES]({ commit }) {
        const facilities = await FacilityService.getFacilities()
        commit(MUTATION_SET_FACILITIES, facilities)
    },
    async [ACTION_FETCH_FILTERS]({ commit, getters, dispatch }) {
        await dispatch(ACTION_FETCH_FACILITIES)

        const doctors = await DoctorService.getDoctors()
        commit(
            MUTATION_SET_DOCTORS,
            DoctorService.matchSelectedPropertyBetweenLists(doctors, getters[GETTER_SELECTED_DOCTORS])
        )

        const rooms = await RoomService.getRooms()

        commit(
            MUTATION_SET_CALENDAR_ROOMS,
            RoomService.matchSelectedPropertyBetweenLists(rooms, getters[GETTER_SELECTED_ROOMS])
        )
    },
    async [ACTION_SET_ONLY_DOCTORS_SELECTED]({ commit, state, dispatch }, { doctorIds }) {
        commit(MUTATION_SET_DOCTORS_WORKING_CURRENT_PERIOD, false)
        commit(MUTATION_SET_ONLY_DOCTORS_SELECTED, { doctorIds })

        const doctorSchedulesIds = state.doctors.reduce((acc, { userSchedules }) => {
            return [...acc, ...userSchedules.map(({ id }) => id)]
        }, [])

        const doctorSchedulesIdsToSelect = state.doctors
            .filter(({ id }) => doctorIds.includes(id))
            .reduce((acc, { userSchedules }) => {
                return [...acc, ...userSchedules.map(({ id }) => id)]
            }, [])

        dispatch(ACTION_SET_CALENDAR_SELECTED_SCHEDULES, {
            scheduleIds: xor(doctorSchedulesIds, doctorSchedulesIdsToSelect),
            selected: false
        })

        dispatch(ACTION_SET_CALENDAR_SELECTED_SCHEDULES, {
            scheduleIds: doctorSchedulesIdsToSelect,
            selected: true
        })
    },
    [ACTION_SELECT_FACILITY]({ commit, getters }, facility) {
        const roomIdsToUnselect = getters[GETTER_CALENDAR_ROOMS].reduce((acc, { facilityId, id }) => {
            return facilityId !== facility.id ? [...acc, id] : acc
        }, [])

        commit(MUTATION_SET_CALENDAR_SELECTED_ROOMS, {
            roomIds: roomIdsToUnselect,
            selected: false
        })

        commit(MUTATION_SET_SELECTED_FACILITY, facility)
        commit(MUTATION_SET_SELECTED_SPECIALITY, null)
    },
    [ACTION_SET_DOCTORS_WORKING_CURRENT_PERIOD]({ commit, dispatch, getters }, workingCurrentPeriod) {
        commit(MUTATION_SET_DOCTORS_WORKING_CURRENT_PERIOD, workingCurrentPeriod)
        commit(MUTATION_SET_SELECTED_SPECIALITY_GROUP, null)
        if (!workingCurrentPeriod) {
            dispatch(ACTION_SELECT_ALL_SCHEDULES, false)
        } else {
            commit(MUTATION_CLEAN_CALENDAR_EVENTS)
            dispatch(ACTION_GET_CALENDAR_EVENTS, getters[GETTER_SELECTED_DATE_RANGE_BY_PERIOD].from)
            dispatch(ACTION_SELECT_ALL_ROOMS, false)
        }
    },
    [ACTION_SELECT_ALL_SCHEDULES]: ({ state, commit, dispatch }, selected) => {
        const scheduleIds = state.schedules.map(({ id }) => id)

        dispatch(ACTION_SET_CALENDAR_SELECTED_SCHEDULES, {
            scheduleIds,
            selected
        })

        commit(MUTATION_SET_DOCTOR_SCHEDULE_SELECTED, {
            scheduleIds: state.doctorSchedules.map(({ id }) => id),
            selected
        })

        dispatch(ACTION_SELECT_ALL_DOCTORS, false)
    },

    [ACTION_SELECT_ALL_ROOMS]({ commit, getters }, selected) {
        const roomIds = getters[GETTER_CALENDAR_ROOMS].map(({ id }) => id)
        commit(MUTATION_SET_CALENDAR_SELECTED_ROOMS, {
            roomIds,
            selected
        })
    },
    [ACTION_SELECT_ALL_DEVICES]({ getters, dispatch }, selected) {
        const deviceIds = getters[GETTER_CALENDAR_DEVICES].map(({ id }) => id)
        dispatch(ACTION_SET_CALENDAR_SELECTED_SCHEDULES, {
            scheduleIds: deviceIds,
            selected
        })
    },
    [ACTION_SELECT_SPECIALITY]({ commit }, speciality) {
        commit(MUTATION_SET_SELECTED_SPECIALITY, speciality)
    },
    async [ACTION_FETCH_AGENDA_EVENTS]({ commit, getters, state }, showLoading) {
        const dateRange = getters[GETTER_AGENDA_RANGE]
        const colorSchemas = getters[GETTER_COLOR_SCHEMAS]
        const { schedules } = state

        if (showLoading) {
            commit(MUTATION_SET_CALENDAR_LOADING, true)
        }

        const agendaEvents = await CalendarService.getAgendaSlots(dateRange, colorSchemas, schedules)
        commit(MUTATION_SET_AGENDA_EVENTS, agendaEvents)

        if (showLoading) {
            commit(MUTATION_SET_CALENDAR_LOADING, false)
        }
    },
    async [ACTION_FETCH_CALENDAR_SCHEDULES]({ commit, getters, dispatch, rootGetters: { managesMedicalCenter } }) {
        const schedules = await CalendarService.getSchedules()

        commit(
            MUTATION_SET_CALENDAR_SCHEDULES,
            ScheduleService.matchSelectedPropertyBetweenLists(schedules, getters[GETTER_SELECTED_SCHEDULES])
        )

        if (!managesMedicalCenter) {
            dispatch(ACTION_SET_DOCTOR_SCHEDULES)
            commit(MUTATION_SET_DOCTORS_WORKING_CURRENT_PERIOD, false)
        }
    },
    async [ACTION_FETCH_CALENDAR_SCHEDULES_ORDER]({ commit, getters }) {
        if (getters[GETTER_CALENDAR_ORDER] === CalendarOrderType.Custom) {
            const schedulesOrder = await CalendarOrderColumnsService.getSchedulesOrder()
            commit(MUTATION_SET_CALENDAR_SCHEDULES_ORDER, schedulesOrder)
        }
    },
    async [ACTION_FETCH_CALENDAR_COLOR_SCHEMAS]({ commit, getters }) {
        const schemas = getters[GETTER_COLOR_SCHEMAS]
        if (!schemas) {
            const colors = await CalendarService.getColorsSchemas()
            commit(MUTATION_SET_CALENDAR_COLOR_SCHEMAS, colors)
            commit(MUTATION_SET_CALENDAR_COLOR_SCHEMAS_ARRAY, colors)
        }
    },
    [ACTION_SELECT_ALL_STAFF]({ dispatch, getters }, selected) {
        const staffIds = getters[GETTER_CALENDAR_STAFF].map(({ id }) => id)
        dispatch(ACTION_SET_CALENDAR_SELECTED_SCHEDULES, {
            scheduleIds: staffIds,
            selected
        })
    },
    [ACTION_SELECT_SCHEDULE]({ commit, dispatch }, { scheduleIds, selected }) {
        commit(MUTATION_SET_DOCTOR_SCHEDULE_SELECTED, {
            scheduleIds,
            selected
        })

        dispatch(ACTION_SET_CALENDAR_SELECTED_SCHEDULES, {
            scheduleIds,
            selected
        })
    },
    [ACTION_SET_DOCTOR_SCHEDULES]({ state, commit, rootGetters }) {
        const { isStoreCalendarFiltersEnabled } = rootGetters

        const shouldSetSelectedStateFromStorage =
            isStoreCalendarFiltersEnabled &&
            !!storage.get(STORE_CALENDAR_FILTERS_STORAGE_KEY)?.[MODULE_CALENDAR]?.schedules?.length

        const doctorSchedules = state.schedules.reduce((selectedDoctorSchedules, schedule) => {
            if (schedule.kind === CALENDAR_KIND.doctor) {
                selectedDoctorSchedules.push({
                    ...schedule,
                    selected: shouldSetSelectedStateFromStorage ? schedule.selected : true
                })
            }
            return selectedDoctorSchedules
        }, [])
        commit(MUTATION_SET_DOCTOR_SCHEDULES, doctorSchedules)
        commit(MUTATION_SET_CALENDAR_SELECTED_SCHEDULES, {
            scheduleIds: doctorSchedules.filter(({ selected }) => selected).map(({ id }) => id),
            selected: true
        })
    },
    async [ACTION_SET_CALENDAR_HIDE_BOOKED_SLOTS]({ commit }) {
        const hideBookedSlots = await CalendarSettingsDoctorService.getSetting(HIDE_BOOKED_SLOTS_KEY)
        commit(MUTATION_TOGGLE_CALENDAR_HIDE_BOOKED_SLOTS, hideBookedSlots)
    },

    ...calendarZoomActions
}
