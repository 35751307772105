import { ChatType, PatientChatCategory } from '../../../domain/enums/chat.enums'
import { PatientConversation, UnreadConversation } from '../../../domain/interfaces/conversations.interfaces'
import { QuickReply } from '../../../domain/interfaces/quickReplies.interfaces'

export enum ActionTypes {
    AcceptSaasInternalChatDisclaimer = 'ACCEPT_SAAS_INTERNAL_CHAT_DISCLAIMER',
    AddConversationToUnread = 'ADD_CONVERSATION_TO_UNREAD',
    ChatCreateProvider = 'CHAT_CREATE_PROVIDER',
    ChatMoveConversationToFolder = 'CHAT_MOVE_CONVERSATION_TO_FOLDER',
    FetchChatTemplates = 'FETCH_CHAT_TEMPLATES',
    FetchConversationByPatientRecipient = 'FETCH_CONVERSATION_BY_PATIENT_RECIPIENT',
    FetchConversationByPatientToken = 'FETCH_CONVERSATION_BY_PATIENT_TOKEN',
    FetchCurrentUserSessionToken = 'FETCH_CURRENT_USER_SESSION_TOKEN',
    FetchGlobalMessageBlockStatus = 'FETCH_GLOBAL_MESSAGE_BLOCK_STATUS',
    FetchIsContactFromProfileAllowed = 'FETCH_IS_CONTACT_FROM_PROFILE_ALLOWED',
    FetchPatientSessionToken = 'FETCH_PATIENT_SESSION_TOKEN',
    FetchUnreadPatientConversations = 'FETCH_UNREAD_PATIENT_CONVERSATIONS',
    FetchUnreadSaasInternalConversations = 'FETCH_UNREAD_SAAS_INTERNAL_CONVERSATIONS',
    FormatQuickReply = 'FORMAT_QUICK_REPLY',
    RemoveConversationsFromUnread = 'REMOVE_CONVERSATIONS_FROM_UNREAD',
    UpdateIsContactFromProfileAllowed = 'UPDATE_IS_CONTACT_FROM_PROFILE_ALLOWED',
    UpdateStatusGlobalMessageBlock = 'UPDATE_STATUS_GLOBAL_MESSAGE_BLOCK'
}

export type ChatActions = {
    [ActionTypes.AcceptSaasInternalChatDisclaimer]: () => void
    [ActionTypes.ChatCreateProvider]: (payload: {
        channelUrl: string
        generateThumbnail: boolean
        isPatientView: boolean
        recipientId: string
        sessionToken: string
        thumbnail: any
        updateReadReceipt: boolean
        userId: string
    }) => void
    [ActionTypes.ChatMoveConversationToFolder]: (payload: {
        category: PatientChatCategory
        hostId?: number
        id: number
        providerId: string
        resolvePatientRequests?: boolean
    }) => Promise<void>
    [ActionTypes.FetchConversationByPatientRecipient]: (payload: { id: number }) => Promise<PatientConversation>
    [ActionTypes.FetchConversationByPatientToken]: (payload: { token: string }) => Promise<PatientConversation>
    [ActionTypes.FetchCurrentUserSessionToken]: () => Promise<void>
    [ActionTypes.FetchGlobalMessageBlockStatus]: (payload: { docplannerDoctorId: string }) => Promise<void>
    [ActionTypes.FetchPatientSessionToken]: (payload: { token: string }) => Promise<void>
    [ActionTypes.FormatQuickReply]: (payload: QuickReply) => Promise<string>
    [ActionTypes.AddConversationToUnread]: (payload: {
        chatType: ChatType
        unreadConversation: UnreadConversation
    }) => Promise<void>
    [ActionTypes.RemoveConversationsFromUnread]: (payload: {
        chatType: ChatType
        providerConversationId: string
    }) => Promise<void>
    [ActionTypes.FetchUnreadPatientConversations]: (payload: {
        hostId?: number
        skipIfRecentlyTriggered?: boolean
    }) => Promise<void>
    [ActionTypes.FetchUnreadSaasInternalConversations]: () => Promise<void>
    [ActionTypes.FetchIsContactFromProfileAllowed]: () => Promise<void>
    [ActionTypes.UpdateIsContactFromProfileAllowed]: (payload: { isAllowed: boolean }) => Promise<void>
    [ActionTypes.FetchChatTemplates]: () => Promise<void>
    [ActionTypes.UpdateStatusGlobalMessageBlock]: (payload: {
        doctorId: string
        shouldActivateBlock: boolean
    }) => Promise<void>
}
