const state = {
    patient: null,
    services: [],
    snippets: [],
    templates: [],
    templateSmartProperties: [],
    episodeTypes: [],
    episode: null,
    isDoctorsCopilotTrainingUsageAccepted: false
}

export default state
