import { AuthMutations, AuthMutationTypes } from './types/mutations'

const mutations: AuthMutations = {
    [AuthMutationTypes.ResetAuth](state) {
        state.user = null
        state.status = ''
    },
    [AuthMutationTypes.SetAuthStatus](state, error) {
        state.status = error
    },
    [AuthMutationTypes.SetAuthToken](state) {
        state.status = 'success'
    },
    [AuthMutationTypes.SetIsFakeClinic](state, isFakeClinic) {
        state.user.isFakeClinic = isFakeClinic
    },
    [AuthMutationTypes.SetIsPublicPractitioner](state, isPublicPractitioner) {
        state.user.isPublicPractitioner = isPublicPractitioner
    },
    [AuthMutationTypes.SetSaasNavigation](state, data) {
        state.saasNavigation = { ...state.saasNavigation, ...data }
    },
    [AuthMutationTypes.SetUser](state, user) {
        state.status = 'success'
        state.user = user
    },
    [AuthMutationTypes.SetUserProfileCopyLinkUrl](state, url) {
        state.userProfileCopyLinkUrl = url
    },
    [AuthMutationTypes.SetUserProfileEmailUrl](state, url) {
        state.userProfileEmailUrl = url
    },
    [AuthMutationTypes.SetUserProfileWhatsappUrl](state, url) {
        state.userProfileWhatsappUrl = url
    },
    [AuthMutationTypes.SetUserSettingsByKey](state, { key, value }) {
        state.user.userSettings = { ...state.user.userSettings, [key]: value }
    },
    [AuthMutationTypes.SetUsersAccounters](state, accountUsers) {
        state.accountUsers = accountUsers
    }
}

export default mutations
