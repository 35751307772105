import { getUrlFromTemplate } from '@dp-vue/utils'
import { AxiosRequestConfig } from 'axios'

import httpAPI from '@/api/utils/httpClientAPI'
import {
    AiAssistantStatus,
    AiAssistantSummaryFeedback,
    AiAssistantTrainingConsent,
    Appointment,
    EpisodeType,
    EpisodeUploadPayload,
    ICD10Code,
    PmsCustomFields,
    PmsEvent,
    PmsField,
    PmsFileImageSizing,
    PmsHistory,
    PmsHistoryConfig,
    PmsSendEhrChatInput,
    PmsSendEhrInput,
    PmsSendFullEhrInput,
    Previsit,
    Snippet
} from '@/features/pms/domain/interfaces/ehr.interfaces'

import { ProgressCallback, ProgressData } from './interfaces/pms.interfaces'

const URL_BASE_HEALTH_RECORDS = 'healthrecords'
const URL_BASE_HEALTH_RECORD_TYPES = 'healthrecord-types'
const URL_BASE_PATIENTS = 'patients'
const URL_BASE_APPOINTMENTS = 'appointments'
const URL_BASE_SPECIALITIES = 'specialities'
const URL_BASE_MEDICAL_CODES = 'medicalcodes'
const URL_BASE_FILES = 'files'
const URL_BASE_PREVISIT = 'doctorcheckin'
const URL_BASE_EXAMINATION_CODES = 'examinationcodes'
const URL_BASE_DOCTORS_COPILOT = 'doctorscopilot'

const URL_CONFIGURATION_FIELDS = `${URL_BASE_HEALTH_RECORDS}/configuration/fields`
const URL_CONFIGURATION_ADMIN_FIELDS = `${URL_BASE_HEALTH_RECORDS}/configuration/fields/import`
const URL_PUBLISH_EPISODE = `${URL_BASE_HEALTH_RECORDS}/:id/publish`
const URL_SEND_ALL_EPISODE_HISTORY = `${URL_BASE_HEALTH_RECORDS}/history/send`
const URL_SEND_TO_PATIENT = `${URL_BASE_HEALTH_RECORDS}/history/send/:episodeId`
const URL_SEND_TO_PATIENT_THROUGH_CHAT = `${URL_BASE_HEALTH_RECORDS}/chat/send/:episodeId`
const URL_PRINT_ALL_EPISODE_HISTORY = `${URL_BASE_HEALTH_RECORDS}/history/:id`
const URL_PRINT_EPISODE = `${URL_BASE_HEALTH_RECORDS}/:episodeId/printhealthrecord`
const URL_GET_EPISODE_SNIPPET = `${URL_BASE_HEALTH_RECORDS}/configuration/snippets`
const URL_UPDATE_EPISODE_SNIPPET = `${URL_BASE_HEALTH_RECORDS}/configuration/snippets/:snippetId`
const URL_GET_HISTORY = `v2/patients/:id/${URL_BASE_HEALTH_RECORDS}`

const URL_HEALTH_RECORDS_V2 = `v2/${URL_BASE_HEALTH_RECORDS}`
const URL_HEALTH_RECORDS_V3 = `v3/${URL_BASE_HEALTH_RECORDS}`
const URL_UPDATE_EPISODE = URL_HEALTH_RECORDS_V3
const URL_GET_SECTIONS = `${URL_HEALTH_RECORDS_V2}/fields`
const URL_GET_EPISODE_HISTORY = `${URL_HEALTH_RECORDS_V2}/version`
const URL_CHECK_MISSING_FIELDS = `${URL_HEALTH_RECORDS_V2}/:id/can-save`
const URL_PUT_ACCEPT_FIELD_URL = `${URL_HEALTH_RECORDS_V2}/:id/accept-field-content`

const URL_UPLOAD_FILE = `${URL_BASE_PATIENTS}/:id/files`
const URL_DOWNLOAD_FILE = `${URL_BASE_PATIENTS}/:patientId/files/:fileId`
const URL_PREVIEW_FILE = `${URL_BASE_PATIENTS}/:patientId/files/:fileId/shortened`
const URL_RENAME_FILE = `${URL_BASE_PATIENTS}/:patientId/files/:fileId/name`
const URL_DELETE_FILE = `${URL_BASE_PATIENTS}/:patientId/files/:fileId`
const URL_HISTORY_FILES = `${URL_BASE_PATIENTS}/:patientId/files`
const URL_UPDATE_MEDICAL_INFO = `${URL_BASE_PATIENTS}/:patientId/medicalinfo`

const URL_APPOINTMENTS_ID = `${URL_BASE_APPOINTMENTS}/:id`

const URL_GET_DOCTOR_SPECIALITY = `${URL_BASE_SPECIALITIES}/getCurrentUserSpeciality`

const URL_GET_ICD_CODES = `${URL_BASE_MEDICAL_CODES}/ICD-10`

const URL_GET_FILES_FROM_QR = `${URL_BASE_FILES}/qr`
const URL_GET_FILES_FROM_QR_LINK = `${URL_BASE_FILES}/qrlink`

const URL_POST_RECORDER_TOKEN_URL = `${URL_BASE_DOCTORS_COPILOT}/recorder-token/:healthRecordId`
const URL_GET_AI_ASSISTANT_STATUS = `${URL_BASE_DOCTORS_COPILOT}/appointments/:episodeId`
const URL_TERMINATE_AI_ASSISTANT = `${URL_BASE_DOCTORS_COPILOT}/appointments/:episodeId/terminate`
const URL_AI_ASSISTANT_SUMMARY_RATING = `${URL_BASE_DOCTORS_COPILOT}/appointments/:episodeId/summary-rating`
const URL_AI_ASSISTANT_SEND_COPY_SUMMARY_EVENT = `${URL_BASE_DOCTORS_COPILOT}/appointments/:episodeId/copy-summary`
const URL_AI_ASSISTANT_SET_DR_TRAINING_USAGE_ACK = `${URL_BASE_DOCTORS_COPILOT}/training-usage-ack`
const URL_AI_ASSISTANT_TRAINING_CONSENT = `${URL_BASE_DOCTORS_COPILOT}/appointments/:episodeId/training-consent`

class Pms {
    async getPrevisit(id: number): Promise<Previsit> {
        const { data } = await httpAPI.get(`${URL_BASE_PREVISIT}/${id}`)
        return data
    }

    async getAppointment(id: number): Promise<Appointment> {
        // part of another feature, I will create sperate PR for creating new features
        const url = getUrlFromTemplate({
            template: URL_APPOINTMENTS_ID,
            params: {
                id: id.toString()
            }
        })
        const { data } = await httpAPI.get(url)
        return data
    }

    async getHistory(id: number, params: PmsHistoryConfig): Promise<PmsHistory> {
        const url = getUrlFromTemplate({
            template: URL_GET_HISTORY,
            params: {
                id: id.toString()
            }
        })
        const { data } = await httpAPI.get(url, { params })
        return data
    }

    async getPmsFields(): Promise<PmsCustomFields> {
        const { data } = await httpAPI.get(URL_CONFIGURATION_FIELDS)
        return data
    }

    async getAdminPmsFields(): Promise<PmsCustomFields> {
        const { data } = await httpAPI.get(URL_CONFIGURATION_ADMIN_FIELDS)
        return data
    }

    async getPmsPhonePictureQrCode(healthRecordId: number): Promise<string> {
        const url = getUrlFromTemplate({
            template: URL_GET_FILES_FROM_QR,
            query: { healthRecordId }
        })
        const { data } = await httpAPI.get(url)
        return data
    }

    async getPmsPhonePictureLink(healthRecordId: number): Promise<string> {
        const url = getUrlFromTemplate({
            template: URL_GET_FILES_FROM_QR_LINK,
            query: { healthRecordId }
        })
        const { data } = await httpAPI.get(url)
        return data.url
    }

    async updatePmsFields(fields: PmsField[]): Promise<number> {
        const { data } = await httpAPI.put(URL_CONFIGURATION_FIELDS, {
            customFields: fields
        })
        return data
    }

    async getEpisode(healthRecordId: number, patientId: number): Promise<PmsEvent> {
        const { data } = await httpAPI.get(URL_HEALTH_RECORDS_V2, {
            params: {
                healthRecordId,
                patientId
            }
        })
        return data
    }

    async getEpisodeTypes(): Promise<EpisodeType[]> {
        const { data } = await httpAPI.get(URL_BASE_HEALTH_RECORD_TYPES)

        return data
    }

    async getEpisodeHistory(versionId: string, patientId: number): Promise<PmsEvent> {
        const { data } = await httpAPI.get(URL_GET_EPISODE_HISTORY, {
            params: {
                versionId,
                patientId
            }
        })
        return data
    }

    updateEpisode(episode: any): Promise<EpisodeUploadPayload> {
        return httpAPI.put(URL_UPDATE_EPISODE, episode)
    }

    async createEpisode(episode: any): Promise<any> {
        const { data } = await httpAPI.post(URL_HEALTH_RECORDS_V2, episode)
        return data
    }

    async publishEpisode(id: number): Promise<any> {
        const url = getUrlFromTemplate({
            template: URL_PUBLISH_EPISODE,
            params: {
                id: id.toString()
            }
        })
        const { data } = await httpAPI.post(url)
        return data
    }

    async discardEpisode(id: number, patient: number): Promise<number> {
        const url = getUrlFromTemplate({
            template: URL_HEALTH_RECORDS_V2,
            query: { healthRecordId: id, patientId: patient }
        })
        const { data } = await httpAPI.delete(url)
        return data
    }

    async addFile(
        name: string,
        file: File,
        id: number,
        healthRecordId: number,
        process?: ProgressCallback
    ): Promise<any> {
        const url = getUrlFromTemplate({
            template: URL_UPLOAD_FILE,
            params: {
                id: id.toString()
            }
        })

        let config = {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Docplanner-File': `fileName=${name};source=ehr`
            }
        }

        const formData = new FormData()
        formData.append('file', file)
        formData.append('healthRecordId', healthRecordId.toString())

        if (process) {
            const onUploadProgress = {
                onUploadProgress: (progressEvent: any) => {
                    const obj: ProgressData = {
                        event: progressEvent,
                        file,
                        fileName: name
                    }

                    process(obj)
                }
            }
            config = { ...config, ...onUploadProgress }
        }

        const { data } = await httpAPI.post(url, formData, config)
        data.justUploaded = true
        data.blob = file
        return data
    }

    async getHistoryFiles(patientId: string) {
        const url = getUrlFromTemplate({
            template: URL_HISTORY_FILES,
            params: {
                patientId
            }
        })
        const { data } = await httpAPI.get(url)
        return data
    }

    // TODO
    async previewFile(patientId: string, fileId: string, imageSizing: PmsFileImageSizing, withDownload: boolean) {
        const { width, height } = imageSizing || {}
        const query = width && height ? { width, height } : undefined

        const rawUrl = withDownload ? URL_DOWNLOAD_FILE : URL_PREVIEW_FILE
        const url = getUrlFromTemplate({
            template: rawUrl,
            params: {
                patientId,
                fileId
            },
            query
        })
        const config: AxiosRequestConfig = { responseType: 'blob' as const }
        const { data } = await httpAPI.get(url, config)
        return data
    }

    downloadFile(patientId: string, fileId: string) {
        const url = getUrlFromTemplate({
            template: URL_DOWNLOAD_FILE,
            params: {
                patientId,
                fileId
            }
        })
        const config: AxiosRequestConfig = { responseType: 'blob' as const }
        return httpAPI.get(url, config)
    }

    async getEHRDoctorSpeciality() {
        const { data } = await httpAPI.get(URL_GET_DOCTOR_SPECIALITY)
        return data
    }

    async getIcdCodes(): Promise<ICD10Code[]> {
        const { data } = await httpAPI.get(URL_GET_ICD_CODES)
        return data
    }

    async getExaminationCodes(): Promise<ICD10Code[]> {
        const { data } = await httpAPI.get(URL_BASE_EXAMINATION_CODES)
        return data
    }

    deleteFile(id: number, patientId: number) {
        const url = getUrlFromTemplate({
            template: URL_DELETE_FILE,
            params: {
                patientId: patientId.toString(),
                fileId: id.toString()
            }
        })
        return httpAPI.delete(url)
    }

    async renameFile(patientId: string, fileId: string, name: string) {
        const url = getUrlFromTemplate({
            template: URL_RENAME_FILE,
            params: {
                patientId,
                fileId
            }
        })
        const { status } = await httpAPI.put(url, {
            name
        })
        return status
    }

    async sendEhrToPatient({
        episodeId,
        fields,
        files,
        email,
        phoneNumber,
        patientId,
        shouldSaveEmail = false,
        shouldSavePhone = false,
        printLayoutToken
    }: PmsSendEhrInput): Promise<any> {
        const url = getUrlFromTemplate({
            template: URL_SEND_TO_PATIENT,
            params: {
                episodeId
            }
        })

        const dataRequest: Omit<PmsSendEhrInput, 'episodeId'> = {
            fields,
            files,
            email,
            patientId,
            phoneNumber,
            shouldSaveEmail,
            shouldSavePhone,
            ...(printLayoutToken ? { printLayoutToken } : {})
        }

        const { status } = await httpAPI.post(url, dataRequest)

        return status
    }

    async updateMedicalBackground(patientId: number, medicalBackground: Record<string, any>): Promise<number> {
        const url = getUrlFromTemplate({
            template: URL_UPDATE_MEDICAL_INFO,
            params: {
                patientId: patientId.toString()
            }
        })
        const { status } = await httpAPI.put(url, medicalBackground)
        return status
    }

    async getSnippets(): Promise<Snippet[]> {
        const { data } = await httpAPI.get(URL_GET_EPISODE_SNIPPET)
        return data
    }

    async createSnippet(snippet: Snippet): Promise<number> {
        const { status } = await httpAPI.post(URL_GET_EPISODE_SNIPPET, snippet)
        return status
    }

    async deleteSnippet(id: number): Promise<number> {
        const url = getUrlFromTemplate({
            template: URL_UPDATE_EPISODE_SNIPPET,
            params: {
                snippetId: id.toString()
            }
        })
        const { status } = await httpAPI.delete(url)
        return status
    }

    async updateSnippet(snippet: Snippet, snippetId: number): Promise<number> {
        const url = getUrlFromTemplate({
            template: URL_UPDATE_EPISODE_SNIPPET,
            params: {
                snippetId: snippetId.toString()
            }
        })
        const { status } = await httpAPI.put(url, snippet)
        return status
    }

    async printEpisode(
        episodeId: number,
        patientId: number,
        fieldIds: number[],
        fileIds: number[],
        printLayoutToken?: string
    ): Promise<any> {
        const url = getUrlFromTemplate({
            template: URL_PRINT_EPISODE,
            params: {
                episodeId
            }
        })

        const config: AxiosRequestConfig = {
            responseType: 'blob' as const
        }

        const dataRequest = {
            PatientId: patientId,
            Fields: fieldIds,
            Files: fileIds,
            ...(printLayoutToken ? { printLayoutToken } : {})
        }

        const { data } = await httpAPI.post(url, dataRequest, config)

        return data
    }

    async printAllEpisodeHistory(patientId: number, attachPatientFiles: boolean, printLayoutToken: string) {
        const url = getUrlFromTemplate({
            template: URL_PRINT_ALL_EPISODE_HISTORY,
            params: {
                id: patientId.toString()
            }
        })
        const config: AxiosRequestConfig = {
            responseType: 'blob' as const
        }
        const { data } = await httpAPI.post(
            url,
            {
                attachPatientFiles,
                printLayoutToken
            },
            config
        )
        return data
    }

    async sendAllEpisodeHistory({
        patientId,
        email,
        phoneNumber,
        printLayoutToken,
        includeFiles,
        shouldSaveEmail,
        shouldSavePhone
    }: PmsSendFullEhrInput) {
        const { status } = await httpAPI.post(URL_SEND_ALL_EPISODE_HISTORY, {
            patientId,
            email,
            phoneNumber,
            printLayoutToken,
            includeFiles,
            shouldSaveEmail,
            shouldSavePhone
        })

        return status
    }

    async getSections(patientId: number, query?: string): Promise<any> {
        // TODO: define the interface
        const { data: sections } = await httpAPI.get(URL_GET_SECTIONS, {
            params: {
                patientId: patientId.toString(),
                search: query || ''
            }
        })

        return sections
    }

    async checkIfMissingPatientDataFields(healthRecordId: number): Promise<string[]> {
        const url = getUrlFromTemplate({
            template: URL_CHECK_MISSING_FIELDS,
            params: {
                id: healthRecordId.toString()
            }
        })
        const { data: fields } = await httpAPI.get(url)

        return fields
    }

    async getRecorderToken(healthRecordId: number): Promise<string[]> {
        const url = getUrlFromTemplate({
            template: URL_POST_RECORDER_TOKEN_URL,
            params: {
                healthRecordId: healthRecordId.toString()
            }
        })
        const { data } = await httpAPI.post(url)

        return data
    }

    async acceptFieldContent(healthRecordId: number, fieldId: number, accept: boolean): Promise<number> {
        const url = getUrlFromTemplate({
            template: URL_PUT_ACCEPT_FIELD_URL,
            params: {
                id: healthRecordId
            }
        })

        const { status } = await httpAPI.put(url, {
            fieldId,
            accept
        })

        return status
    }

    async getAiAssistantStatus(episodeId: number): Promise<AiAssistantStatus> {
        const url = getUrlFromTemplate({
            template: URL_GET_AI_ASSISTANT_STATUS,
            params: {
                episodeId: episodeId.toString()
            }
        })
        const { data } = await httpAPI.get(url)

        return data
    }

    async terminateAiAssistant(episodeId: number): Promise<void> {
        const url = getUrlFromTemplate({
            template: URL_TERMINATE_AI_ASSISTANT,
            params: {
                episodeId: episodeId.toString()
            }
        })
        await httpAPI.post<void>(url)
    }

    async sendEhrThroughChatToPatient({
        episodeId,
        fields,
        files,
        patientId,
        printLayoutToken
    }: PmsSendEhrChatInput): Promise<any> {
        const url = getUrlFromTemplate({
            template: URL_SEND_TO_PATIENT_THROUGH_CHAT,
            params: {
                episodeId
            }
        })

        const dataRequest: Omit<PmsSendEhrChatInput, 'episodeId'> = {
            fields,
            files,
            patientId,
            ...(printLayoutToken ? { printLayoutToken } : {})
        }

        const { status } = await httpAPI.post(url, dataRequest)

        return status
    }

    async getAiAssistantSummaryFeedback(episodeId: number): Promise<AiAssistantSummaryFeedback> {
        const url = getUrlFromTemplate({
            template: URL_AI_ASSISTANT_SUMMARY_RATING,
            params: {
                episodeId: episodeId.toString()
            }
        })
        const { data } = await httpAPI.get(url)

        return data
    }

    async sendAiAssistantSummaryFeedback(episodeId: number, data: AiAssistantSummaryFeedback): Promise<void> {
        const url = getUrlFromTemplate({
            template: URL_AI_ASSISTANT_SUMMARY_RATING,
            params: {
                episodeId: episodeId.toString()
            }
        })
        await httpAPI.post<void>(url, data)
    }

    async sendAiAssistantCopySummaryEvent(episodeId: number): Promise<void> {
        const url = getUrlFromTemplate({
            template: URL_AI_ASSISTANT_SEND_COPY_SUMMARY_EVENT,
            params: {
                episodeId: episodeId.toString()
            }
        })
        await httpAPI.post<void>(url)
    }

    async setAiAssistantDrTrainingUsageAck(): Promise<void> {
        const url = getUrlFromTemplate({
            template: URL_AI_ASSISTANT_SET_DR_TRAINING_USAGE_ACK
        })
        await httpAPI.post<void>(url)
    }

    async sendAiAssistantTrainingConsent(episodeId: number, data: AiAssistantTrainingConsent): Promise<void> {
        const url = getUrlFromTemplate({
            template: URL_AI_ASSISTANT_TRAINING_CONSENT,
            params: {
                episodeId: episodeId.toString()
            }
        })
        await httpAPI.post<void>(url, data)
    }
}
export default new Pms()
