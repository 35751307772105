export enum StartPaymentsHelperOrigin {
    Banner = 'banner'
}

export enum ClosePaymentsHelperOrigin {
    CloseModal = 'close_modal',
    CloseVideo = 'close_video',
    ConfirmCta = 'confirm_cta'
}

export enum PaymentsHelperFlowType {
    Modal = 'modal',
    Video = 'video'
}
