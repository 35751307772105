import { NavigationGuardNext, Route } from 'vue-router'

import { CountryCodes } from '@/core/domain/enums/country.code.enum'
import { apiMkplUrl } from '@/utils/url'

import { getCountryCode, getFreeUserRedirectTo, getIsDoctorChurned, getIsImpersonator } from '../../utils/vuex.utils'
import { getAnyBypassFeatureToggle } from './bypassFeatureToggle.utils'
import { isRouteAccessibleToFreeUsers } from './bypassRoutes'

const EXPORT_DATA_TOOL_ROUTE_NAME = 'settings-exportdata'

export const CHURNED_ORIGIN_KEY = 'churned'

const pushToExportDataPage = ({ next, to }: { next: NavigationGuardNext; to: Route }) => {
    next({ name: EXPORT_DATA_TOOL_ROUTE_NAME, query: { ...to.query, [CHURNED_ORIGIN_KEY]: 'true' } })
}

const redirectOutApp = () => {
    window.location.href = `${apiMkplUrl}/${getFreeUserRedirectTo()}`
}

export const tryRedirectFreeUser = ({
    isFreeUser,
    next,
    to
}: {
    isFreeUser: boolean
    next: NavigationGuardNext
    to: Route
}): boolean => {
    // Non-free users should never be redirected.
    if (!isFreeUser) return false

    // Impersonators should never be redirected.
    if (getIsImpersonator()) return false

    // Users with a bypass feature toggle should remain in-app.
    if (getAnyBypassFeatureToggle()) return false

    // Routes explicitly configured as accessible to free users
    if (isRouteAccessibleToFreeUsers(to.name!)) return false

    // Churned doctors (except when using the export data tool or in Italy) get redirected out-app.
    if (getIsDoctorChurned() && getCountryCode() !== CountryCodes.IT) {
        if (to.name === EXPORT_DATA_TOOL_ROUTE_NAME) {
            return false
        }

        pushToExportDataPage({ next, to })
        return true
    }

    redirectOutApp()
    return true
}
