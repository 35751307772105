const state = {
    status: '',
    user: null,
    userProfileCopyLinkUrl: '',
    userProfileEmailUrl: '',
    userProfileWhatsappUrl: '',
    accountUsers: [],
    saasOnly: {
        isActive: false,
        isFreemium: false
    },
    saasNavigation: {}
}

export default state
