import uninav from '@/features/navigation/ui/router/uninav.middleware'
import permissions from '@/features/payments/ui/router/middleware/permissions'

import {
    ROUTE_PAYMENTS_ACTIVATION_NAME,
    ROUTE_PAYMENTS_ACTIVATION_PATH,
    ROUTE_PAYMENTS_BASE_NAME,
    ROUTE_PAYMENTS_BASE_PATH,
    ROUTE_PAYMENTS_OVERVIEW_NAME,
    ROUTE_PAYMENTS_OVERVIEW_PATH,
    ROUTE_PAYMENTS_REQUESTS_NAME,
    ROUTE_PAYMENTS_REQUESTS_PATH,
    ROUTE_PAYMENTS_SCHEDULE_NAME,
    ROUTE_PAYMENTS_SETTINGS_NAME,
    ROUTE_PAYMENTS_SETTINGS_PATH,
    ROUTE_PAYMENTS_SETTINGS_SCHEDULES_PATH
} from '../../constants'
import { redirectionToLandingPage } from './middleware/redirectionToLandingPage.middleware'

const Payments = () => import(/* webpackChunkName: "payments-base" */ '../views/Payments.vue')
const PaymentsOverview = () => import(/* webpackChunkName: "payments-overview" */ '../views/PaymentsOverview.vue')
const PaymentsLanding = () => import(/* webpackChunkName: "payments-activation" */ '../views/PaymentsLanding.vue')
const PaymentsRequests = () => import(/* webpackChunkName: "payments-requests" */ '../views/PaymentsRequests.vue')
const PaymentsSettings = () => import(/* webpackChunkName: "payments-settings" */ '../views/PaymentsSettings.vue')
const PaymentsSettingsSchedule = () =>
    import(/* webpackChunkName: "payments-settings-schedule" */ '../views/PaymentsSettingsSchedule.vue')

export default {
    path: ROUTE_PAYMENTS_BASE_PATH,
    name: ROUTE_PAYMENTS_BASE_NAME,
    component: Payments,
    redirect: { name: ROUTE_PAYMENTS_OVERVIEW_NAME },
    meta: {
        middleware: [uninav, permissions],
        uninavName: 'payments'
    },
    children: [
        {
            path: ROUTE_PAYMENTS_OVERVIEW_PATH,
            name: ROUTE_PAYMENTS_OVERVIEW_NAME,
            component: PaymentsOverview,
            meta: {
                middleware: redirectionToLandingPage
            }
        },
        {
            path: ROUTE_PAYMENTS_ACTIVATION_PATH,
            name: ROUTE_PAYMENTS_ACTIVATION_NAME,
            component: PaymentsLanding,
            meta: {
                middleware: redirectionToLandingPage
            }
        },
        {
            path: ROUTE_PAYMENTS_REQUESTS_PATH,
            name: ROUTE_PAYMENTS_REQUESTS_NAME,
            component: PaymentsRequests,
            meta: {
                middleware: redirectionToLandingPage
            }
        },
        {
            path: ROUTE_PAYMENTS_SETTINGS_PATH,
            name: ROUTE_PAYMENTS_SETTINGS_NAME,
            component: PaymentsSettings,
            meta: {
                middleware: redirectionToLandingPage
            }
        },
        {
            path: ROUTE_PAYMENTS_SETTINGS_SCHEDULES_PATH,
            redirect: {
                name: ROUTE_PAYMENTS_SETTINGS_NAME
            }
        },
        {
            path: `${ROUTE_PAYMENTS_SETTINGS_SCHEDULES_PATH}/:scheduleId`,
            name: ROUTE_PAYMENTS_SCHEDULE_NAME,
            component: PaymentsSettingsSchedule,
            meta: {
                middleware: redirectionToLandingPage
            }
        }
    ]
}
