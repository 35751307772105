import { Context, User as DataDogUser } from '@datadog/browser-core'
import { datadogLogs, LogsEvent } from '@datadog/browser-logs'
import { datadogRum, RumEvent } from '@datadog/browser-rum'
import versions from '@public/config/versions.json'

import configManager from '@/bootstrap/configurationManager'
import { maskDynamicSegments, maskSensitiveInfo } from '@/core/ui/utils/dataMasking.utils'
import Storage from '@/store/storage'

import { botNavigatorRegex, excludedActivityUrls } from './datadog.constants'
import { DatadogCustomActionName, DatadogCustomMetricName, DatadogFakeCustomActionName } from './datadog.types'

type PropagatorType = 'tracecontext' | 'b3' | 'datadog' | 'b3multi'
type StartViewParams = Parameters<typeof datadogRum.startView>[0]

// NOTE: Do not increase this value without OK from Security team (due to privacy issues)
const DATADOG_MAX_SESSION_REPLAY_SAMPLE_RATE = 0

// NOTE: Do not extend these supported options without OK from Security team
enum SupportedPrivacyLevelSessionReplay {
    Mask = 'mask'
}

const dataDogEnabled = !!parseInt(configManager.getValue('DATADOG_ENABLED'), 10)

const getDataDogAllowedTracingUrls = () => {
    const allowedTracingUrls: { match: string; propagatorTypes: PropagatorType[] }[] = []

    const oneAppUrl = configManager.getValue('API_URL')
    if (oneAppUrl) {
        allowedTracingUrls.push({ match: oneAppUrl, propagatorTypes: ['tracecontext'] })
    }

    return allowedTracingUrls
}

const getSessionSampleRate = () => {
    if (Storage.get('UseDatadogFullSampling')) {
        return parseInt(Storage.get('UseDatadogFullSampling'), 10)
    }

    const isBotSession = botNavigatorRegex.test(navigator.userAgent)
    return isBotSession ? 0 : parseInt(configManager.getValue('DATADOG_SESSION_SAMPLE_RATE'), 10)
}

const getSessionReplaySampleRate = () =>
    Math.min(
        parseInt(configManager.getValue('DATADOG_SESSION_REPLAY_SAMPLE_RATE'), 10),
        DATADOG_MAX_SESSION_REPLAY_SAMPLE_RATE
    )

// TODO: refactor to integrate with monitor (WSTB-503)
if (dataDogEnabled) {
    const beforeSend = (event: RumEvent) => {
        if (event.view.url) {
            event.view.url = maskDynamicSegments(event.view.url)
        }

        if (event.view.referrer) {
            event.view.referrer = maskDynamicSegments(event.view.referrer)
        }

        if (event.type === 'error') {
            if (event.error.message) {
                event.error.message = maskDynamicSegments(event.error.message)
            }

            if (event.error.stack) {
                event.error.stack = maskSensitiveInfo(event.error.stack)
            }
        }

        if (event.type === 'resource') {
            if (event.resource.url) {
                event.resource.url = maskDynamicSegments(event.resource.url)
            }
        }

        return true
    }

    const beforeSendLogs = (event: LogsEvent): boolean => {
        if (event.view.url) {
            event.view.url = maskDynamicSegments(event.view.url)
        }

        if (event.view.referrer) {
            event.view.referrer = maskDynamicSegments(event.view.referrer)
        }

        return true
    }

    datadogRum.init({
        allowedTracingUrls: getDataDogAllowedTracingUrls(),
        excludedActivityUrls,
        applicationId: configManager.getValue('DATADOG_APPLICATION_ID'),
        clientToken: configManager.getValue('DATADOG_CLIENT_TOKEN'),
        site: configManager.getValue('DATADOG_SITE'),
        service: configManager.getValue('DATADOG_SERVICE'),
        env: configManager.getValue('DATADOG_ENV'),
        version: versions && versions.latest,
        sessionSampleRate: getSessionSampleRate(),
        sessionReplaySampleRate: getSessionReplaySampleRate(),
        trackUserInteractions: true,
        trackResources: true,
        trackLongTasks: true,
        trackViewsManually: true,
        defaultPrivacyLevel: SupportedPrivacyLevelSessionReplay.Mask,
        enablePrivacyForActionName: true,
        beforeSend
    })

    datadogLogs.init({
        clientToken: configManager.getValue('DATADOG_CLIENT_TOKEN'),
        site: configManager.getValue('DATADOG_SITE'),
        service: configManager.getValue('DATADOG_SERVICE'),
        env: configManager.getValue('DATADOG_ENV'),
        version: versions && versions.latest,
        forwardErrorsToLogs: false,
        sessionSampleRate: getSessionSampleRate(),
        telemetrySampleRate: 0,
        beforeSend: beforeSendLogs
    })
}

export const setDataDogUser = (user: DataDogUser) => {
    datadogRum.setUser(user)
    datadogLogs.setUser(user)
}

export const clearDataDogUser = () => {
    datadogRum.stopSession()
    datadogRum.clearUser()
    datadogLogs.clearUser()
}

export const addDataDogAction = (
    name: DatadogCustomActionName | DatadogFakeCustomActionName,
    context?: object | undefined
) => {
    datadogRum.addAction(name, context)
}

export const addDataDogTiming = (name: DatadogCustomMetricName) => {
    datadogRum.addTiming(name)
}

export const dataDogStartView = (options: StartViewParams) => {
    datadogRum.startView(options)
}

export const addDataDogLogInfo = (message: string, context?: Context) => {
    datadogLogs.logger.info(message, context)
}
