import { getUrlFromTemplate } from '@dp-vue/utils'

import httpAPI, { post } from '@/api/utils/httpClientAPI'

import { PAGINATION_DEFAULT } from '../../constants/table.constants'
import {
    PaymentRequestsCreatePayload,
    PaymentRequestsSendNotification,
    PaymentsSchedule,
    PaymentsScheduleSettings
} from '../../domain/interfaces'
import {
    PaymentDTO,
    PaymentInstructionsDTO,
    PaymentsProfileDTO,
    PaymentsRequestDTO,
    PaymentsSettingsDTO
} from '../interfaces'

const URL_BASE = 'payments'

const URL_PAYMENTS_PROFILE = `${URL_BASE}/profile`
const URL_PAYMENTS_SCHEDULES = `${URL_BASE}/schedules`
const URL_PAYMENTS_SCHEDULES_SCHEDULE_ID = `${URL_PAYMENTS_SCHEDULES}/:scheduleId`
const URL_PAYMENTS_SCHEDULE_ID = `${URL_BASE}/:scheduleId`
const URL_PAYMENTS_SCHEDULE_SETTINGS = `${URL_PAYMENTS_SCHEDULES_SCHEDULE_ID}/settings`
const URL_PAYMENT_INSTRUCTIONS = `${URL_PAYMENTS_SCHEDULE_ID}/instructions`
const URL_ACTIVATE_SCHEDULE = `${URL_BASE}/activate-schedule/:scheduleId`
const URL_DEACTIVATE_SCHEDULE = `${URL_BASE}/deactivate-schedule/:scheduleId`
const URL_REMINDER = `${URL_BASE}/events/:appointmentId/reminder`
const URL_ACTIVATE_FEATURE = `${URL_BASE}/activate-feature`
const URL_SEND_NOTIFICATION = `${URL_BASE}/send-notification`

class PaymentsRepository {
    async getPayments(pageNumber: number, pageSize = PAGINATION_DEFAULT.pageSize): Promise<PaymentDTO> {
        const { data } = await httpAPI.get(URL_BASE, { params: { pageNumber, pageSize } })
        return data
    }

    async getPaymentsProfile({ cache } = { cache: true }): Promise<PaymentsProfileDTO> {
        const { data } = await httpAPI.get(`${URL_PAYMENTS_PROFILE}${!cache ? '?invalidateCache=true' : ''}`)
        return data
    }

    async getSchedules(): Promise<PaymentsSchedule[]> {
        const { data } = await httpAPI.get(URL_PAYMENTS_SCHEDULES)
        return data
    }

    async activateFeature(): Promise<void> {
        await httpAPI.post(URL_ACTIVATE_FEATURE)
    }

    async getPaymentInstructions(scheduleId: number): Promise<PaymentInstructionsDTO> {
        const url = getUrlFromTemplate({
            template: URL_PAYMENT_INSTRUCTIONS,
            params: {
                scheduleId: scheduleId.toString()
            }
        })
        const { data } = await httpAPI.get(url)
        return data
    }

    async setPaymentInstructions(scheduleId: number, data: PaymentInstructionsDTO): Promise<void> {
        const url = getUrlFromTemplate({
            template: URL_PAYMENT_INSTRUCTIONS,
            params: {
                scheduleId: scheduleId.toString()
            }
        })
        await httpAPI.put(url, data)
    }

    async sendPaymentLinkToPatient(appointmentId: string) {
        const url = getUrlFromTemplate({
            template: URL_REMINDER,
            params: {
                appointmentId
            }
        })

        return post<void, null>(url)
    }

    async activateSchedule(scheduleId: number): Promise<boolean> {
        const url = getUrlFromTemplate({
            template: URL_ACTIVATE_SCHEDULE,
            params: {
                scheduleId: scheduleId.toString()
            }
        })
        const { data } = await httpAPI.post(url)

        return data
    }

    async deactivateSchedule(scheduleId: number): Promise<void> {
        const url = getUrlFromTemplate({
            template: URL_DEACTIVATE_SCHEDULE,
            params: {
                scheduleId: scheduleId.toString()
            }
        })
        await httpAPI.post(url)
    }

    async createPaymentLink(payload: PaymentRequestsCreatePayload): Promise<PaymentsRequestDTO> {
        const { data } = await httpAPI.post(URL_BASE, payload)
        return data
    }

    async sendPaymentLinkNotification(payload: PaymentRequestsSendNotification): Promise<void> {
        const { data } = await httpAPI.post(URL_SEND_NOTIFICATION, payload)
        return data
    }

    async setScheduleSettings(scheduleId: number, payload: PaymentsScheduleSettings) {
        const url = getUrlFromTemplate({
            template: URL_PAYMENTS_SCHEDULE_SETTINGS,
            params: {
                scheduleId: scheduleId.toString()
            }
        })

        return post<PaymentsSettingsDTO, PaymentsScheduleSettings>(url, payload)
    }
}

export default new PaymentsRepository()
