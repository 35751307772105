import Cookies from 'js-cookie'

import { addDataDogLogInfo } from '@/bootstrap/monitor/datadog-poc/datadog'
import { refreshSSOToken } from '@/core/api/utils/refreshToken.util'
import { ROUTER_NAVIGATION_LOGOUT } from '@/core/ui/constants/event.bus.constants'
import EventBus from '@/core/ui/libs/eventBus'
import { MKPL_COOKIE } from '@/features/auth/domain/constants/auth.constants'
import Storage from '@/store/storage'
import { LAST_SUCCESSFUL_LOGIN_DATE_TIME } from '@/store/storage-types'

class SSOCookieService {
    static INTERVAL_DURATION = 1000 * 60 * 1

    private checkCookieInterval: ReturnType<typeof setInterval> | null = null

    private cookieName: string = MKPL_COOKIE

    private renewingProcess: Promise<void> | undefined = undefined

    private hasInitialized = false

    public initialize() {
        if (this.hasInitialized) {
            return
        }

        this.hasInitialized = true
        this.startExpirationInterval()
    }

    startExpirationInterval() {
        if (!this.hasInitialized) {
            return
        }

        if (this.checkCookieInterval) {
            clearInterval(this.checkCookieInterval)
        }

        const interval = () => {
            const cookie = Cookies.get(this.cookieName)

            if (!cookie) {
                clearInterval(this.checkCookieInterval!)
                this.renewToken()
            }
        }

        this.checkCookieInterval = setInterval(interval, SSOCookieService.INTERVAL_DURATION)
    }

    renewToken() {
        if (this.renewingProcess) {
            return
        }

        this.renewingProcess = new Promise((resolve, reject) => {
            refreshSSOToken()
                .then(() => {
                    this.startExpirationInterval()
                    resolve()
                })
                .catch(error => {
                    addDataDogLogInfo('SSO_renew_token_failed', {
                        lastSuccessfulLoginDateTime: Storage.get(LAST_SUCCESSFUL_LOGIN_DATE_TIME)
                    })

                    EventBus.emit(ROUTER_NAVIGATION_LOGOUT)
                    reject(error)
                })
                .finally(() => {
                    this.renewingProcess = undefined
                })
        })
    }

    public async getNewToken() {
        if (!this.hasInitialized) {
            return new Promise<void>((resolve, reject) => {
                reject(new Error('SSOCookieService have not been initialized'))
            })
        }

        if (!this.renewingProcess) {
            this.renewToken()
        }

        await this.renewingProcess
    }
}

export default new SSOCookieService()
