import configManager from '@/bootstrap/configurationManager'

const DEVELOPMENT = 'development'
const PRODUCTION = 'production'
const TST = 'tst'

const { hostname } = location
// NOTE: hostname.includes('docplanner-front-test') is a temporary condition until OAP-1611 will be done
// NOTE: host.includes('zltapp') is a temporary condition until PR 70 will be merged
const assimilableToTSTDomainParts = ['docplanner-front-test', 'zltapp', 'tst.docplanner.com']
const isAssimilableToTST = assimilableToTSTDomainParts.some(part => hostname.includes(part))

const isLocalEnvironmentDomain = hostname.indexOf(configManager.getValue('LOCAL_DOMAIN')) !== -1
const isTstEnvironmentDomain =
    hostname.indexOf(configManager.getValue('TST_ENV_DEFINITION')) !== -1 || isAssimilableToTST

let environment = DEVELOPMENT

if (process.env.NODE_ENV === PRODUCTION && !isLocalEnvironmentDomain) {
    environment = isTstEnvironmentDomain ? TST : PRODUCTION
}

const isDevelopment = environment === DEVELOPMENT
const isTst = environment === TST

const isTstOrDevelopment = isTst || isDevelopment

const getTstName = () => {
    const [tstName] = window.location.host.split('-') || []

    return tstName
}
const getOnDemandTestingName = () => {
    return configManager.getValue('TESTING_NAME')
}

const isE2EEnvironment = hostname.includes('docplanner-front-test')

/** @type {string} */
let remotesEnvironment
if (isE2EEnvironment) {
    remotesEnvironment = 'production'
} else if (environment === 'tst') {
    remotesEnvironment = 'testing'
} else {
    remotesEnvironment = environment
}

export {
    DEVELOPMENT,
    environment,
    getOnDemandTestingName,
    getTstName,
    isDevelopment,
    isTst,
    isTstOrDevelopment,
    PRODUCTION,
    remotesEnvironment,
    TST
}
