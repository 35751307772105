import { getUrlFromTemplate } from '@dp-vue/utils'

import httpAPI from '@/api/utils/httpClientAPI'
import { Device } from '@/features/devices/domain/interfaces/devices.interface'
import { Staff } from '@/features/staff/domain/interfaces/staff.interface'
import { parseParams } from '@/utils/parseParams'

import {
    CreateSchedule,
    ScheduleOverlapDTO,
    ScheduleOverlapQueryParams
} from '../domain/interfaces/schedule.interfaces'

const URL_BASE = 'schedules'
const URL_CREATE_CALENDAR_DEVICE = `${URL_BASE}/device`
const URL_CREATE_CALENDAR_STAFF = `${URL_BASE}/staff`
const URL_BASE_V2 = 'v2/schedules'
const URL_CREATE_CALENDAR_DEVICE_V2 = `${URL_BASE_V2}/device`
const URL_CREATE_CALENDAR_STAFF_V2 = `${URL_BASE_V2}/staff`
const URL_REMOVE_HOLIDAY = `${URL_BASE}/:scheduleId/holidays/:holidayId/:year`
const URL_REMOVE_HOLIDAY_EVENT = `${URL_BASE}/:scheduleId/holidays/:holidayId`

const URL_SCHEDULE_OVERLAP = 'schedules-overlaps'

class ScheduleRepository {
    async createDeviceCalendar(params: CreateSchedule): Promise<Device> {
        const { data } = await httpAPI.post(URL_CREATE_CALENDAR_DEVICE, params)

        return data
    }

    async createStaffCalendar(params: CreateSchedule): Promise<Staff> {
        const { data } = await httpAPI.post(URL_CREATE_CALENDAR_STAFF, params)

        return data
    }

    async createDeviceCalendarV2(params: CreateSchedule): Promise<{ id: number }> {
        const { data } = await httpAPI.post(URL_CREATE_CALENDAR_DEVICE_V2, params)

        return { id: data }
    }

    async createStaffCalendarV2(params: CreateSchedule): Promise<{ id: number }> {
        const { data } = await httpAPI.post(URL_CREATE_CALENDAR_STAFF_V2, params)

        return { id: data }
    }

    async removeExternalHoliday(scheduleId: number, externalId: string) {
        const url = getUrlFromTemplate({
            template: URL_REMOVE_HOLIDAY_EVENT,
            params: {
                scheduleId,
                holidayId: externalId
            }
        })

        await httpAPI.delete(url)
    }

    async removeHoliday(scheduleId: number, holidayId: number | string, year: number): Promise<void> {
        const url = getUrlFromTemplate({
            template: URL_REMOVE_HOLIDAY,
            params: {
                scheduleId,
                holidayId,
                year
            }
        })

        await httpAPI.delete(url)
    }

    async getSchedulesOverlap(queryParams: ScheduleOverlapQueryParams): Promise<ScheduleOverlapDTO> {
        const url = getUrlFromTemplate({
            template: URL_SCHEDULE_OVERLAP,
            query: parseParams(queryParams)
        })
        const { data } = await httpAPI.get(url)

        return data
    }
}

export default new ScheduleRepository()
