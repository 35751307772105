import { getUrlFromTemplate } from '@dp-vue/utils'

import httpAPI from '@/api/utils/httpClientAPI'
import httpClientMkplAPI from '@/api/utils/httpClientMkplAPI'

import {
    DoctorUrlDTO,
    HostUrlDTO,
    PhotoDTO,
    PhotoMkplDTO,
    SaasOnlyDTO,
    UserIdDTO,
    UserIdMkplDTO,
    UtmDTO
} from './interfaces/profile.DTO'

const URL_BASE = 'profile'
const URL_PHOTO = `${URL_BASE}/photo/:userId`
const URL_DOCTOR = `${URL_BASE}/url/:userId`
const URL_HOST = `${URL_BASE}/host-user-profile-url`
const URL_PHOTO_MKPL = `/patient-profiles/:patientId`
const URL_OPINION_URL = `${URL_BASE}/opinion-url`

class ProfileRepository {
    async photo(userId: UserIdDTO, smallVersion = false): Promise<PhotoDTO> {
        const url = getUrlFromTemplate({
            template: URL_PHOTO,
            params: { userId }
        })
        const { data } = await httpAPI.get(url, {
            params: { smallVersion }
        })
        return data
    }

    async doctorUrl(userId: UserIdDTO, utm: UtmDTO): Promise<DoctorUrlDTO> {
        let params = {}

        const url = getUrlFromTemplate({
            template: URL_DOCTOR,
            params: { userId }
        })

        if (utm) {
            params = {
                ...utm
            }
        }

        const { data } = await httpAPI.get(url, { params })

        return data
    }

    async hostUrl(utm: UtmDTO): Promise<HostUrlDTO> {
        let params = {}

        if (utm) {
            params = {
                ...utm
            }
        }
        const { data } = await httpAPI.get(URL_HOST, { params })

        return data
    }

    async getProfileMKPL(patientId: UserIdMkplDTO): Promise<PhotoMkplDTO> {
        const url = getUrlFromTemplate({
            template: URL_PHOTO_MKPL,
            params: { patientId }
        })

        const { data } = await httpClientMkplAPI.get(url)
        return data
    }

    async getOpinionURL(): Promise<string> {
        const {
            data: { profileUrl }
        } = await httpAPI.get(URL_OPINION_URL)
        return profileUrl
    }
}

export default new ProfileRepository()
