import { Route } from 'vue-router'

export const REPLACEMENT = 'xxxx'

const ID_MATCHER = /(?<=\/)(\d+)(?=\/|$)/g
const DATE_MATCHER = /\d{1,2}-\d{1,2}-\d{2,4}/
const GUID_MATCHER = /[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}/
const EMAIL_MATCHER = /[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}/gi
const QUERY_PARAMETER_MATCHER = /(\bhttps?:\/\/[^?#]+|\/[^?#]+)(\?[^#]*)/g
const LONG_NUMBER_MATCHER = /\b\d{10,}\b/g

export const maskString = (value: string, prefixSize = 3, suffixSize = 0): string => {
    if (!value?.length) {
        return value
    }

    let result = ''

    if (prefixSize > 0 && value.length >= (prefixSize + suffixSize) * 2) {
        result += value.substring(0, prefixSize)
    }

    result += REPLACEMENT

    if (suffixSize > 0 && value.length >= (prefixSize + suffixSize) * 2) {
        result += value.substring(value.length - suffixSize)
    }

    return result
}

export const maskSentence = (value: string): string => {
    return value
        .split(' ')
        .map(str => maskString(str))
        .join(' ')
}

export const maskPhone = (value: string): string => maskString(value, 0, 3)

export const maskEmail = (value: string): string => {
    if (!value?.length) {
        return value
    }

    if (value.indexOf('@') === -1) {
        return maskSentence(value)
    }

    const unmaskedPrefixLength = 3
    const [username, domain] = value.split('@')

    if (username.length <= unmaskedPrefixLength) {
        return `${username}${REPLACEMENT}@${domain}`
    }

    return `${maskString(username, unmaskedPrefixLength)}@${domain}`
}

export const maskSensitiveInfo = (msg: string | undefined): string => {
    if (!msg) return ''

    try {
        return msg?.replace(QUERY_PARAMETER_MATCHER, '$1?[MASKED]').replace(EMAIL_MATCHER, '[MASKED EMAIL]')
    } catch (error) {
        return `Error masking sensitive info: ${error}`
    }
}

export const maskDynamicSegments = (path: string): string => {
    if (!path) return ''

    try {
        return path
            ?.replace(QUERY_PARAMETER_MATCHER, '$1?[MASKED]')
            .replace(ID_MATCHER, '[MASKED ID]')
            .replace(DATE_MATCHER, '[MASKED DATE]')
            .replace(GUID_MATCHER, '[MASKED GUID]')
            .replace(EMAIL_MATCHER, '[MASKED EMAIL]')
    } catch (error) {
        return `Error masking sensitive info: ${error}`
    }
}
