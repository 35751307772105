import { SettingsTabIds } from '../enums'
import { SettingsTab } from '../interfaces'

export const SETTINGS_TABS: SettingsTab[] = [
    {
        label: 'payments-section-settings-tab-1',
        value: SettingsTabIds.Schedules,
        iconName: 'calendar',
        isActive: true
    },
    {
        label: 'payments-section-settings-tab-2',
        value: SettingsTabIds.ActiveSchedules,
        iconName: 'docplanner'
    }
]
