import { middleware as featureToggleMiddleware } from '@/core/ui/plugins/featureToggle/featureToggle.middleware'

import { LEGACY_SETTINGS_SERVICES_ROUTES } from '../../dependencies'
import { SETTINGS_SERVICES_CONFIGURATION_ROUTES } from './routes.constants'

const Index = () => import(/* webpackChunkName: "services-configuration-index" */ '../views/Index.vue')
const Services = () => import(/* webpackChunkName: "services-configuration-list" */ '../views/Services.vue')
const ServiceDetails = () => import(/* webpackChunkName: "service-details" */ '../views/ServiceDetails.vue')

export default [
    {
        path: 'settings/services',
        component: Index,
        meta: {
            middleware: featureToggleMiddleware({
                key: 'ServiceConfiguration',
                value: 'true',
                fallbackRouteName: LEGACY_SETTINGS_SERVICES_ROUTES.LIST
            })
        },
        children: [
            {
                path: '',
                name: SETTINGS_SERVICES_CONFIGURATION_ROUTES.LIST,
                component: Services
            },
            {
                path: ':activeWorkplaceId(\\d+)/:serviceId',
                name: SETTINGS_SERVICES_CONFIGURATION_ROUTES.DETAIL,
                component: ServiceDetails
            },
            {
                path: ':activeWorkplaceId(\\d+)/copy/:serviceId',
                name: SETTINGS_SERVICES_CONFIGURATION_ROUTES.COPY,
                component: ServiceDetails
            },
            {
                path: 'add/:activeWorkplaceId(\\d+)',
                name: SETTINGS_SERVICES_CONFIGURATION_ROUTES.ADD,
                component: ServiceDetails
            }
        ]
    }
]
