import httpAPI from '@/api/utils/httpClientAPI'

import { PaymentsProfileDTO } from '../interfaces'

const URL_BASE = 'payments'
const URL_PAYMENTS_PROFILE = `${URL_BASE}/profile`

class PaymentsProfileRepository {
    async getPaymentsProfile({ cache } = { cache: true }): Promise<PaymentsProfileDTO> {
        const { data } = await httpAPI.get(`${URL_PAYMENTS_PROFILE}${!cache ? '?invalidateCache=true' : ''}`)
        return data
    }

    acceptSalesFlow(): Promise<void> {
        return httpAPI.post(`${URL_BASE}/accept-sales-flow`)
    }
}

export default new PaymentsProfileRepository()
