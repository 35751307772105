export enum RequestType {
    Prescriptions = 1,
    RefillPrescription = 2,
    Certificate = 3,
    Uncategorized = 4,
    // Booking = 5, Only use it for booking request feature
    TestResults = 6,
    Referrals = 7
}

export enum RequestStatus {
    Pending = 1,
    Done = 2
}

export enum RequestFilterStatus {
    Pending = 'false',
    Resolved = 'true'
}

export enum PatientType {
    Existing = 0,
    NewAndExisting = 1
}
