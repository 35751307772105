import monitor from '@/bootstrap/monitor/monitor'
import { SeverityLevels } from '@/bootstrap/monitor/monitor.constants'
import { SET_COUNTRY_SETTINGS } from '@/store/mutation-types'

export default async function countrySettings({ router, store, next, to, from }) {
    const { culture } = store.getters

    if (
        !(store.state.authProvider && culture && store.getters.countrySettings) ||
        culture !== store.getters.countrySettings.id
    ) {
        let country = null

        try {
            const { data } = await store.dispatch('FETCH_COUNTRY_SETTINGS', store.getters.culture)
            country = data
        } catch (error) {
            const isStatus0 = error.request.status === 0
            const prefix = isStatus0 ? '[Status 0] ' : ''

            monitor.send(`${prefix}Settings for a culture can't be found. Default settings will be used instead.`, {
                level: SeverityLevels.Info,
                extra: {
                    culture: store.getters.culture,
                    status: error.request.status,
                    readyState: error.request.readyState
                }
            })
        }

        store.commit(SET_COUNTRY_SETTINGS, country)
    }

    next()
}
