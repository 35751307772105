import * as amplitude from '@amplitude/analytics-browser' // Tested with version 1.7.1

import { CustomDestination } from '../Avo'

const amplitudeDestinationInterface: CustomDestination = {
    make: (_env, apiKey) =>
        amplitude.init(apiKey, {
            serverZone: 'EU',
            autocapture: {
                attribution: false,
                pageViews: false,
                sessions: false,
                formInteractions: false,
                fileDownloads: false,
                elementInteractions: false
            },
            trackingOptions: {
                ipAddress: false
            }
        }),

    identify: userId => {
        amplitude.setUserId(userId)
        amplitude.setOptOut(false)
    },

    logEvent: (eventName, eventProperties) => amplitude.track(eventName, eventProperties),

    logPage: pageUrl => {
        amplitude.track('PageView', { 'Page URL': pageUrl })
    },

    setUserProperties: (_userId, userProperties) => {
        const identify = new amplitude.Identify()
        Object.entries(userProperties).forEach(([key, value]) => identify.set(key, value))
        amplitude.identify(identify)
    },

    unidentify: () => {
        amplitude.setOptOut(true)
    },

    revenue: (amount, eventProperties) => {
        const event = new amplitude.Revenue().setPrice(amount)
        if (eventProperties.quantity) {
            event.setQuantity(eventProperties.quantity)
        }

        if (eventProperties.productId) {
            event.setProductId(eventProperties.productId)
        }

        if (eventProperties.revenueType) {
            event.setRevenueType(eventProperties.revenueType)
        }

        if (Object.keys(eventProperties).length > 0) {
            event.setEventProperties(eventProperties)
        }
        amplitude.revenue(event)
    },

    setGroupProperties: (groupTypeName, groupId, groupProperties) => {
        const group = new amplitude.Identify()
        Object.entries(groupProperties).forEach(([key, value]) => group.set(key, value))
        amplitude.groupIdentify(groupTypeName, groupId, group)
    },

    addCurrentUserToGroup: (groupTypeName, groupId) => amplitude.setGroup(groupTypeName, groupId),

    logEventWithGroups: (eventName, eventProperties, groupTypeNamesToGroupIds) =>
        amplitude.track({
            event_type: eventName,
            event_properties: eventProperties,
            groups: groupTypeNamesToGroupIds
        })
}

export default amplitudeDestinationInterface
