import { Feature } from '@patientus/video-lib-wc'

export const ASSETS_PATH = '/assets'
export const FEATURE_CONFIG: Feature = {
    chat: 'custom',
    fileSharing: false,
    whiteboard: {
        enabled: true
    }
}
