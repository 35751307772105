import { Canceler } from 'axios'

import httpAPI from '@/api/utils/httpClientAPI'
import { PhoneNumberDTO } from '@/core/api/interfaces/phoneNumber.DTO'

const URL_BASE = 'phoneNumber'

class PhoneNumber {
    cancelGetData = () => {}

    async validatePhoneCancelable(phone: string): Promise<PhoneNumberDTO> {
        this.cancelGetData()
        const { data } = await httpAPI.post(
            URL_BASE,
            { phone },
            {
                cancelToken: httpAPI.createCancelToken((cancelExecutor: Canceler) => {
                    this.cancelGetData = cancelExecutor
                })
            }
        )
        return data
    }

    async validatePhone(phone: string): Promise<PhoneNumberDTO> {
        const { data } = await httpAPI.post(URL_BASE, { phone })
        return data
    }
}

export default new PhoneNumber()
