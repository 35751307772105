import Config from '@/api/Config'
import Culture from '@/api/Culture'
import AuthService from '@/features/auth/domain/auth.service'

const ConfigApi = Config()
const CultureApi = Culture()

export async function fetchInitializationConfig() {
    const results = await Promise.allSettled([
        CultureApi.fetch(),
        AuthService.getProvider(),
        ConfigApi.fetchCountrySettings()
    ])

    const initializationError = results.find(result => result.status === 'rejected') as
        | PromiseRejectedResult
        | undefined

    if (initializationError) {
        throw initializationError.reason
    }

    return {
        culture: (results[0] as PromiseFulfilledResult<any>).value.data,
        provider: (results[1] as PromiseFulfilledResult<any>).value,
        countrySettings: (results[2] as PromiseFulfilledResult<any>).value.data
    }
}
