export enum PFTourState {
    Finished = 'finished',
    NotFinished = 'not_finished',
    Skipped = 'skipped'
}

export enum PFTourEvents {
    TourFinished = 'tour-finished',
    TourSkipped = 'tour-skipped'
}

export enum PFChecklistDismissReason {
    Completed = 'completed',
    NotCompleted = 'not-completed'
}

export enum PFChecklistEvents {
    Dismissed = 'dismissed'
}
