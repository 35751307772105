import { CountryCodes } from '@/core/domain/enums/country.code.enum'
import { AuthGetterTypes } from '@/features/auth/ui/store/types'
import store from '@/store'

export const getIsDoctorChurned = () => {
    const { isDoctorChurned } = store.getters[AuthGetterTypes.GetAuthUser]
    return isDoctorChurned
}

export const getFreeUserRedirectTo = () => {
    return store.getters.countrySettings && store.getters.countrySettings.freeUserRedirectTo
}

export const getCountryCode = (): CountryCodes => {
    return store.getters[AuthGetterTypes.GetCountryCode]
}

export const getIsImpersonator = () => {
    return store.getters[AuthGetterTypes.IsImpersonator]
}
