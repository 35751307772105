import { RouteConfig } from 'vue-router'

import { middleware as featureToggleMiddleware } from '@/core/ui/plugins/featureToggle/featureToggle.middleware'
import { ROUTE_CALENDAR_BASE_NAME } from '@/features/calendar/ui/constants/routes.constants'

import { PRACTICE_SETUP_NAME, PRACTICE_SETUP_PATH } from './routes.constants'

const Index = () => import(/* webpackChunkName: "practice-setup-view" */ '../views/PracticeSetupView.vue')

export const PRACTICE_SETUP_ROUTE: RouteConfig = {
    path: PRACTICE_SETUP_PATH,
    name: PRACTICE_SETUP_NAME,
    component: Index,
    meta: {
        middleware: featureToggleMiddleware({
            key: 'OnboardingWizardTracking',
            value: 'true',
            fallbackRouteName: ROUTE_CALENDAR_BASE_NAME
        })
    }
}
