export enum NotificationSettings {
    NotifyAdvanceAppointment = 'notifyAdvanceAppointment',
    NotifyPatientWelcomeMessage = 'notifyPatientWelcomeMessage',
    NotifyPatients = 'notifyPatients',
    NotifyPatientsAdminBooking = 'notifyPatientsAdminBookings',
    NotifyPatientsReview = 'notifyPatientsReview',
    PatientsNotificationType = 'patientsNotificationType',
    SuggestNextBookingOnline = 'suggestNextBookingOnline'
}

export enum NotificationSettingsDependencyType {
    Disabled = 'disabled',
    Enabled = 'enabled'
}
