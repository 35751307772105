import { AuthState } from './state'

export enum AuthMutationTypes {
    ResetAuth = 'MUTATION_RESET_AUTH',
    SetAuthStatus = 'MUTATION_SET_AUTH_STATUS',
    SetAuthToken = 'MUTATION_SET_AUTH_TOKEN',
    SetIsFakeClinic = 'MUTATION_SET_IS_FAKE_CLINIC',
    SetIsPublicPractitioner = 'MUTATION_SET_IS_PUBLIC_PRACTITIONER',
    SetSaasNavigation = 'MUTATION_SET_SAAS_NAVIGATION',
    SetUser = 'MUTATION_SET_USER',
    SetUserProfileCopyLinkUrl = 'MUTATION_SET_USER_PROFILE_COPY_LINK_URL',
    SetUserProfileEmailUrl = 'MUTATION_SET_USER_PROFILE_EMAIL_URL',
    SetUserProfileWhatsappUrl = 'MUTATION_SET_USER_PROFILE_WHATSAPP_URL',
    SetUserSettingsByKey = 'MUTATION_SET_USER_SETTINGS_BY_KEY',
    SetUsersAccounters = 'MUTATION_SET_USERS_ACCOUNTUSERS'
}

export type AuthMutations = {
    [AuthMutationTypes.ResetAuth](state: AuthState): void
    [AuthMutationTypes.SetAuthStatus](state: AuthState, error: any): void
    [AuthMutationTypes.SetAuthToken](state: AuthState): void
    [AuthMutationTypes.SetIsFakeClinic](state: AuthState, isFakeClinic: any): void
    [AuthMutationTypes.SetIsPublicPractitioner](state: AuthState, isPublicPractitioner: boolean): void
    [AuthMutationTypes.SetSaasNavigation](state: AuthState, data: any): void
    [AuthMutationTypes.SetUser](state: AuthState, user: any): void
    [AuthMutationTypes.SetUserProfileCopyLinkUrl](state: AuthState, url: string): void
    [AuthMutationTypes.SetUserProfileEmailUrl](state: AuthState, url: string): void
    [AuthMutationTypes.SetUserProfileWhatsappUrl](state: AuthState, url: string): void
    [AuthMutationTypes.SetUserSettingsByKey](state: AuthState, { key, value }: any): void
    [AuthMutationTypes.SetUsersAccounters](state: AuthState, accountUsers: any): void
}
