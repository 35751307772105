import { ensureToggleIsEnabled } from '@/core/ui/plugins/featureToggle/featureToggle.util'

interface FreeUserToggles {
    enabled: boolean
    toggle: string
}

const freeUserFeatureToggles: FreeUserToggles[] | [] = [{ toggle: 'HasAccessToSaasForFreemiumTrial', enabled: true }]

export const getAnyBypassFeatureToggle = (): boolean => {
    return freeUserFeatureToggles.some(({ toggle, enabled }) => ensureToggleIsEnabled(toggle, enabled))
}
