import httpAPI from '@/api/utils/httpClientAPI'
import { ensureToggleIsEnabled } from '@/core/ui/plugins/featureToggle/featureToggle.util'

import { getMKPLTokenWithoutType } from '../domain/utils/token.utils'
import { QRCodeDTO } from './interfaces/account.DTO'

const URL_BASE = 'account'
const URL_QR_CODE_IMAGE = `${URL_BASE}/qrcodeimage`
const URL_CREATE = `${URL_BASE}/create`
const URL_CREATE_V2 = `v2/${URL_BASE}/create`
const URL_ACCOUNT_LOGO = `${URL_BASE}/logo`
const URL_INVOICING_INTEGRATIONS_TERMS_ACCEPTED = `${URL_BASE}/invoicing/integrationtermsaccepted`
const URL_SOFTWARE_VERSION = `${URL_BASE}/version`
const URL_DLINK = `${URL_BASE}/dlink`

class AccontRepository {
    async getQRCodeImage(): Promise<QRCodeDTO> {
        const randomNumber = Math.random()
        const { data }: any = await httpAPI.get(URL_QR_CODE_IMAGE, {
            params: {
                r: randomNumber,
                marketplaceToken: getMKPLTokenWithoutType()
            },
            responseType: 'arraybuffer'
        })

        return data
    }

    async getAccountLogo(): Promise<any> {
        const { data } = await httpAPI.get(URL_ACCOUNT_LOGO)
        return data
    }

    async getInvoicingIntegrationTermsAccepted(): Promise<any> {
        const { data } = await httpAPI.get(URL_INVOICING_INTEGRATIONS_TERMS_ACCEPTED)
        return data
    }

    patchInvoicingIntegrationTermsAccepted(): Promise<any> {
        return httpAPI.patch(URL_INVOICING_INTEGRATIONS_TERMS_ACCEPTED)
    }

    create(): Promise<any> {
        const url = ensureToggleIsEnabled('UseSSOTokenOnSaaS', true) ? URL_CREATE_V2 : URL_CREATE

        return httpAPI.post(url)
    }

    async getSoftwareVersion(): Promise<string> {
        const { data } = await httpAPI.get(URL_SOFTWARE_VERSION)
        return data
    }

    async getShortDLink(): Promise<string> {
        const { data } = await httpAPI.get(URL_DLINK)
        return data
    }
}

export default new AccontRepository()
