import { parse, stringify } from 'query-string'

import { SSO_LOGIN_STATE, SSO_RENEW_STATE } from '@/features/auth/domain/constants/auth.constants'
import store from '@/store'
import Storage from '@/store/storage'
import * as STORAGE from '@/store/storage-types'
import { apiMkplUrl, getDecodedUrl, redirectUri } from '@/utils/url'

const routeBase = '/#'
const paramsToExpect = ['redirectUrl', 'redirect_uri']
const MKPL_LOGOUT_PATH = '/auth/logout'

const getExpectedParam = params => {
    return paramsToExpect.find(item => {
        return params[item]
    })
}

const getRedirectURL = to => {
    let redirectTo = Storage.get(STORAGE.REDIRECT_URL)

    if (redirectTo) {
        Storage.remove(STORAGE.REDIRECT_URL)
        return redirectTo
    }

    if (location.hash) {
        const search = location.hash.split('?')[1]

        if (search) {
            const params = parse(search)

            redirectTo = params[getExpectedParam(params)]
        }
    }

    return redirectTo
}

const getPathMatched = to => {
    return to.params.pathMatch
}

const getRedirectPath = to => {
    let redirectTo = to.fullPath
    const redirection = getRedirectURL(to)

    if (redirection) {
        redirectTo = redirection
    }

    return getDecodedUrl(redirectTo)
}

const getProviderPath = () => {
    const { url_login: urlLogin, client_id: clientId, scope, response_type: responseType } = store.getters.authProvider

    const state = SSO_LOGIN_STATE
    const qs = stringify({
        client_id: clientId,
        redirect_uri: redirectUri,
        response_type: responseType,
        scope,
        state
    })

    return `${urlLogin}?${qs}`
}

const getRenewTokenPath = () => {
    return getProviderPath().replace(SSO_LOGIN_STATE, SSO_RENEW_STATE).concat('&includePostMessage')
}

const goToProviderPath = () => {
    window.location.href = getProviderPath()
}

const getApiLogoutPath = to => {
    const { url_logout: urlLogout } = store.getters.authProvider
    const ssoLoginWithSaaSRedirect = getProviderPath()
    const ssoLogoutPath = `${urlLogout}?redirect_uri=${encodeURIComponent(ssoLoginWithSaaSRedirect)}`
    const mkplLogoutPath = `${apiMkplUrl}${MKPL_LOGOUT_PATH}?redirect_uri=${encodeURIComponent(ssoLogoutPath)}`

    return mkplLogoutPath
}

const getMFALogoutPath = () => {
    const { sso_base_url: ssoBaseUrl } = store.getters.authProvider
    const ssoMFALogoutPath = `${ssoBaseUrl}two-factor-settings?redirect_uri=${encodeURIComponent(
        location.href
    )}&required_mfa_level=10`
    const mkplLogoutPath = `${apiMkplUrl}${MKPL_LOGOUT_PATH}?redirect_uri=${encodeURIComponent(ssoMFALogoutPath)}`

    return mkplLogoutPath
}

const getImpersonationPath = username => {
    const { sso_base_url: ssoBaseUrl } = store.getters.authProvider
    const encodedUsername = encodeURIComponent(username)

    const ssoSwitchUserImpersonate = `${ssoBaseUrl}?_switch_user=${encodedUsername}&redirect_uri=${encodeURIComponent(
        window.location.origin
    )}`
    const ssoSwitchUserExit = `${ssoBaseUrl}?_switch_user=_exit&redirect_uri=${encodeURIComponent(
        ssoSwitchUserImpersonate
    )}`

    return ssoSwitchUserExit
}

export {
    getApiLogoutPath,
    getDecodedUrl,
    getExpectedParam,
    getImpersonationPath,
    getMFALogoutPath,
    getPathMatched,
    getProviderPath,
    getRedirectPath,
    getRedirectURL,
    getRenewTokenPath,
    goToProviderPath,
    paramsToExpect,
    routeBase
}
