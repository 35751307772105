import monitor from '@/bootstrap/monitor/monitor'
import { usePackaging } from '@/features/packaging/composables/usePackaging'

import { tryRedirectFreeUser } from './utils/tryRedirectFreeUser'

export default async function packageFeaturesMiddleware({ next, to }: any) {
    try {
        const { initializePackaging, packagingState, canUsePackaging, getIsFreeDoctor } = usePackaging()

        if (canUsePackaging() && !packagingState.isInitialized) {
            await initializePackaging()
        }

        const redirected = tryRedirectFreeUser({
            isFreeUser: getIsFreeDoctor(),
            next,
            to
        })

        if (redirected) {
            return
        }
    } catch (e: any) {
        e.message = `Package middleware error: ${e.message}`
        monitor.sendException(e)
    }

    next()
}
