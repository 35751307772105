export enum FeaturePermissionsType {
    AdvanceAppointment = 'saas:schedule.advance-appointment',
    CalendarBulkOperations = 'saas:calendar.bulk-operations',
    CampaignsLimitPrefix = 'saas:campaigns.messages.limit',
    DpVideo = 'saas:schedule.telemedicine.dp-video',
    IntegrationsPluginManager = 'integrations:plugin-manager',
    PatientDocuments = 'saas:patient.documents',
    PatientEHRRead = 'saas:patient.ehr.read',
    PatientEHRWrite = 'saas:patient.ehr.write',
    PaymentsConfiguration = 'saas:payments.configuration',
    PostOfficeConfirmationBasic = 'post-office:confirmation.basic',
    ScheduleOnlineBookingConfiguration = 'saas:schedules.online-booking.configuration',
    WaitingList = 'saas:schedule.waiting-list'
}
