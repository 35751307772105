import {
    MUTATION_SET_EDITING_PATIENT_EPISODE,
    MUTATION_SET_EPISODE_TYPES,
    MUTATION_SET_IS_DRS_COPILOT_TRAINING_USAGE_ACCEPTED,
    MUTATION_SET_PATIENT,
    MUTATION_SET_SERVICES,
    MUTATION_SET_SNIPPETS,
    MUTATION_SET_TEMPLATE_SMART_PROPERTIES,
    MUTATION_SET_TEMPLATES
} from './types/mutations'

const mutations = {
    [MUTATION_SET_PATIENT](state, patient) {
        state.patient = patient
    },
    [MUTATION_SET_SERVICES](state, services) {
        state.services = services
    },
    [MUTATION_SET_SNIPPETS](state, snippets) {
        state.snippets = snippets
    },
    [MUTATION_SET_TEMPLATES](state, templates) {
        state.templates = templates
    },
    [MUTATION_SET_TEMPLATE_SMART_PROPERTIES](state, properties) {
        state.templateSmartProperties = properties
    },
    [MUTATION_SET_EPISODE_TYPES](state, episodeTypes = []) {
        state.episodeTypes = episodeTypes
    },
    [MUTATION_SET_EDITING_PATIENT_EPISODE](state, episode) {
        state.episode = episode
    },
    [MUTATION_SET_IS_DRS_COPILOT_TRAINING_USAGE_ACCEPTED](state, isDoctorsCopilotTrainingUsageAccepted) {
        state.isDoctorsCopilotTrainingUsageAccepted = isDoctorsCopilotTrainingUsageAccepted
    }
}

export default mutations
