import { middleware as featureToggleMiddleware } from '@/core/ui/plugins/featureToggle'
import { ADMIN_ROUTES } from '@/features/admin/ui/constants/routes.constants'

const Admin = () => import(/* webpackChunkName: "admin" */ '@/features/admin/ui/views/Admin.vue')
const AdminPanel = () => import(/* webpackChunkName: "admin-panel" */ '@/features/admin/ui/views/AdminPanel.vue')
const AdminDataImport = () =>
    import(/* webpackChunkName: "admin-data-import" */ '@/features/admin/ui/views/AdminDataImport.vue')
const AdminDataImportList = () =>
    import(/* webpackChunkName: "admin-data-import-list" */ '@/features/admin/ui/views/AdminDataImportList.vue')
const AdminAccess = () => import(/* webpackChunkName: "admin-access" */ '@/features/admin/ui/views/AdminAccess.vue')
const AdminServiceImport = () =>
    import(/* webpackChunkName: "admin-service-import" */ '@/features/admin/ui/views/AdminServiceImport.vue')
const AdminNewServicesImport = () =>
    import(/* webpackChunkName: "admin-new-services-import" */ '@/features/admin/ui/views/AdminNewServicesImport.vue')
const AdminNewServicesImportList = () =>
    import(
        /* webpackChunkName: "admin-new-services-import-list" */ '@/features/admin/ui/views/AdminNewServicesImportList.vue'
    )
const AdminServiceImportDetail = () =>
    import(
        /* webpackChunkName: "admin-service-import-detail" */ '@/features/admin/ui/views/AdminServiceImportDetail.vue'
    )
const AdminServiceList = () =>
    import(/* webpackChunkName: "admin-service-list" */ '@/features/admin/ui/views/AdminServiceList.vue')
const AdminServiceImportList = () =>
    import(/* webpackChunkName: "admin-service-import-detail" */ '@/features/admin/ui/views/AdminServiceImportList.vue')
const AdminCalendarAppDashboard = () =>
    import(
        /* webpackChunkName: "admin-calendar-app-dashboard" */ '@/features/admin/ui/views/AdminCalendarAppDashboard.vue'
    )
const AdminScheduleTools = () =>
    import(/* webpackChunkName: "admin-schedule-tools" */ '@/features/admin/ui/views/AdminScheduleTools.vue')
export default [
    {
        path: 'admin',
        name: ADMIN_ROUTES.ADMIN,
        redirect: 'admin/panel',
        component: Admin,
        children: [
            {
                path: 'panel',
                name: ADMIN_ROUTES.PANEL,
                component: AdminPanel
            },
            {
                path: 'import',
                name: ADMIN_ROUTES.IMPORT,
                component: AdminDataImport
            },
            {
                path: 'import/list',
                name: ADMIN_ROUTES.IMPORT_LIST,
                component: AdminDataImportList
            },
            {
                path: 'service-list',
                name: ADMIN_ROUTES.SERVICE_LIST,
                component: AdminServiceList
            },
            {
                path: 'service-import',
                name: ADMIN_ROUTES.SERVICE_IMPORT_WIZARD,
                component: AdminServiceImport
            },
            {
                path: 'new-services-import',
                name: ADMIN_ROUTES.NEW_SERVICES_IMPORT_WIZARD,
                component: AdminNewServicesImport,
                meta: {
                    middleware: featureToggleMiddleware({
                        key: 'ServiceConfiguration',
                        value: 'true',
                        fallbackRouteName: ADMIN_ROUTES.SERVICE_IMPORT_WIZARD
                    })
                }
            },
            {
                path: 'new-services-import-list',
                name: ADMIN_ROUTES.NEW_SERVICES_IMPORT_LIST,
                component: AdminNewServicesImportList,
                meta: {
                    middleware: featureToggleMiddleware({
                        key: 'ServiceConfiguration',
                        value: 'true',
                        fallbackRouteName: ADMIN_ROUTES.IMPORT_LIST
                    })
                }
            },
            {
                path: 'service-import/list',
                name: ADMIN_ROUTES.SERVICE_IMPORT_LIST,
                component: AdminServiceImportList
            },
            {
                path: 'service-import/:uniqueId/detail',
                name: ADMIN_ROUTES.SERVICE_IMPORT_DETAIL,
                component: AdminServiceImportDetail,
                props: true
            },
            {
                path: 'calendar-app-dashboard',
                name: ADMIN_ROUTES.CALENDAR_APP_DASHBOARD,
                component: AdminCalendarAppDashboard,
                props: true
            },
            {
                path: 'schedule-tools',
                name: ADMIN_ROUTES.SCHEDULE_TOOLS,
                component: AdminScheduleTools,
                props: true
            }
        ]
    },
    {
        path: 'admin-access',
        name: ADMIN_ROUTES.ACCESS,
        alias: '/admin/access',
        component: AdminAccess
    }
]
