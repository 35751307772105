import AVOTrack from '@lib/avo'

import httpAPI from '@/api/utils/httpClientAPI'
import httpClientCacheAPI from '@/api/utils/httpClientCacheAPI'
import { clearDataDogUser } from '@/bootstrap/monitor/datadog-poc/datadog'
import { removeAllCookies, removeAllCookiesExcept, removeCookie } from '@/core/ui/utils/cookies.utils'
import NavigationAPI from '@/features/navigation/api/navigation.api'
import { ACTION_SET_IS_DRS_COPILOT_TRAINING_USAGE_ACCEPTED } from '@/features/pms/ui/store/types/actions'
import { MODULE_PMS } from '@/features/pms/ui/store/types/module'
import UsersService from '@/features/settings/domain/users.service'
import { ActionsAdaptor } from '@/interfaces'
import store from '@/store'
import { ACTION_SET_APP_STATE } from '@/store/actions-types'
import { RESET_MODULE_STORES } from '@/store/mutation-types'
import storage from '@/store/storage'
import * as STORAGE from '@/store/storage-types'
import { redirectUri } from '@/utils/url'

import AuthService from '../../domain/auth.service'
import { MKPL_COOKIE, SAAS_COOKIE } from '../../domain/constants/auth.constants'
import ProfileService from '../../domain/profile.service'
import { getMKPLToken, setMKPLToken, setSAASToken } from '../../domain/utils/token.utils'
import { AuthActions, AuthActionTypes, AuthGetterTypes, AuthMutations, AuthMutationTypes, AuthState } from './types'

const actions: ActionsAdaptor<AuthActions, AuthMutations, AuthState> = {
    async [AuthActionTypes.AuthWebToken]({ commit, dispatch }, code: string) {
        commit(AuthMutationTypes.SetAuthStatus, 'loading')

        dispatch(AuthActionTypes.AuthLogout)

        try {
            const { saasToken, marketplaceToken } = await AuthService.getWebToken(code, redirectUri)
            dispatch(AuthActionTypes.AuthToken, {
                saas: saasToken,
                mkpl: marketplaceToken
            })
        } catch (error) {
            dispatch(AuthActionTypes.AuthError, {
                error
            })
            return Promise.reject(error)
        }
    },
    [AuthActionTypes.AuthError]({ commit }, { error }) {
        commit(AuthMutationTypes.SetAuthStatus, error.response.data.error_description)
        removeCookie(SAAS_COOKIE)
        removeCookie(MKPL_COOKIE)

        return Promise.reject(error)
    },
    [AuthActionTypes.AuthToken]({ commit }, { saas, mkpl }) {
        if (saas?.token) {
            setSAASToken(saas.token, saas.expires_in)
        }

        if (mkpl?.token) {
            setMKPLToken(mkpl.token, mkpl.expires_in)
        }

        commit(AuthMutationTypes.SetAuthToken)
    },
    async [AuthActionTypes.AuthLogin]({ commit, dispatch }, { userId }) {
        await store.dispatch(AuthActionTypes.SwitchSaaSUser, { userId })
    },
    async [AuthActionTypes.SwitchSaaSUser]({ commit, dispatch }, { userId }) {
        commit(AuthMutationTypes.SetAuthStatus, 'loading')

        try {
            const saasToken = await AuthService.login(userId)

            if (userId !== storage.get(STORAGE.USER_ID)) {
                clearDataDogUser()

                removeAllCookiesExcept([MKPL_COOKIE])

                storage.clear()

                httpAPI.clearAuthorizationToken()
                httpClientCacheAPI.clearAuthorizationToken()

                dispatch(ACTION_SET_APP_STATE, false)
                dispatch(RESET_MODULE_STORES)
            }

            dispatch(AuthActionTypes.AuthToken, {
                saas: saasToken
            })

            storage.set(STORAGE.USER_ID, userId)
        } catch (error) {
            dispatch(AuthActionTypes.AuthError, {
                error
            })
            return Promise.reject(error)
        }
    },
    [AuthActionTypes.AuthLogout]({ dispatch }) {
        AVOTrack.generalUserLogout()

        // TODO: refactor to integrate with monitor (WSTB-503)
        clearDataDogUser()

        removeAllCookies()

        storage.clear()

        httpAPI.clearAuthorizationToken()
        httpClientCacheAPI.clearAuthorizationToken()

        dispatch(ACTION_SET_APP_STATE, false)
        dispatch(RESET_MODULE_STORES)
    },
    async [AuthActionTypes.FetchProfile]({ commit, dispatch }) {
        commit(AuthMutationTypes.SetAuthStatus, 'loading')

        try {
            const profile = await AuthService.getProfile()
            commit(AuthMutationTypes.SetUser, profile)
            storage.set(STORAGE.USER_ID, profile.id)

            dispatch(
                `${MODULE_PMS}/${ACTION_SET_IS_DRS_COPILOT_TRAINING_USAGE_ACCEPTED}`,
                profile.isDoctorsCopilotTrainingUsageAccepted
            )
        } catch (error) {
            return Promise.reject(error)
        }
    },
    async [AuthActionTypes.FetchUsersAccountUsers]({ commit, dispatch }) {
        try {
            const accountUsers = await UsersService.getAccountUsers({
                includeCurrentUser: true
            })

            commit(AuthMutationTypes.SetUsersAccounters, accountUsers)
            const currentUser = storage.get(STORAGE.USER_ID)
            if (currentUser) {
                const exitsOnAccount = accountUsers.find(
                    (accountUser: any) => accountUser.id === parseInt(currentUser, 10)
                )

                if (!exitsOnAccount) {
                    storage.remove(STORAGE.USER_ID)
                }
            }
        } catch (error) {
            return Promise.reject(error)
        }
    },
    async [AuthActionTypes.FetchUserProfileCopyLinkUrl]({ getters, commit, state }, { utms }) {
        if (!getters[AuthGetterTypes.IsLoggedIn] || state.userProfileCopyLinkUrl) {
            return
        }

        try {
            const { doctorUrl } = await ProfileService.hostUrl(utms)

            commit(AuthMutationTypes.SetUserProfileCopyLinkUrl, doctorUrl)
        } catch (error) {
            commit(AuthMutationTypes.SetUserProfileCopyLinkUrl, '')
        }
    },
    async [AuthActionTypes.FetchUserProfileEmailUrl]({ getters, commit, state }, { utms }) {
        if (!getters[AuthGetterTypes.IsLoggedIn] || state.userProfileEmailUrl) {
            return
        }

        try {
            const { doctorUrl } = await ProfileService.hostUrl(utms)

            commit(AuthMutationTypes.SetUserProfileEmailUrl, doctorUrl)
        } catch (error) {
            commit(AuthMutationTypes.SetUserProfileEmailUrl, '')
        }
    },
    async [AuthActionTypes.FetchUserProfileWhatsappUrl]({ getters, commit, state }, { utms }) {
        if (!getters[AuthGetterTypes.IsLoggedIn] || state.userProfileWhatsappUrl) {
            return
        }

        try {
            const { doctorUrl } = await ProfileService.hostUrl(utms)

            commit(AuthMutationTypes.SetUserProfileWhatsappUrl, doctorUrl)
        } catch (error) {
            commit(AuthMutationTypes.SetUserProfileWhatsappUrl, '')
        }
    },
    async [AuthActionTypes.FetchSaasNavigation]({ commit }) {
        try {
            const saasUniNavOptions = await NavigationAPI.navigation()
            commit(AuthMutationTypes.SetSaasNavigation, saasUniNavOptions)
        } catch (error) {
            commit(AuthMutationTypes.SetSaasNavigation, {})
        }
    },
    [AuthActionTypes.SetIsFakeClinic]({ commit }, isFakeClinic) {
        commit(AuthMutationTypes.SetIsFakeClinic, isFakeClinic)
    },
    [AuthActionTypes.SetIsPublicPractitioner]({ commit }, isPublicPractitioner) {
        commit(AuthMutationTypes.SetIsPublicPractitioner, isPublicPractitioner)
    },
    [AuthActionTypes.SetUserSettingsByKey]({ commit }, { key, value }) {
        commit(AuthMutationTypes.SetUserSettingsByKey, { key, value })
    }
}

export default actions
