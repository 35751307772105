import { computed, ref } from 'vue'

import { ensureToggleIsEnabled } from '@/core/ui/plugins/featureToggle/featureToggle.util'
// import { AuthGetterTypes } from '@/features/auth/ui/store/types'
import store from '@/store'

import { PaymentsAccountType } from '../../domain/enums'
import { PaymentsProfile } from '../../domain/interfaces'
import PaymentsProfileService from '../../domain/paymentsProfile.service'
import { getPaymentsNavigationOption } from '../utils'

const paymentsUserProfile = ref<PaymentsProfile>()
const isLoading = ref<boolean>(false)

let ongoingRequest: Promise<void> | null = null

export function usePaymentsProfile() {
    function getPaymentsProfile({ cache = true } = {}) {
        if (ongoingRequest) return ongoingRequest
        if (paymentsUserProfile.value && cache) return

        isLoading.value = true

        ongoingRequest = PaymentsProfileService.getPaymentsProfile({ cache })
            .then(profile => {
                paymentsUserProfile.value = profile
            })
            .catch(() => {})
            .finally(() => {
                ongoingRequest = null
                isLoading.value = false
            })

        return ongoingRequest
    }

    const countryHasPayments = computed(() => store.getters.feature('onlinepayments'))
    const canOnboardPayments = computed(() => !!paymentsUserProfile.value?.canOnboard)
    // const hasOnlinePaymentsFromUserSettings = computed(
    //     () => store.getters[AuthGetterTypes.GetAuthUser]?.userSettings?.isOnlinePaymentsEnabled === 'true'
    // )

    /**
     * User has an onboarded payment account but might not be ready to be used because action required
     */
    const hasPaymentsOnboarded = computed(() => {
        if (!paymentsUserProfile.value) return false
        return (
            paymentsUserProfile.value.accountType !== null &&
            paymentsUserProfile.value.accountType !== PaymentsAccountType.None
        )
    })

    /**
     * User has a payment account with issues that need to be resolved
     */
    const hasPaymentsActionRequiredStatus = computed(() => !!paymentsUserProfile.value?.isPaymentsActionRequired)

    /**
     * User can onboard to create a payment account
     */
    const canActivatePayments = computed(
        () => countryHasPayments.value && canOnboardPayments.value && !hasPaymentsOnboarded.value
    )

    /**
     * User has an onboarded payment account, ready to be used without any issue
     */
    const hasPaymentsActive = computed(
        () => countryHasPayments.value && hasPaymentsOnboarded.value && !hasPaymentsActionRequiredStatus.value
    )

    /**
     * User has access to payments area with two possible scenarios:
     *     1. Landing page with activation cta or with action required disclaimer because onboarded account has issues
     *     2. Payments area including overview, settings, payments requests etc.
     */
    const hasAccessToPaymentsArea = computed(
        () => countryHasPayments.value && (canActivatePayments.value || hasPaymentsOnboarded.value)
    )

    const isUserAllowedToMigrate = computed(
        () =>
            paymentsUserProfile.value?.fee.isAllowedToMigrate &&
            paymentsUserProfile.value?.fee.paymentFee !== null &&
            paymentsUserProfile.value?.fee.migrationPaymentFee !== null &&
            paymentsUserProfile.value?.accountType === PaymentsAccountType.StripeCustom
    )

    const isPaymentsRequestsEnabled = computed(() => {
        const userHasSingleCustomerId = paymentsUserProfile.value?.customerIds.length === 1
        return userHasSingleCustomerId && hasPaymentsActive.value
    })

    const isExtraVisibilityActionEnabled = computed(() => {
        return ensureToggleIsEnabled('PaymentsExtraVisibility', true) && hasPaymentsActive.value
    })

    const isPaymentsVouchersEnabled = computed(
        () =>
            ensureToggleIsEnabled('VouchersPaymentsRequests', true) &&
            isPaymentsRequestsEnabled.value &&
            hasPaymentsActive.value
    )

    const paymentsMenuOption = computed(() =>
        hasAccessToPaymentsArea.value ? getPaymentsNavigationOption() : undefined
    )

    return {
        getPaymentsProfile,
        paymentsUserProfile,
        paymentsMenuOption,
        canActivatePayments,
        countryHasPayments,
        hasPaymentsActive,
        hasAccessToPaymentsArea,
        hasPaymentsOnboarded,
        hasPaymentsActionRequiredStatus,
        isExtraVisibilityActionEnabled,
        isPaymentsRequestsEnabled,
        isPaymentsVouchersEnabled,
        isUserAllowedToMigrate,
        isLoading
    }
}
