import {
    SETTINGS_BOOKING_QUESTIONS_ROUTE,
    SETTINGS_BOOKING_RULES_NOTE_SET_EDITION_ROUTE,
    SETTINGS_BOOKING_RULES_QUESTION_SET_EDITION_ROUTE,
    SETTINGS_BOOKING_RULES_ROUTE
} from '../constants/router.constants'
import hasAccessToBookingQuestions from '../middleware/hasAccessToBookingQuestions.middleware'

const BookingRulesIndex = () => import(/* webpackChunkName: "booking-rules-index" */ '../views/BookingRulesIndex.vue')

const BookingNotes = () => import(/* webpackChunkName: "booking-notes" */ '../views/BookingNotes.vue')

const BookingQuestions = () => import(/* webpackChunkName: "booking-questions" */ '../views/BookingQuestions.vue')

const NoteSetEdition = () =>
    import(/* webpackChunkName: "booking-rules-note-set-edition" */ '../components/noteSetEdition/NoteSetEdition.vue')
const QuestionSetEdition = () =>
    import(
        /* webpackChunkName: "booking-rules-question-set-edition" */ '../components/questionSetEdition/QuestionSetEdition.vue'
    )

export default [
    {
        path: 'booking-rules',
        component: BookingRulesIndex,
        children: [
            {
                name: SETTINGS_BOOKING_RULES_ROUTE,
                path: 'notes',
                component: BookingNotes
            },
            {
                name: SETTINGS_BOOKING_QUESTIONS_ROUTE,
                meta: {
                    middleware: hasAccessToBookingQuestions
                },
                path: 'questions',
                component: BookingQuestions
            }
        ]
    },
    {
        name: SETTINGS_BOOKING_RULES_NOTE_SET_EDITION_ROUTE,
        path: 'booking-rules/notes/note-set/:id?',
        component: NoteSetEdition
    },
    {
        path: 'booking-rules/questions/question-set/:id?',
        meta: {
            middleware: hasAccessToBookingQuestions
        },
        name: SETTINGS_BOOKING_RULES_QUESTION_SET_EDITION_ROUTE,
        component: QuestionSetEdition
    }
]
