import { freemiumSaasOnly } from '@docplanner/one-tracking'
import { mapGetters } from 'vuex'

import { ALERT_MODAL } from '@/components/modals/names'
import { AuthGetterTypes } from '@/features/auth/ui/store/types'

import { TermsSource } from '../../domain/enums/terms.enums'
import VideoConsultationTermsService from '../../domain/videoConsultationTerms.service'
import { EXCLUDED_ROUTES_FREEMIUM_TERMS } from '../constants/terms.constants'

const TermsAndConditionsFreemiumModalMixin = {
    name: 'TermsAndConditionsFreemiumModal',

    computed: {
        ...mapGetters({
            authUser: AuthGetterTypes.GetAuthUser,
            features: 'features',
            saasOnly: AuthGetterTypes.IsSaasOnly
        }),
        modalEnabled() {
            const { videoConsultationTermsAccepted } = this.authUser || {}
            const { isFreemium } = this.saasOnly
            const { freemiumtermsmodalenabled } = this.features || {}

            if (!freemiumtermsmodalenabled) {
                return false
            }

            if (videoConsultationTermsAccepted) {
                return false
            }

            if (this.routeIsExcluded) {
                return false
            }

            return isFreemium
        },
        routeIsExcluded() {
            const { name: currentRouteName } = this.$route

            if (!currentRouteName) {
                return true
            }

            return EXCLUDED_ROUTES_FREEMIUM_TERMS.includes(currentRouteName)
        }
    },

    watch: {
        modalEnabled: {
            handler(val) {
                if (val) {
                    setTimeout(this.showModal, 1000)
                }
            },
            immediate: true
        }
    },

    methods: {
        setTermsAccepted() {
            this.trackGA('accept')
            VideoConsultationTermsService.setVideoTermsAccepted(TermsSource.FreemiumModal)
        },

        showModal() {
            this.trackGA('show')

            const MODAL_CONTENT_1 = this.$t('freemium-terms-and-conditions-wizard-modal-acceptation-body')
            const MODAL_CONTENT_2 = this.$t('freemium-terms-and-conditions-wizard-modal-acceptation-body-2')
            const LINK_TEXT = this.$t('freemium-terms-and-conditions-wizard-modal-acceptation-body-text-with-link')
            const LINK_URL = this.$t('freemium-terms-and-conditions-wizard-modal-acceptation-body-url')

            this.$modal
                .show(ALERT_MODAL, {
                    title: this.$t('freemium-terms-and-conditions-wizard-modal-acceptation-title'),
                    showCloseBtn: false,
                    props: {
                        content: `${MODAL_CONTENT_1}<a href="${LINK_URL}" target="_blank"> ${LINK_TEXT} </a>${MODAL_CONTENT_2}`,
                        actionButtonText: this.$t('freemium-terms-and-conditions-wizard-modal-acceptation-cta'),
                        showCancelButton: false
                    }
                })
                .then(this.setTermsAccepted)
        },

        trackGA(label) {
            this.$tracking.track({
                category: freemiumSaasOnly.freemiumModal.category,
                action: freemiumSaasOnly.freemiumModal.action,
                label
            })
        }
    }
}

export default TermsAndConditionsFreemiumModalMixin
