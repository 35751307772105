export enum PaymentStatus {
    Unpaid = 0,
    Paid,
    Refunded,
    RefundFailed,
    PartiallyPaid
}

export enum PaymentRequestStatus {
    NotPaid = 0,
    Paid,
    Refunded,
    WaitingForConfirmation,
    Canceled
}

export enum PaymentRequestType {
    None = 0,
    Visit,
    Voucher
}

export enum PaymentStatusTranslationKeys {
    Paid = 'payments-section-overview-history-filter-status-paid',
    PartiallyPaid = 'payments-section-overview-history-filter-status-partially-paid',
    RefundFailed = 'payments-section-overview-history-filter-status-refund-failed',
    Refunded = 'payments-section-overview-history-filter-status-refunded',
    Unpaid = 'payments-section-overview-history-filter-status-unpaid'
}

export enum PaymentRequestStatusTranslationKeys {
    Canceled = 'payments-section-payments-requests-summary-status-canceled',
    NotPaid = 'payments-section-payments-requests-summary-status-not-paid',
    Paid = 'payments-section-payments-requests-summary-status-paid',
    Refunded = 'payments-section-payments-requests-summary-status-refunded',
    WaitingForConfirmation = 'payments-section-payments-requests-summary-status-pending'
}

export enum PaymentType {
    Automatic = 'automatic',
    Custom = 'custom'
}

export enum PaymentRequestTypeTranslationKeys {
    None = 'payments-section-payments-requests-list-column-type-request',
    Visit = 'payments-section-payments-requests-list-column-visit',
    Voucher = 'payments-section-payments-requests-list-column-type-voucher'
}

export enum DisablePaymentTracking {
    DoesntWork = 'not_work',
    DontLike = 'dont_like',
    DontNeed = 'dont_need',
    Other = 'other'
}

export enum PaymentsBannerOptionName {
    ActivatePayments = 'activatePayments',
    AddCard = 'addCard',
    AddPrices = 'addPrices',
    AllSet = 'allSet',
    Helper = 'helper'
}

export enum SchedulePaymentStatus {
    Active = 0,
    Inactive,
    ActiveByDefault,
    Required
}

export enum PaymentsRequestsPreviewName {
    DateTime = 'dateTime',
    Email = 'email',
    FullName = 'fullName',
    Phone = 'phone',
    ServiceName = 'serviceName'
}

export enum SchedulePaymentStatusTranslationKeys {
    Active = 'payments-section-settings-list-online-payments-badge-active',
    ActiveByDefault = 'payments-section-settings-list-online-payments-badge-active-by-default',
    Inactive = 'payments-section-settings-list-online-payments-badge-inactive',
    Required = 'payments-section-settings-list-online-payments-badge-action-required'
}

export enum SchedulePaymentStatusDescriptionTranslationKeys {
    Active = '',
    ActiveByDefault = 'payments-section-settings-configure-payments-in-calendar-status-active-by-default-desc',
    Inactive = 'payments-section-settings-configure-payments-in-calendar-status-inactive-desc',
    Required = ''
}

export enum SettingsTabIds {
    ActiveSchedules = 'marketplace-schedules',
    Schedules = 'schedules'
}

export enum UpfrontPaymentsMandatorySetting {
    None = 0,
    NewPatients,
    ExistingPatients,
    AllPatients
}

export enum PaymentsUpfrontSettings {
    Inactive = 'inactive',
    Mandatory = 'mandatory',
    Optional = 'optional'
}

export enum FeatureDeactivationReason {
    DontLike,
    DoesntWork,
    DontNeed,
    Other
}
