import {
    SetupGuideTaskName,
    SetupGuideTaskURL,
    TASK_NAME_PATIENT,
    TASK_NAME_PROFILE,
    TASK_NAME_WIZARD
} from '../../domain'
import { SetupGuideTaskRouteType, SetupGuideTaskURLAppType } from '../../enums'

interface RouteInfo {
    newTab: boolean
    path: string
    type: string
}

const TASK_IN_NEW_TAB: SetupGuideTaskName[] = [TASK_NAME_WIZARD.UPDATE, TASK_NAME_PROFILE.UPDATE]

const openInNewTab = (taskName: SetupGuideTaskName) => TASK_IN_NEW_TAB.includes(taskName)

export const getRouteInfo = (url: SetupGuideTaskURL, taskName: SetupGuideTaskName): RouteInfo => {
    const { domain, path, appType } = url
    const taskInNewTab = openInNewTab(taskName)

    if (!domain || !path || appType === undefined) {
        return { type: '', path: '', newTab: false }
    }

    if (appType !== SetupGuideTaskURLAppType.SaaS) {
        return {
            type: SetupGuideTaskRouteType.Global,
            path: `${domain}${path}`,
            newTab: taskInNewTab
        }
    }

    return {
        type: SetupGuideTaskRouteType.Local,
        path,
        newTab: taskInNewTab
    }
}
