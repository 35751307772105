import { WAITING_LIST_ROUTE, WAITING_LIST_ROUTE_NAME } from '../constants/routes.constants'

const WaitingListIndex = () => import(/* webpackChunkName: "waiting-list-index" */ '../views/WaitingListIndex.vue')
const WaitingList = () => import(/* webpackChunkName: "waiting-list" */ '../views/WaitingList.vue')

export default {
    path: WAITING_LIST_ROUTE,
    component: WaitingListIndex,
    children: [
        {
            path: '',
            name: WAITING_LIST_ROUTE_NAME,
            component: WaitingList,
            meta: {
                title: 'advance-appointment-list-table-title'
            }
        }
    ]
}
