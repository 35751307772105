type SendbirdProviderOptionsParams = {
    accessToken: string
    appId: string
    channelUrl: string
    customApiHost?: string
    customWsHost?: string
    isPatientView: boolean
    options: any
    origin?: string
    platform?: string
    recipientId: string
    userId: string
}

type SendbirdProviderOptions = {
    accessToken: string
    apiHost?: string
    appId: string
    channelUrl: string
    isPatientView: boolean
    options: string
    origin: string
    platform: string
    recipientId: string
    userId: string
    wsHost?: string
}

export const composeSendbirdProviderOptions = ({
    appId,
    userId,
    recipientId,
    accessToken,
    channelUrl,
    isPatientView,
    options,
    origin,
    platform,
    customApiHost,
    customWsHost
}: SendbirdProviderOptionsParams): SendbirdProviderOptions => {
    return {
        appId: appId.toUpperCase(),
        wsHost: customWsHost,
        apiHost: customApiHost,
        userId,
        isPatientView,
        recipientId,
        accessToken,
        channelUrl,
        options,
        origin: origin || '',
        platform: platform || ''
    }
}
