import { keyBy } from '@dp-vue/utils'
import * as Sentry from '@sentry/browser'
import { assign } from 'lodash'
import Vue from 'vue'

import { date, DateFormat, formatDate, isSameDay } from '@/core/ui/utils'
import { removeRoomPrefixFromId } from '@/features/rooms/domain/utils/roomProperty.utils'

import { CalendarEventGroups } from '../../domain/enums/calendarEvent.enums'
import { mutations as calendarSidebarMutations } from './calendarSidebar/mutations'
import { mutations as performanceSurveyMutations } from './performanceSurvey/mutations'
import { defaultCalendarCreationState, defaultCalendarEventsState } from './state'
import {
    MUTATION_ADD_CALENDAR_EVENTS,
    MUTATION_ADD_CALENDAR_WORKPERIODS,
    MUTATION_CLEAN_CALENDAR_EVENTS,
    MUTATION_HIDE_PATIENT_DATAS,
    MUTATION_REMOVE_CALENDAR_EVENTS,
    MUTATION_RESET_APPOINTMENT_INTEGRATED_STATE,
    MUTATION_RESET_CALENDAR_CLIPBOARD,
    MUTATION_RESET_FREE_SLOTS_FILTERS,
    MUTATION_RESET_RIGHT_SIDEBAR_PROPS,
    MUTATION_SET_AGENDA_EVENTS,
    MUTATION_SET_AGENDA_EXPAND,
    MUTATION_SET_AGENDA_SELECTED_DURATION,
    MUTATION_SET_AGENDA_SELECTED_PATIENT,
    MUTATION_SET_CALENDAR_CLIPBOARD,
    MUTATION_SET_CALENDAR_COLOR_SCHEMAS,
    MUTATION_SET_CALENDAR_COLOR_SCHEMAS_ARRAY,
    MUTATION_SET_CALENDAR_CREATION_ADDRESS,
    MUTATION_SET_CALENDAR_CREATION_COLOR,
    MUTATION_SET_CALENDAR_CREATION_NAME,
    MUTATION_SET_CALENDAR_CREATION_RESET,
    MUTATION_SET_CALENDAR_CREATION_SERVICES,
    MUTATION_SET_CALENDAR_CREATION_TYPE,
    MUTATION_SET_CALENDAR_DEVICES,
    MUTATION_SET_CALENDAR_EVENTS,
    MUTATION_SET_CALENDAR_EVENTS_DATE_RANGE,
    MUTATION_SET_CALENDAR_EVENTS_WEEK_NUMBER,
    MUTATION_SET_CALENDAR_FILTER_SELECTED_SERVICES,
    MUTATION_SET_CALENDAR_FILTER_SERVICES,
    MUTATION_SET_CALENDAR_LOADING,
    MUTATION_SET_CALENDAR_ROOMS,
    MUTATION_SET_CALENDAR_ROUTE_PATH,
    MUTATION_SET_CALENDAR_SCHEDULES,
    MUTATION_SET_CALENDAR_SCHEDULES_ORDER,
    MUTATION_SET_CALENDAR_SELECTED_DATE,
    MUTATION_SET_CALENDAR_SELECTED_ROOMS,
    MUTATION_SET_CALENDAR_SELECTED_SCHEDULES,
    MUTATION_SET_CALENDAR_STAFF,
    MUTATION_SET_DISPLAYED_EVENTS_SERVICES_IDS,
    MUTATION_SET_DOCTOR_SCHEDULE_SELECTED,
    MUTATION_SET_DOCTOR_SCHEDULES,
    MUTATION_SET_DOCTORS,
    MUTATION_SET_DOCTORS_WORKING_CURRENT_PERIOD,
    MUTATION_SET_FACILITIES,
    MUTATION_SET_FETCH_SCHEDULES_SELECTED,
    MUTATION_SET_FREE_SLOTS_AVAILABLE_DAYS,
    MUTATION_SET_FREE_SLOTS_FILTER_DAYS,
    MUTATION_SET_FREE_SLOTS_FILTER_DAYTIME,
    MUTATION_SET_FREE_SLOTS_FILTER_INSURANCE,
    MUTATION_SET_FREE_SLOTS_SELECTED_DATE_END,
    MUTATION_SET_FREE_SLOTS_SELECTED_SERVICE,
    MUTATION_SET_ONLY_DOCTORS_SELECTED,
    MUTATION_SET_RIGHT_SIDEBAR_PROPS,
    MUTATION_SET_SCHEDULES,
    MUTATION_SET_SELECTED_FACILITY,
    MUTATION_SET_SELECTED_SPECIALITY,
    MUTATION_SET_SELECTED_SPECIALITY_GROUP,
    MUTATION_TOGGLE_CALENDAR_HIDE_BOOKED_SLOTS,
    MUTATION_TOGGLE_CALENDAR_RESOURCE_WEEK_VIEW,
    MUTATION_UPDATE_CALENDAR_EVENTS,
    MUTATION_UPDATE_DOCTORS,
    MUTATION_UPDATE_DOCTORS_SELECTED,
    MUTATION_UPDATE_SEARCH_CALENDARS_QUERY,
    MUTATION_UPDATE_SEARCH_SERVICES_QUERY
} from './types/mutations'

export const mutations = {
    [MUTATION_SET_CALENDAR_LOADING](state, isLoading) {
        state.loading = isLoading
    },
    [MUTATION_SET_CALENDAR_SELECTED_DATE](state, selectedDate) {
        selectedDate.setHours(0, 0, 0, 0)
        state.selectedDate = selectedDate
    },
    [MUTATION_SET_CALENDAR_ROUTE_PATH](state, pathName) {
        state.calendarRoutePath = pathName
    },
    [MUTATION_SET_CALENDAR_EVENTS](state, calendarEvents) {
        const currentSpan = Sentry.getCurrentScope().getSpan()
        const spanMutationSetCalendarEvents = currentSpan?.startChild({
            name: 'SAAS ::MUTATION_SET_CALENDAR_EVENTS::',
            description: 'Map received events and save them in the store',
            op: 'function'
        })

        const keys = [
            CalendarEventGroups.Blocks,
            CalendarEventGroups.Appointments,
            CalendarEventGroups.Holidays,
            CalendarEventGroups.Reminders,
            CalendarEventGroups.Resources,
            CalendarEventGroups.BookingRequests
        ]

        Object.keys(state.calendarEvents).forEach(key => {
            if (
                [CalendarEventGroups.Workperiods, CalendarEventGroups.Holidays, CalendarEventGroups.Blocks].includes(
                    key
                )
            ) {
                state.calendarEvents[key] = [...state.calendarEvents[key], ...calendarEvents[key]]
            }

            if (keys.includes(key)) {
                const eventIds = state.calendarEvents[key]?.map(event => event.id) || []
                calendarEvents[key]?.forEach(event => {
                    if (key === CalendarEventGroups.Resources) {
                        const resource = state.calendarEvents[key].find(({ id }) => id === event.id)
                        if (resource) {
                            resource.availableDates = [...resource.availableDates, ...event.availableDates]
                        } else {
                            state.calendarEvents[key].push(event)
                        }
                    } else if (!eventIds.includes(event.id)) {
                        state.calendarEvents[key].push(event)
                    }
                })
            }
        })

        spanMutationSetCalendarEvents?.end()
    },
    [MUTATION_ADD_CALENDAR_EVENTS](state, calendarEvent) {
        Object.keys(calendarEvent).forEach(key => {
            calendarEvent[key].forEach(event => {
                const eventFound = state.calendarEvents[key].find(({ id }) => id === event.id)
                if (!eventFound) {
                    state.calendarEvents[key].push(event)
                }
            })
        })
    },
    [MUTATION_UPDATE_CALENDAR_EVENTS](state, calendarEvent) {
        Object.keys(calendarEvent).forEach(key => {
            calendarEvent[key].forEach(event => {
                const eventFound = state.calendarEvents[key].find(({ id }) => id === event.id)
                if (eventFound) {
                    assign(eventFound, event)
                }
            })
        })
    },
    [MUTATION_REMOVE_CALENDAR_EVENTS](state, calendarEvent) {
        Object.keys(calendarEvent).forEach(key => {
            calendarEvent[key].forEach(({ id: calendarEventId }) => {
                const index = state.calendarEvents[key].findIndex(
                    event => event.id.toString() === calendarEventId.toString()
                )
                if (index >= 0) {
                    state.calendarEvents[key].splice(index, 1)
                }
            })
        })
    },
    [MUTATION_SET_CALENDAR_SELECTED_ROOMS](state, { roomIds, selected }) {
        state.calendarRooms.forEach((rooms, index) => {
            if (roomIds.includes(rooms.id)) {
                Vue.set(state.calendarRooms, index, { ...rooms, selected })
            }
        })
    },
    [MUTATION_SET_CALENDAR_FILTER_SELECTED_SERVICES](state, { serviceIds, selected }) {
        state.calendarServices.forEach((service, index) => {
            if (serviceIds.includes(service.id)) {
                Vue.set(state.calendarServices, index, { ...service, selected })
            }
        })
    },
    [MUTATION_SET_CALENDAR_EVENTS_DATE_RANGE](state, calendarEventsDateRange) {
        state.calendarEventsDateRange = calendarEventsDateRange
    },
    [MUTATION_SET_CALENDAR_EVENTS_WEEK_NUMBER](state, { from }) {
        const weekOfTheYear = formatDate(from, DateFormat.YearAndWeekOfTheYearFormat)

        if (!state.calendarEventsWeekRangeNumbers.includes(weekOfTheYear)) {
            state.calendarEventsWeekRangeNumbers = [...state.calendarEventsWeekRangeNumbers, weekOfTheYear]
        }
    },
    [MUTATION_SET_CALENDAR_COLOR_SCHEMAS](state, colorSchemas) {
        if (Array.isArray(colorSchemas)) {
            colorSchemas = keyBy(colorSchemas, 'id')
        }
        state.colorSchemas = colorSchemas
    },
    [MUTATION_SET_CALENDAR_COLOR_SCHEMAS_ARRAY](state, colorSchemas) {
        state.colorSchemasArray = colorSchemas
    },
    [MUTATION_SET_CALENDAR_CREATION_TYPE](state, calendarCreationType) {
        state.calendarCreationType = calendarCreationType
    },
    [MUTATION_SET_CALENDAR_CREATION_ADDRESS](state, calendarCreationAddress) {
        state.calendarCreationAddress = calendarCreationAddress
    },
    [MUTATION_SET_CALENDAR_CREATION_SERVICES](state, calendarCreationSelectedServices) {
        state.calendarCreationSelectedServices = [...calendarCreationSelectedServices]
    },
    [MUTATION_SET_CALENDAR_CREATION_NAME](state, calendarName) {
        state.calendarCreationName = calendarName
    },
    [MUTATION_SET_CALENDAR_CREATION_COLOR](state, calendarColorId) {
        state.calendarCreationColorId = calendarColorId
    },
    [MUTATION_SET_CALENDAR_CREATION_RESET](state) {
        state.calendarCreationType = defaultCalendarCreationState.calendarCreationType
        state.calendarCreationAddress = defaultCalendarCreationState.calendarCreationAddress
        state.calendarCreationSelectedServices = defaultCalendarCreationState.calendarCreationSelectedServices
        state.calendarCreationWorkperiods = defaultCalendarCreationState.calendarCreationWorkperiods
        state.calendarCreationName = defaultCalendarCreationState.calendarCreationName
        state.calendarCreationColorId = defaultCalendarCreationState.calendarCreationColorId
    },
    [MUTATION_SET_CALENDAR_ROOMS](state, rooms) {
        const roomsWithFormattedIds = rooms.map(room => ({
            ...room,
            formattedId: removeRoomPrefixFromId(room.id)
        }))

        state.calendarRooms = roomsWithFormattedIds
    },
    [MUTATION_SET_CALENDAR_FILTER_SERVICES](state, services) {
        state.calendarServices = services
    },
    [MUTATION_SET_SELECTED_FACILITY](state, facility) {
        state.selectedFacility = facility?.id ? facility : null
    },
    [MUTATION_SET_SELECTED_SPECIALITY](state, speciality) {
        state.selectedSpeciality = speciality?.id ? speciality : null
    },
    [MUTATION_SET_SELECTED_SPECIALITY_GROUP](state, specialityGroup) {
        state.selectedSpecialityGroup = specialityGroup
    },
    [MUTATION_SET_DOCTORS](state, doctors) {
        state.doctors = doctors
    },
    [MUTATION_SET_SCHEDULES](state, schedules) {
        state.schedules = schedules
    },
    [MUTATION_SET_FACILITIES](state, facilities) {
        state.facilities = facilities
    },
    [MUTATION_UPDATE_DOCTORS_SELECTED](state, { doctorIds, selected }) {
        state.doctors = state.doctors.map((doctor, index) => {
            return {
                ...doctor,
                selected: doctorIds.includes(doctor.id) ? selected : doctor.selected
            }
        })
    },
    [MUTATION_SET_ONLY_DOCTORS_SELECTED](state, { doctorIds }) {
        state.doctors.forEach(doctor => {
            doctor.selected = doctorIds.includes(doctor.id)
        })
    },
    [MUTATION_SET_DOCTORS_WORKING_CURRENT_PERIOD](state, doctorsWorkingCurrentPeriod) {
        state.doctorsWorkingCurrentPeriod = doctorsWorkingCurrentPeriod
    },
    [MUTATION_UPDATE_DOCTORS](state, doctors) {
        Vue.set(state, 'doctors', doctors)
    },
    [MUTATION_UPDATE_SEARCH_CALENDARS_QUERY](state, query) {
        state.sidebarCalendarsQuery = query
    },
    [MUTATION_UPDATE_SEARCH_SERVICES_QUERY](state, query) {
        state.sidebarServicesQuery = query
    },
    [MUTATION_SET_CALENDAR_SELECTED_SCHEDULES](state, selectedSchedules) {
        state.schedules.forEach((schedule, index) => {
            if (!selectedSchedules) {
                Vue.set(state.schedules, index, {
                    ...schedule,
                    selected: false
                })
            }
            if (selectedSchedules && selectedSchedules.scheduleIds.includes(schedule.id)) {
                Vue.set(state.schedules, index, {
                    ...schedule,
                    selected: selectedSchedules.selected
                })
            }
        })
    },
    [MUTATION_SET_CALENDAR_DEVICES](state, devices) {
        state.calendarDevices = [...devices]
    },
    [MUTATION_SET_FREE_SLOTS_AVAILABLE_DAYS](state, availableDays) {
        state.freeSlotsAvailableDays = availableDays
    },
    [MUTATION_SET_FREE_SLOTS_SELECTED_DATE_END](state, freeSlotsEndDate) {
        state.freeSlotsSelectedDateEnd = freeSlotsEndDate
    },
    [MUTATION_SET_AGENDA_EVENTS](state, agendaEvents) {
        state.agendaEvents = agendaEvents
    },
    [MUTATION_SET_CALENDAR_SCHEDULES](state, schedules) {
        state.schedules = schedules
    },
    [MUTATION_SET_CALENDAR_SCHEDULES_ORDER](state, schedulesOrder) {
        state.schedulesOrder = schedulesOrder
    },
    [MUTATION_SET_AGENDA_SELECTED_PATIENT](state, patient) {
        state.agendaSelectedPatient = patient
    },
    [MUTATION_SET_AGENDA_EXPAND](state, payload) {
        let { slots } = state.agendaEvents.days[payload.dayIndex].schedules[payload.scheduleIndex]

        slots[payload.slotIndex].collapse = 0

        slots = slots.map(s => {
            if (slots[payload.slotIndex].collapseId === s.collapseId) {
                s.isCollapsed = false
            }
            return s
        })
    },
    [MUTATION_SET_AGENDA_SELECTED_DURATION](state, duration) {
        state.agendaSelectedDuration = duration
    },
    [MUTATION_SET_CALENDAR_STAFF](state, staff) {
        state.calendarStaff = [...staff]
    },
    [MUTATION_SET_FREE_SLOTS_FILTER_DAYS](state, days) {
        state.selectedFreeSlotsDays = [...days]
    },
    [MUTATION_SET_FREE_SLOTS_FILTER_DAYTIME](state, daytime) {
        state.selectedFreeSlotsDaytime = daytime
    },
    [MUTATION_SET_FREE_SLOTS_FILTER_INSURANCE](state, insuranceType) {
        state.selectedFreeSlotsInsuranceIds = !insuranceType ? [] : [insuranceType]
    },
    [MUTATION_SET_FREE_SLOTS_SELECTED_SERVICE](state, serviceId) {
        state.selectedFreeSlotsService = !serviceId ? [] : [serviceId]
    },
    [MUTATION_ADD_CALENDAR_WORKPERIODS](state, workperiods) {
        const workperiod = workperiods[0]
        const filteredWorkperiods = state.calendarEvents.workperiods.filter(
            ({ scheduleId, start }) => !(scheduleId === workperiod.scheduleId && isSameDay(start, workperiod.start))
        )

        state.calendarEvents.workperiods = [...filteredWorkperiods, ...workperiods]
    },
    [MUTATION_SET_RIGHT_SIDEBAR_PROPS](state, props) {
        state.rightSideBarProps = props
    },
    [MUTATION_RESET_RIGHT_SIDEBAR_PROPS](state) {
        state.rightSideBarProps = null
    },
    [MUTATION_RESET_CALENDAR_CLIPBOARD](state) {
        state.clipboard = {
            event: null,
            isCut: false,
            timestamp: null
        }
    },
    [MUTATION_SET_CALENDAR_CLIPBOARD](state, { clipboardEvent, isCut }) {
        const timestamp = date()
        state.clipboard = {
            event: clipboardEvent,
            isCut,
            timestamp
        }
    },
    [MUTATION_SET_DISPLAYED_EVENTS_SERVICES_IDS](state, servicesIds) {
        const servicesSelectedIds = state.calendarServices.filter(({ selected }) => selected).map(({ id }) => id)
        state.calendarEventsCurrentViewServicesIds = [...new Set([...servicesIds, ...servicesSelectedIds])]
    },
    [MUTATION_TOGGLE_CALENDAR_RESOURCE_WEEK_VIEW](state, showResourceWeek) {
        state.showResourceWeek = showResourceWeek
    },
    [MUTATION_TOGGLE_CALENDAR_HIDE_BOOKED_SLOTS](state, hideBookedSlots) {
        state.hideBookedSlots = hideBookedSlots
    },
    [MUTATION_SET_DOCTOR_SCHEDULE_SELECTED](state, selectedSchedules) {
        state.doctorSchedules.forEach((schedule, index) => {
            if (!selectedSchedules) {
                Vue.set(state.doctorSchedules, index, {
                    ...schedule,
                    selected: false
                })
            }
            if (selectedSchedules && selectedSchedules.scheduleIds.includes(schedule.id)) {
                Vue.set(state.doctorSchedules, index, {
                    ...schedule,
                    selected: selectedSchedules.selected
                })
            }
        })
    },
    [MUTATION_SET_DOCTOR_SCHEDULES](state, schedules) {
        state.doctorSchedules = schedules
    },
    [MUTATION_CLEAN_CALENDAR_EVENTS](state) {
        state.calendarEvents = defaultCalendarEventsState()
        state.calendarEventsWeekRangeNumbers = []
    },
    [MUTATION_RESET_FREE_SLOTS_FILTERS](state) {
        state.selectedFreeSlotsDays = []
        state.selectedFreeSlotsInsuranceIds = []
        state.selectedFreeSlotsDaytime = null
    },
    [MUTATION_HIDE_PATIENT_DATAS](state, isHidden) {
        state.hidePatientData = isHidden
    },
    [MUTATION_SET_FETCH_SCHEDULES_SELECTED](state, fetchSchedulesSelected) {
        state.fetchSchedulesSelected = fetchSchedulesSelected
    },
    [MUTATION_RESET_APPOINTMENT_INTEGRATED_STATE](state, appointmentId) {
        const appointment = state.calendarEvents.appointments.find(({ id }) => {
            return id === appointmentId || id === parseInt(appointmentId, 10)
        })

        if (!appointment) {
            return
        }

        appointment.integrated.hasError = false
    },
    ...calendarSidebarMutations,
    ...performanceSurveyMutations
}
