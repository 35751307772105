import { SHOW_BOOKING_BLOCK_MODAL, SHOW_BOOKING_MODAL } from '@/core/ui/constants/event.bus.constants'
import EventBus from '@/core/ui/libs/eventBus'

interface EmitBookingCreateParams {
    room: any | null
    scheduleId: string
    startDateTime: string
}

export const emitBookingEdit = (id: string) => {
    if (!id) {
        throw Error('An "id" must be specified to emit the booking information')
    }

    EventBus.emit(SHOW_BOOKING_MODAL, {
        mode: 'edit',
        params: {
            id
        }
    })
}

export const emitBookingBlockEdit = (id: string) => {
    if (!id) {
        throw Error('An "id" must be specified to emit the booking information')
    }

    EventBus.emit(SHOW_BOOKING_BLOCK_MODAL, {
        mode: 'edit',
        params: {
            id
        }
    })
}

export const emitBookingCreate = ({ room = null, scheduleId, startDateTime }: EmitBookingCreateParams) => {
    if (!scheduleId) {
        throw Error('A "scheduleId" must be specified to emit the booking information')
    }

    if (!startDateTime) {
        throw Error('A "startDateTime" must be specified to emit the booking information')
    }

    EventBus.emit(SHOW_BOOKING_MODAL, {
        mode: 'create',
        params: {
            startDateTime,
            scheduleId,
            room
        }
    })
}
