export const ROUTER_NAVIGATION = 'routerNavigation'
export const ROUTER_NAVIGATION_LOGOUT = 'routerNavigationLogout'
export const UPDATE_LAYOUT_CONTENT_SIZE = 'updateLayoutContentSize'
export const INVITE_REFERRAL_DOCTOR = 'inviteReferralDoctor'
export const SUGGEST_PATIENT = 'suggestPatient'
export const IFRAME_MODAL_CLOSE = 'iframeModalClose'
export const IS_SELECT_PATIENT_FORM_VISIBLE = 'isSelectPatientFormVisible'
export const INVOICE_AMOUNT_CHANGE_APPROVED = 'invoiceAmountChangeApproved'
export const SCROLL_SIDE_TOP_BAR_LAYOUT = 'scrollSideTopbarLayout'
export const DATEPICKER_RESET_RANGE = 'datePickerResetRange'
export const SHOW_BOOKING_MODAL = 'showBookingModal'
export const SHOW_BOOKING_BLOCK_MODAL = 'showBookingBlockModal'
export const HIDE_BOOKING_MODAL = 'hideBookingModal'
export const NOTIFICATION_SHOW_SUCCESS = 'notificationShowSuccess'
export const NOTIFICATION_SHOW_ERROR = 'notificationShowError'
