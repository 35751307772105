import { DEFAULT_ERROR_MESSAGE } from '@/core/ui/constants/error.constants'

export default error => {
    if (typeof error !== 'object') {
        error = new Error(DEFAULT_ERROR_MESSAGE)
    }

    const { response } = error
    const status = error.status || (error.response ? error.response.status : '')
    let message = ''
    let details = ''
    let headers

    if (response && typeof response.data !== 'object') {
        message = response.data
    } else {
        message = error.message || error.data.message || ''
    }

    if (response && response.data.message) {
        details = response.data.message || ''
    }

    if (error.response?.headers) {
        headers = new Map()
        headers.set('Date', error.response.headers.date)
        headers.set('X-Request-Id', error.response.headers['x-request-id'])
        headers.set('X-ONE-USER-ID', error.response.headers['x-one-user-id'])
        headers.set('X-SSO-Id', error.response.headers['x-sso-id'])
    }

    return {
        message,
        status,
        details,
        headers
    }
}
