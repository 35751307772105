import { productFruits } from 'product-fruits'

import { AuthGetterTypes } from '@/features/auth/ui/store/types'
import {
    PFChecklistDismissReason,
    PFChecklistEvents,
    PFTourEvents,
    PFTourState
} from '@/features/onboardings/domain/enums/productFruits.enums'
import { PFTour } from '@/features/onboardings/domain/interfaces/productFruits.interfaces'
import { getProjectWSCode } from '@/features/onboardings/utils/constants'
import store from '@/store'

export const initProductOnboarding = (project: string, props?: UserCustomProps) => {
    const locale = store.getters.locale?.slice(0, 2)
    const authUser = store.getters[AuthGetterTypes.GetAuthUser]
    const { docplannerDoctorId, id } = authUser
    const productFruitsCode = getProjectWSCode(project)

    if (!productFruitsCode) {
        return
    }

    productFruits.init(productFruitsCode, locale, {
        username: docplannerDoctorId || id,
        props
    })
}

export const trackProductOnboardingEvent = (eventName: string, eventProperties: Record<string, any>) => {
    productFruits.safeExec(($api: any) => {
        $api.events?.track(eventName, eventProperties)
    })
}

// tours utils
const getTours = () => window.productFruits?.api?.tours.getTours()

export const isPFTourFinished = (tourId: number) =>
    getTours()?.some(({ id, userState }: PFTour) => id === tourId && userState === PFTourState.Finished)

export const markPRTour = (tourId: number, state: PFTourState, kill = true) => {
    window.productFruits?.api?.tours.markAs(tourId, state, kill)
}

export const tryStartPFTour = (tourId: number) => {
    window.productFruits?.api?.tours.tryStartTour(tourId)
}

// surveys utils

export const startPFSurvey = (surveyId: string) => {
    window.productFruits?.api?.surveys.startSurvey(surveyId)
}

// listeners

export const onPFTourFinish = (callback: (tourId: number) => void) => {
    return window.productFruits?.api?.tours.listen(PFTourEvents.TourFinished, (tourId: number) => callback(tourId))
}

export const onPFTourSkip = (callback: (tourId: number) => void) => {
    return window.productFruits?.api?.tours.listen(PFTourEvents.TourSkipped, (tourId: number) => callback(tourId))
}

export const onPFChecklistDismiss = (callback: (id: number, reason: PFChecklistDismissReason) => void) => {
    return window.productFruits?.api?.checklists.listen(
        PFChecklistEvents.Dismissed,
        (id: number, reason: PFChecklistDismissReason) => callback(id, reason)
    )
}
