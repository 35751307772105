<template>
    <BaseError :error="error">
        <DpButton color="primary" size="lg" @click="buttonAction">
            <DpIconRefresh />
        </DpButton>
    </BaseError>
</template>

<script>
    import { DpIconRefresh } from '@dp-ui-kit/icons'
    import { DpButton } from '@dp-ui-kit/vue'

    import { DEFAULT_ERROR_MESSAGE } from '@/core/ui/constants/error.constants'
    import BaseError from '@/core/ui/views/BaseError.vue'

    export default {
        name: 'InitializationError',
        components: {
            DpButton,
            BaseError,
            DpIconRefresh
        },
        props: {
            error: {
                type: [Error, Object],
                default() {
                    return new Error(DEFAULT_ERROR_MESSAGE)
                }
            }
        },
        methods: {
            buttonAction() {
                window.location.href = '/'
            }
        }
    }
</script>
