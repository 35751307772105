import uninav from '@/features/navigation/ui/router/uninav.middleware'

import { FORM_TEMPLATE } from '../domain/types/constants'

const FormTemplate = () => import(/* webpackChunkName: "form-template" */ '@/features/formTemplate/ui/index.vue')

export const formTemplateRoute = {
    path: FORM_TEMPLATE,
    name: FORM_TEMPLATE,
    component: FormTemplate,
    meta: {
        middleware: uninav,
        uninavName: FORM_TEMPLATE,
        title: FORM_TEMPLATE
    }
}
