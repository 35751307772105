import { TASK_NAME_BOOKING, TASK_NAME_PATIENT } from '../../domain/constants'
import { SetupGuideTaskName } from '../../domain/interfaces'

interface TaskTutorialItem {
    description: string
    fileName: string
    title: string
    type: 'video' | 'image'
}

type TaskTutorial = TaskTutorialItem[]

interface ModalTaskTutorial {
    cta: string
    title: string
    tutorial: TaskTutorial
}

const TASK_TUTORIAL_BOOKING_CREATE_ADMIN_FIRST: ModalTaskTutorial = {
    title: 'saas-setup-guide-group-trust-action-add-visit-tutorial-header-v2',
    cta: 'saas-setup-guide-group-trust-action-add-visit-tutorial-step3-cta-v2',
    tutorial: [
        {
            description: 'saas-setup-guide-group-trust-action-add-visit-tutorial-step1-descr-v2',
            fileName: 'first-admin-booking-creation.webm',
            title: 'saas-setup-guide-group-trust-action-add-visit-tutorial-step1-header-v2',
            type: 'video'
        },
        {
            description: 'saas-setup-guide-group-trust-action-add-visit-tutorial-step2-descr-v2',
            fileName: 'first-admin-booking-patient.webm',
            title: 'saas-setup-guide-group-trust-action-add-visit-tutorial-step2-header-v2',
            type: 'video'
        },
        {
            description: 'saas-setup-guide-group-trust-action-add-visit-tutorial-step3-descr-v2',
            fileName: 'first-admin-booking-notification.svg',
            title: 'saas-setup-guide-group-trust-action-add-visit-tutorial-step3-header-v2',
            type: 'image'
        }
    ]
}

// const TASK_TUTORIAL_SCHEDULE_UPDATE_WORKING_PERIODS: ModalTaskTutorial = {
//     title: 'saas-setup-guide-group-book-action-add-working-hours-tutorial-header',
//     cta: 'saas-setup-guide-group-book-action-add-working-hours-tutorial-step1-cta',
//     tutorial: [
//         {
//             description: 'saas-setup-guide-group-book-action-add-working-hours-tutorial-step1-descr',
//             imageFileName: 'working-periods.gif',
//             title: 'saas-setup-guide-group-book-action-add-working-hours-tutorial-step1-header'
//         }
//     ]
// }

const TASK_TUTORIAL_BOOKING_UPDATE_ONLINE_ACTIVATION: ModalTaskTutorial = {
    title: 'saas-setup-guide-group-book-action-go-online-tutorial-header',
    cta: 'saas-setup-guide-group-book-action-go-online-tutorial-step2-cta-v2',
    tutorial: [
        {
            description: 'saas-setup-guide-group-book-action-go-online-tutorial-step1-descr-v2',
            fileName: 'online-activation.png',
            title: 'saas-setup-guide-group-book-action-go-online-tutorial-step1-header-v2',
            type: 'image'
        },
        {
            description: 'saas-setup-guide-group-book-action-go-online-tutorial-step2-descr-v2',
            fileName: 'working-periods.webm',
            title: 'saas-setup-guide-group-book-action-go-online-tutorial-step2-header-v2',
            type: 'video'
        }
    ]
}

const TASK_TUTORIAL_PATIENT_IMPORT: ModalTaskTutorial = {
    title: 'saas-setup-guide-group-know-action-import-patients-tutorial-top-header',
    cta: 'generic-got-it',
    tutorial: [
        {
            description: 'saas-setup-guide-group-know-action-import-patients-tutorial-step1-descr',
            fileName: 'patient-import-tutorial.webm',
            title: 'saas-setup-guide-group-know-action-import-patients-tutorial-step1-header',
            type: 'video'
        },
        {
            description: 'saas-setup-guide-group-know-action-import-patients-tutorial-step2-descr',
            fileName: 'patient-import-desktop.svg',
            title: 'saas-setup-guide-group-know-action-import-patients-tutorial-step2-header',
            type: 'image'
        },
        {
            description: 'saas-setup-guide-group-know-action-import-patients-tutorial-step3-descr',
            fileName: 'patient-import-mobile.svg',
            title: 'saas-setup-guide-group-know-action-import-patients-tutorial-step3-header',
            type: 'image'
        }
    ]
}

const TASK_TUTORIAL = {
    [TASK_NAME_BOOKING.CREATE_ADMIN_FIRST]: TASK_TUTORIAL_BOOKING_CREATE_ADMIN_FIRST,
    // [TASK_NAME_SCHEDULE.UPDATE_WORKING_PERIOD]: TASK_TUTORIAL_SCHEDULE_UPDATE_WORKING_PERIODS,
    [TASK_NAME_BOOKING.UPDATE_ONLINE_ACTIVATION]: TASK_TUTORIAL_BOOKING_UPDATE_ONLINE_ACTIVATION,
    [TASK_NAME_PATIENT.IMPORT]: TASK_TUTORIAL_PATIENT_IMPORT
}

export const getTaskTutorialByTaskName = (name: SetupGuideTaskName): ModalTaskTutorial => {
    if (!TASK_TUTORIAL[name]) {
        return { title: '', tutorial: [], cta: '' }
    }

    return TASK_TUTORIAL[name]
}
