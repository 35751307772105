import { ROUTE_PAYMENTS_SETTINGS_NAME } from '@/features/payments/constants/routes.constants'

import { FILE_IMPORTATION_PATHS, FILE_IMPORTATION_ROUTES } from './fileImportation.constants'

export const SETTINGS_PAYMENTS_ROUTE = ROUTE_PAYMENTS_SETTINGS_NAME

export const SETTINGS_SERVICE_ROUTE = 'settings-services'

export const SETTINGS_MESSAGES_ROUTE = 'settings-messages'

export const SETTINGS_USERS_LIST = 'settings-users'
export const SETTINGS_USERS_NEW = 'settings-users-new'
export const SETTINGS_USERS_FORM = 'settings-users-form'

export const SETTINGS_GENERAL = 'settings-general'

export const SETTINGS_ROUTES = {
    routes: {
        schedules: {
            home: 'settings-schedules',
            schedule: 'settings-schedule',
            payments: 'settings-schedule-payments',
            videoConsultation: 'settings-schedule-videoconsultation',
            workTimePeriods: 'settings-schedule-work-periods',
            services: 'settings-schedule-services',
            servicesConfiguration: 'settings-schedule-services-configuration',
            onlineBooking: 'settings-schedule-online-booking-configuration',
            notifications: 'settings-schedule-notifications',
            dailyReport: 'settings-schedule-daily-report',
            info: 'settings-schedule-info'
        },
        fileImportation: FILE_IMPORTATION_ROUTES
    },
    params: {
        id: 'id'
    }
}

export const SETTINGS_PATHS = {
    schedules: {
        home: 'schedules',
        schedule: `schedules/:${SETTINGS_ROUTES.params.id}`,
        payments: 'payments-settings',
        videoConsultations: 'videoConsultations',
        workTimePeriods: 'workTimePeriods',
        services: 'servicesData',
        servicesConfiguration: 'servicesSettings',
        onlineBooking: 'onlineBooking',
        notifications: 'notificationsSettings',
        dailyReport: 'dailyReport',
        info: 'facilityInformation',
        test: 'test'
    },
    fileImportation: FILE_IMPORTATION_PATHS
}
